import React, { useCallback } from 'react';
import type { MRT_TableOptions } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const MetricsTable = <T extends Record<string, any>>(props: MRT_TableOptions<T>) => {
  const { showDealFinancialTable } = useFlags();
  const bodyCellProps = useCallback(
    () => ({
      sx: {
        padding: 2,
        border: 'solid #0000001F',
        borderWidth: '0px 0px 1px 0px',
      },
      align: 'left' as const,
    }),
    [],
  );

  return showDealFinancialTable ? (
    <MaterialReactTable
      enableSorting={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      muiTableHeadRowProps={{
        sx: {
          backgroundColor: '#F5F5F5',
          boxShadow: 'none',
        },
      }}
      muiTableHeadCellProps={{
        align: 'left',
        sx: {
          padding: 2,
        },
      }}
      muiTableBodyCellProps={bodyCellProps}
      muiTableContainerProps={{
        sx: {
          boxShadow: 'none',
          height: '100%',
          border: 'solid #0000001F',
          borderWidth: '1px 1px 1px 1px',
          borderRadius: '8px 8px 0px 0px',
        },
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: 'none',
          border: 'none',
          height: '100%',
        },
      }}
      {...props}
    />
  ) : null;
};
