import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { createDocumentServiceClient } from '@lama/document-service-client';
import type { Document } from '@lama/document-service-client';
import { documentServiceUrl } from '../../../../framework/environment';

export const useGetDocumentCopies = (documentId: string, options?: Partial<UseQueryOptions<Document[]>>) => {
  const { getAccessTokenSilently } = useAuth0();

  const documentServiceClient = createDocumentServiceClient(documentServiceUrl);

  const getDocumentCopiesQueryFunction = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return documentServiceClient.getDocumentCopies(documentId, token);
  }, [documentId, documentServiceClient, getAccessTokenSilently]);

  const query = useQuery<Document[]>({
    queryKey: ['documentCopies', documentId],
    queryFn: getDocumentCopiesQueryFunction,
    ...options,
  });

  return query;
};
