/* eslint-disable react/jsx-no-bind */
import React from 'react';
import type { FC } from 'react';
import type { DocumentGroup as DocumentGroupType } from './types';
import { DocumentGroup } from './DocumentGroup';
import { useDocumentsMoveToTree } from './hooks/useDocumentsMoveToTree';

export interface DocumentsCategoryListProps {
  groups: DocumentGroupType[];
}

export const DocumentList: FC<DocumentsCategoryListProps> = React.memo(({ groups }) => {
  const moveToTreeData = useDocumentsMoveToTree(groups ?? []);

  if (!groups.length) {
    return null;
  }

  return groups.map((group) => <DocumentGroup key={group.title} group={group} moveToTreeData={moveToTreeData} />);
});
