import React, { useMemo } from 'react';
import type { RequirementProperty } from '@lama/contracts';
import { getSelectedPropertyValue } from '@lama/properties';
import { Text, Flex, greyPalette } from '@lama/design-system';
import { InfoLine } from '@lama/app-components';
import { BorderedContainer } from '../../../../Application/shared/BorderedContainer';

const PropertyInfoLine = ({ property, entity }: { property: RequirementProperty; entity: Record<string, any> }) => {
  const propertyValue = useMemo(
    () => getSelectedPropertyValue(property.fieldName, entity, property.sources, property.type).value,
    [entity, property.fieldName, property.sources, property.type],
  );
  return <InfoLine key={property.propertyId} label={property.displayName} values={propertyValue} type={property.type} />;
};

export const EntityPropertiesExport = ({
  entity,
  entityName,
  properties,
}: {
  entity: Record<string, any>;
  entityName: string;
  properties: RequirementProperty[];
}) => (
  <Flex flexDirection={'column'} gap={6}>
    <Text variant={'h5'} color={greyPalette[500]}>
      {entityName}
    </Text>
    <BorderedContainer>
      <Flex flexDirection={'column'} gap={4}>
        {properties.map((property) => (
          <PropertyInfoLine key={property.propertyId} property={property} entity={entity} />
        ))}
      </Flex>
    </BorderedContainer>
  </Flex>
);
