import { useCallback, useContext } from 'react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import type { MiddeskReportTracking } from '@lama/verify-business-service-client';
import { verifyBusinessServiceClient } from '../../../../../../../shared/clients/verifyBusinessServiceClient';
import { ApplicationContext } from '../../../../../ApplicationContext';

export const useMiddeskReportTrackingQuery = (
  businessId: string | undefined,
  options?: Partial<UseQueryOptions<MiddeskReportTracking | null>>,
): UseQueryResult<MiddeskReportTracking | null> => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const {
    opportunity,
    application: { id: applicationId },
  } = useContext(ApplicationContext);

  const getMiddeskReportTrackingQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!businessId) {
      return null;
    }

    const prevBusinessMiddeskReportTracking: MiddeskReportTracking | undefined = queryClient.getQueryData([
      'middeskReportTracking',
      businessId,
    ]);

    const businessMiddeskReportTracking: MiddeskReportTracking = await verifyBusinessServiceClient.getBusinessMiddeskReportTracking(
      businessId,
      token,
    );

    if (
      businessMiddeskReportTracking?.ordersTracking?.some(
        (order) =>
          order.status === 'completed' &&
          prevBusinessMiddeskReportTracking?.ordersTracking?.find(
            (prevOrder) => prevOrder.relatedClosingTaskId === order.relatedClosingTaskId,
          )?.status !== 'completed',
      )
    ) {
      await queryClient.invalidateQueries({
        queryKey: ['opportunity', opportunity.id],
      });

      await queryClient.invalidateQueries({
        queryKey: ['closingTasks', opportunity.id],
      });

      await queryClient.invalidateQueries({
        queryKey: ['applicationDocuments', applicationId],
      });
    }

    return businessMiddeskReportTracking;
  }, [getAccessTokenSilently, businessId, queryClient, opportunity.id, applicationId]);

  const query = useQuery({
    queryKey: ['middeskReportTracking', businessId],
    queryFn: getMiddeskReportTrackingQuery,
    ...options,
  });

  return query;
};
