import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Button, Modal, ModalContent } from '@lama/design-system';

interface DismissTradelineDialogProps {
  open: boolean;
  onDismissClick: (reason: string) => void;
  onCancelClick: () => void;
}

const reasons = ['Duplicate'];

export const DismissTradelineDialog: FC<DismissTradelineDialogProps> = ({ open, onDismissClick, onCancelClick }) => {
  const [reason, setReason] = useState('Duplicate');

  const onValueChange = useCallback((_e: React.SyntheticEvent, value: string) => {
    setReason(value);
  }, []);

  const renderInput = useCallback(
    (props: any) => <TextField id={'reasons'} name={'reasons'} label={'Reason'} placeholder={'Reason for dismiss'} {...props} />,
    [],
  );

  const onDismissButtonClick = useCallback(() => {
    onDismissClick(reason);
  }, [onDismissClick, reason]);

  return (
    <Modal size={'m'} open={open} title={'Dismiss Tradeline'} onClose={onCancelClick} fullWidth>
      <ModalContent>
        <Autocomplete renderInput={renderInput} disableClearable options={reasons} value={reason} onChange={onValueChange} fullWidth />
      </ModalContent>
      <ModalContent>
        <Button color={'neutral'} onClick={onCancelClick}>
          {'Cancel'}
        </Button>
        <Button onClick={onDismissButtonClick}>{'Dismiss'}</Button>
      </ModalContent>
    </Modal>
  );
};
