import { useMutation } from '@tanstack/react-query';

import { useAuth0 } from '@auth0/auth0-react';
import { userServiceClient } from '../../../shared/clients/userServiceClient';

export const useAddPersonToUserMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  const updateRequirementMutation = useMutation({
    mutationFn: async ({ userId, personId }: { userId: string; personId: string }) => {
      const token = await getAccessTokenSilently();
      await userServiceClient.addPersonIdToUser(userId, personId, token);
    },
  });

  return updateRequirementMutation;
};
