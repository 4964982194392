/* eslint-disable @typescript-eslint/naming-convention */
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo, useContext } from 'react';
import type { FormikHelpers, FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { ConfirmLeave, FormikMoneyInputField } from '@lama/app-components';
import type { FinancialData } from '@lama/contracts';
import { useAsyncFn } from 'react-use';
import { getApplicationEntityByType } from '@lama/properties';
import { ApplicationContext } from '../../../../ApplicationContext';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';
import type { RequirementScreenProps } from '../types';
import { SaveFormButton } from '../../../../shared/SaveFormButton';
import { getFinancialPayload, getFinancialFieldByYear } from './financialFieldUtils';
import { useSubmitFinancialsMutation } from './hooks/useSubmitFinancialsMutation';

const validationSchema = yup.object({
  gross1: yup.number().min(0),
  freeCashflow1: yup.number(),
  debtPayments: yup.number(),
  totalCreditLines: yup.number(),
  currentCreditLinesBalance: yup.number(),
});

interface StreamlinedRevenueScreenInnerFormProps {
  formikProps: FormikProps<any>;
  savingFinancial: boolean;
}

const StreamlinedRevenueScreenInnerForm: FC<StreamlinedRevenueScreenInnerFormProps> = ({
  formikProps: { dirty, isValid, handleSubmit },
  savingFinancial,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ConfirmLeave shouldBlock={dirty}>
      <Form onSubmit={handleSubmit}>
        <Stack gap={4}>
          <Stack gap={3} direction={isMobile ? 'column' : 'row'}>
            <FormikMoneyInputField name={'gross1'} label={'Income (annual)'} />
            <FormikMoneyInputField name={'freeCashflow1'} label={'Free Cash Flow (annual)'} />
          </Stack>
          <Stack gap={3} direction={isMobile ? 'column' : 'row'}>
            <FormikMoneyInputField name={'debtPayments'} label={'Business Debt Service'} />
            <FormikMoneyInputField name={'totalCreditLines'} label={'Revolving Credit Limit'} />
            <FormikMoneyInputField name={'currentCreditLinesBalance'} label={'Revolving Credit Balance'} />
          </Stack>
          <SaveFormButton loading={savingFinancial} submit={handleSubmit} disabled={!dirty || !isValid} />
        </Stack>
      </Form>
    </ConfirmLeave>
  );
};

interface RevenueValues {
  gross1: string;
  freeCashflow1: string;
  debtPayments: string;
  totalCreditLines: string;
  currentCreditLinesBalance: string;
}

export const StreamlinedRevenueScreen: FC<RequirementScreenProps> = ({ requirement }) => {
  const {
    application,
    opportunity: { referenceYear, id: opportunityId },
  } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { isPending: savingFinancials, mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    requirement.entityId,
    requirement.entityType,
    opportunityId,
  );

  const entityFinancials = useMemo<FinancialData[]>(
    () =>
      getApplicationEntityByType(application, requirement.entityType, ['all']).find(({ id }) => id === requirement.entityId)?.financials ??
      [],
    [application, requirement.entityId, requirement.entityType],
  );

  // "Gross Income", "Annual Debt Payments", "Total Credit Lines", "Current Credit Lines Balance" pulled for backwards compatibility
  const initialValues: RevenueValues = useMemo(
    () => ({
      gross1:
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Net Operating Income')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Gross Income')?.value?.toString() ??
        '',
      freeCashflow1: getFinancialFieldByYear(entityFinancials, referenceYear, 'Free Cash Flow')?.value?.toString() ?? '',
      debtPayments:
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Business Debt Service')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Annual Debt Payments')?.value?.toString() ??
        '',
      totalCreditLines:
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Business Revolving Credit Limit')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Revolving Credit Limit')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Total Credit Lines')?.value?.toString() ??
        '',

      currentCreditLinesBalance:
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Business Revolving Credit Balance')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Revolving Credit Balance')?.value?.toString() ??
        getFinancialFieldByYear(entityFinancials, referenceYear, 'Current Credit Lines Balance')?.value?.toString() ??
        '',
    }),
    [entityFinancials, referenceYear],
  );

  const [, submitFinancial] = useAsyncFn(
    async (newValues: RevenueValues, { resetForm }: FormikHelpers<any>) => {
      const { gross1, freeCashflow1, debtPayments, totalCreditLines, currentCreditLinesBalance } = newValues;

      resetForm({ values: newValues });

      const revenuePayload = userId
        ? [
            ...getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: referenceYear,
              fieldName: 'Net Operating Income',
              value: gross1,
              userId,
            }),
            ...getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: referenceYear,
              fieldName: 'Net Income',
              value: gross1,
              userId,
            }),
            ...getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: referenceYear,
              fieldName: 'Free Cash Flow',
              value: freeCashflow1,
              userId,
            }),
            ...getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: referenceYear,
              fieldName: 'Business Debt Service',
              value: debtPayments,
              userId,
            }),
            ...(getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: referenceYear,
              fieldName: 'Business Revolving Credit Limit',
              value: totalCreditLines,
              userId,
            }) ??
              getFinancialPayload({
                entityId: requirement.entityId,
                financials: entityFinancials,
                year: referenceYear,
                fieldName: 'Revolving Credit Limit',
                value: totalCreditLines,
                userId,
              })),
            ...(getFinancialPayload({
              entityId: requirement.entityId,
              financials: entityFinancials,
              year: referenceYear,
              fieldName: 'Business Revolving Credit Balance',
              value: currentCreditLinesBalance,
              userId,
            }) ??
              getFinancialPayload({
                entityId: requirement.entityId,
                financials: entityFinancials,
                year: referenceYear,
                fieldName: 'Revolving Credit Balance',
                value: currentCreditLinesBalance,
                userId,
              })),
          ]
        : [];

      if (revenuePayload.length) {
        await updateFinancialData(revenuePayload);
      }
    },
    [userId, requirement.entityId, entityFinancials, referenceYear, updateFinancialData],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitFinancial}>
      {(formikProps) => <StreamlinedRevenueScreenInnerForm formikProps={formikProps} savingFinancial={savingFinancials} />}
    </Formik>
  );
};
