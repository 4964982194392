import JSZip from 'jszip';
import { useCallback } from 'react';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import type { DocumentGroup } from '../types';

interface ZipEntry {
  path: string;
  blob: Blob;
}

const getDocumentsStructure = async (group: DocumentGroup, basePath = ''): Promise<ZipEntry[]> => {
  const groupPath = basePath ? `${basePath}/${group.title}` : group.title;

  const documentEntries = group.documents
    ? await Promise.all(
        group.documents.map(async (doc) => {
          const downloadUrl = doc.document?.downloadUrl;

          if (downloadUrl) {
            try {
              const response = await fetch(downloadUrl);
              const blob = await response.blob();
              const fileName = `${doc.name ? `${doc.name} - ` : ''}${doc.fileName}`;
              return { path: `${groupPath}/${fileName}`, blob };
            } catch {
              toast.error('Failed to download document', { toastId: 'document-download-failed' });
              return null;
            }
          }
          return null;
        }),
      )
    : [];

  const validDocumentEntries = documentEntries.filter((entry): entry is ZipEntry => entry !== null);

  const subgroupEntries = group.subGroups
    ? await Promise.all(group.subGroups.map((subGroup) => getDocumentsStructure(subGroup, groupPath)))
    : [];

  const allEntries = [...validDocumentEntries, ...subgroupEntries.flat()];

  return allEntries;
};

export const useDownloadDocumentGroup = (group: DocumentGroup) =>
  useCallback(async () => {
    const entries = await getDocumentsStructure(group);

    if (entries.length === 0) {
      console.log('No documents to download');
      return;
    }

    const zip = new JSZip();

    entries.forEach(({ path, blob }) => {
      zip.file(path, blob);
    });

    const content: Blob = await zip.generateAsync({ type: 'blob' });

    saveAs(content, `${group.title}.zip`);
  }, [group]);
