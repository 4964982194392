import React, { useContext, useMemo } from 'react';
import type { EvaluatedOpportunityRequirement, OpportunityRequirementStatus } from '@lama/contracts';
import { InfoOutlined } from '@mui/icons-material';
import { Typography, Chip } from '@mui/material';
import { Tooltip } from '@lama/app-components';
import Markdown from 'react-markdown';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../ApplicationContext';
import { requirementStatusToTextMapping, statusColorMapping, statusToBgColorMapping } from '../RequirementStatusBadge';
import { RequirementHeaderActions } from './RequirementHeader/RequirementHeaderActions';

const getStatusChipProps = (status: OpportunityRequirementStatus) => ({
  label: requirementStatusToTextMapping[status],
  style: {
    bgcolor: statusToBgColorMapping[status],
    color: statusColorMapping[status],
    borderColor: statusColorMapping[status],
    border: 'none',
  },
});

export const RequirementHeaderRow: React.FC<{ requirement: EvaluatedOpportunityRequirement }> = ({ requirement }) => {
  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { name, status, description, statusOverride, statusOverrideByEntity, entityId } = requirement;

  const statusChipProps = useMemo(() => getStatusChipProps(status), [status]);

  return (
    <Flex data-testid={'requirementContent'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
      <Flex gap={4} alignItems={'center'}>
        <Flex gap={2} alignItems={'center'}>
          <Typography variant={'h5'} className={'sentry-unmask'}>
            {name}
          </Typography>
          {description ? (
            <Tooltip title={<Markdown>{description}</Markdown>}>
              <InfoOutlined color={'info'} />
            </Tooltip>
          ) : null}
        </Flex>
        <Chip size={'small'} label={statusChipProps.label} variant={'outlined'} sx={statusChipProps.style} />
      </Flex>
      <RequirementHeaderActions
        opportunityId={opportunityId}
        requirement={requirement}
        statusOverride={statusOverride ?? statusOverrideByEntity?.[entityId]}
        status={status}
      />
    </Flex>
  );
};
