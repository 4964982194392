import type { FC } from 'react';
import React, { useMemo } from 'react';
import OnboardingCompletedIcon from '@mui/icons-material/PlaylistAddCheckCircleOutlined';
import { personFullName } from '@lama/selectors';
import { AuditLog } from '../../../../AuditLog';
import { useUserPartner } from '../../../../../../hooks/useCurrentPartner';
import type { ApplicationStatusAuditProps } from './applicationStatusAuditComponentMap';

export const ApplicationOnboardingCompleteAuditLog: FC<ApplicationStatusAuditProps> = ({ audit, groupInfo }) => {
  const partner = useUserPartner();
  const auditText = useMemo(() => {
    if (audit.originType === 'system' || !audit.user?.firstName || !audit.user?.lastName) {
      return `Application moved to status ${partner.applicationStatusDisplayNameMapping.OnboardingCompleted}`;
    }

    if (audit.user.type === 'lender') {
      return `${personFullName(
        audit.user as { firstName: string; middleName?: string; lastName: string },
      )} has changed the application status to ${partner.applicationStatusDisplayNameMapping.OnboardingCompleted}`;
    }
    return `${personFullName(audit.user as { firstName: string; middleName?: string; lastName: string })} completed the onboarding process`;
  }, [audit.originType, audit.user, partner.applicationStatusDisplayNameMapping.OnboardingCompleted]);

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={auditText}
      timestamp={audit.timestamp}
      key={audit.id}
      badge={
        <OnboardingCompletedIcon fontSize={'inherit'} color={'primary'} sx={{ backgroundColor: 'white', borderRadius: '50%', border: 0 }} />
      }
    />
  );
};
