import React, { useContext, useMemo, type FC } from 'react';
import { allApplicationPeopleSelector, personFullName } from '@lama/data-formatters';
import type { UpdateAuditLogProps } from '../types';
import { AuditLog } from '../../AuditLog';
import { ApplicationContext } from '../../../../../components/Application/ApplicationContext';

export const OpportunityCreditFraudulentActivityCleared: FC<UpdateAuditLogProps> = ({ audit, groupInfo }) => {
  const { application } = useContext(ApplicationContext);
  const reason = audit[0]?.changes?.creditReportFraudulentActivityClearance?.[0]?.reason;
  const personId = audit[0]?.changes?.creditReportFraudulentActivityClearance?.[0]?.personId;

  const person = allApplicationPeopleSelector(application).find((p) => p.id === personId);
  const personName = person ? personFullName(person) : 'UNKNOWN';

  const text = useMemo(() => `Fraudulent activity alert has been cleared for ${personName}. Comment: "${reason}"`, [personName, reason]);

  if (!reason) {
    return null;
  }

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={text}
      timestamp={groupInfo.timestamp}
    />
  );
};
