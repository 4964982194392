/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import type { FC } from 'react';
import { isNil } from 'lodash-es';
import type { ApplicationStatus } from '@lama/contracts';
import type { AuditComponentProps } from '../types';
import { AuditLog } from '../../../AuditLog';
import { useUserPartner } from '../../../../../hooks/useCurrentPartner';
import { OpportunityProductChangeAuditLog } from './OpportunityProductChangeAuditLog';
import { OpportunityFlagClearedAuditLog } from './OpportunityFlagClearedAuditLog';
import { OpportunityCompleteApplicationExtensionAuditLog } from './OpportunityCompleteApplicationExtension';
import { OpportunityApprovedLoanAmoutAuditLog } from './OpportunityApprovedLoanAmoutAuditLog';
import { OpportunityCreditFraudulentActivityCleared } from './OpportunityCreditFraudulentActivityCleared';

export const OpportunityUpdateAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const partner = useUserPartner();

  const opportunityUpdateAuditLogs = useMemo(
    () =>
      audit.map((auditEntry) => {
        if (!isNil(auditEntry.changes?.productId)) {
          return <OpportunityProductChangeAuditLog key={auditEntry.id} audit={[auditEntry]} groupInfo={groupInfo} />;
        }

        if (!isNil(auditEntry.changes?.completeApplicationExtension?.reason)) {
          return <OpportunityCompleteApplicationExtensionAuditLog key={auditEntry.id} audit={[auditEntry]} groupInfo={groupInfo} />;
        }

        if (!isNil(auditEntry.changes?.terms?.approvedLoanAmount)) {
          return <OpportunityApprovedLoanAmoutAuditLog key={auditEntry.id} audit={[auditEntry]} groupInfo={groupInfo} />;
        }

        if (!isNil(auditEntry.changes?.creditReportFraudulentActivityClearance)) {
          return <OpportunityCreditFraudulentActivityCleared key={auditEntry.id} audit={[auditEntry]} groupInfo={groupInfo} />;
        }

        if (auditEntry.changes?.underwriting?.notes?.at(0)?.type === 'cleared') {
          return <OpportunityFlagClearedAuditLog key={auditEntry.id} audit={[auditEntry]} groupInfo={groupInfo} />;
        }

        if (auditEntry.changes?.underwriting?.decisions?.at(0)) {
          return (
            <AuditLog
              key={auditEntry.id}
              {...(auditEntry.originType === 'user'
                ? { originType: auditEntry.originType, user: auditEntry.user! }
                : { originType: auditEntry.originType })}
              text={`Marked the application as ${
                partner.applicationStatusDisplayNameMapping[auditEntry.changes.underwriting.decisions[0].decision as ApplicationStatus] ??
                auditEntry.changes.underwriting.decisions[0].decision
              }`}
              timestamp={groupInfo.timestamp}
            />
          );
        }

        if (auditEntry.changes?.underwriting?.deletedDecisions?.at(0)) {
          return (
            <AuditLog
              key={auditEntry.id}
              {...(auditEntry.originType === 'user'
                ? { originType: auditEntry.originType, user: auditEntry.user! }
                : { originType: auditEntry.originType })}
              text={'Reverted decision'}
              timestamp={groupInfo.timestamp}
            />
          );
        }

        if (auditEntry.changes?.underwriting?.decisionRecommendations?.at(0)) {
          return (
            <AuditLog
              key={auditEntry.id}
              {...(auditEntry.originType === 'user'
                ? { originType: auditEntry.originType, user: auditEntry.user! }
                : { originType: auditEntry.originType })}
              text={`Requested to mark the application as ${
                partner.applicationStatusDisplayNameMapping[
                  auditEntry.changes.underwriting.decisionRecommendations[0].recommendation as ApplicationStatus
                ]
              }`}
              timestamp={groupInfo.timestamp}
            />
          );
        }

        return null;
      }),
    [audit, groupInfo, partner.applicationStatusDisplayNameMapping],
  );

  return <>{opportunityUpdateAuditLogs}</>;
};
