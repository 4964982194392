import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { documentDataExtractorServiceClient } from '../../../clients/documentDataExtractorServiceClient';

export const useSyncSamplesWithS3Mutation = (partnerId: string) => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      await documentDataExtractorServiceClient.syncSpreadingSamples(partnerId, token);
    },
  });
};
