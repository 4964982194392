import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useOpportunityRequirementsQuery = (
  opportunityId: string | undefined,
  options?: Partial<UseQueryOptions<EvaluatedOpportunityRequirement[] | null>>,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const getRequirementsQuery = useCallback(async () => {
    if (!opportunityId) {
      return null;
    }

    const token = await getAccessTokenSilently();

    const opportunityRequirements = await applicationServiceClient.getOpportunityRequirements(opportunityId, token);

    return opportunityRequirements;
  }, [getAccessTokenSilently, opportunityId]);

  return useQuery<EvaluatedOpportunityRequirement[] | null>({
    queryKey: ['opportunityRequirements', opportunityId],
    queryFn: getRequirementsQuery,
    ...options,
  });
};
