import type { LabeledValue } from '@lama/contracts';
import type { AutocompleteRenderInputParams, SxProps, TextFieldProps, Theme } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import { uniq } from 'lodash-es';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Spinner } from '@lama/design-system';
import { UserDetailsContext } from '../context/UserDetailsContext';
import { useGetPartnersQuery } from '../hooks/react-query/partner/useGetPartnersQuery';

const defaultRenderPartnerInput =
  ({ textFieldProps, loading }: { textFieldProps?: TextFieldProps; loading?: boolean }) =>
  ({ InputProps, ...params }: AutocompleteRenderInputParams) =>
    (
      <TextField
        {...textFieldProps}
        {...params}
        label={'Partner'}
        variant={'outlined'}
        InputProps={{
          ...InputProps,
          endAdornment: loading ? <Spinner color={'inherit'} size={'s'} /> : null,
        }}
        fullWidth
      />
    );
const isOptionEqualToValue = (option: { value: string }, value: { value: string }) => option.value === value.value;

export const UserPartnerSelection: FC<{
  partnerId: string | null;
  onPartnerChange: (event: any, value: { label: string; value: string } | null) => void;
  permissionFilter?: 'read' | 'write';
  renderInputProps?: TextFieldProps;
  autocompleteStyleProps?: SxProps<Theme>;
  filterProductionPartners?: boolean;
}> = ({ partnerId, onPartnerChange, permissionFilter, renderInputProps, filterProductionPartners = true, autocompleteStyleProps }) => {
  const { partner: userPartner, partnerPermissions } = useContext(UserDetailsContext);

  const userPartners = useMemo(
    () =>
      uniq([
        ...(userPartner ? [userPartner] : []),
        ...(partnerPermissions
          ? Object.entries(partnerPermissions)
              .filter(([, value]) => !permissionFilter || value.includes(permissionFilter))
              .map(([key]) => key)
          : []),
      ]),
    [userPartner, partnerPermissions, permissionFilter],
  );
  const { data: partners, pending: loadingPartners } = useGetPartnersQuery(userPartners);

  const partnerOptions: LabeledValue[] = useMemo(
    () =>
      loadingPartners
        ? []
        : partners
            ?.filter(({ status }) => !filterProductionPartners || status !== 'production')
            .map(({ id, displayName }) => ({ label: displayName, value: id })) ?? [],
    [filterProductionPartners, loadingPartners, partners],
  );

  const currentPartnerOption = useMemo(() => partnerOptions.find((p) => p.value === partnerId) ?? null, [partnerOptions, partnerId]);

  const renderInput = useMemo(
    () => defaultRenderPartnerInput({ textFieldProps: renderInputProps, loading: loadingPartners }),
    [renderInputProps, loadingPartners],
  );

  return (
    <Autocomplete
      value={currentPartnerOption}
      options={partnerOptions}
      renderInput={renderInput}
      onChange={onPartnerChange}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={loadingPartners}
      loadingText={'Loading Partners'}
      sx={{ ...autocompleteStyleProps }}
    />
  );
};
