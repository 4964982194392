import { useAuth0 } from '@auth0/auth0-react';
import type { Entity } from '@lama/common-types';
import type { FinancialData } from '@lama/contracts';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { FinancialUpdateModel } from '@lama/clients';
import { merge } from 'lodash-es';
import { displayToast } from '@lama/app-components';
import { financialsServiceClient } from '../../../../../shared/clients/financialsServiceClient';

interface UpdateFinancialsMutationParams {
  entityType: Entity;
  entityId: string;
  financialsToUpdate: FinancialUpdateModel[];
}

export const useUpdateFinancialsMutation = (applicationId: string, entityId: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ entityType, financialsToUpdate }: UpdateFinancialsMutationParams) => {
      const token = await getAccessTokenSilently();

      await financialsServiceClient.updateFinancials(entityType, entityId, financialsToUpdate, token);
    },
    onMutate: async ({ financialsToUpdate }) => {
      await queryClient.cancelQueries({ queryKey: ['financials', entityId] });

      const previousFinancials = queryClient.getQueryData(['financials', entityId]);

      queryClient.setQueryData(['financials', entityId], (oldFinancials?: FinancialData[]) => {
        const oldFinancialsWithoutUpdated = oldFinancials?.filter(
          (oldFin) => !financialsToUpdate.some((updatedFin) => oldFin.id === updatedFin.id),
        );
        const updatedFinancials = financialsToUpdate.map((financialUpdate) => {
          const oldFinancial = oldFinancials?.find((oldFin) => oldFin.id === financialUpdate.id);
          return merge(oldFinancial, financialUpdate);
        });

        return [...(oldFinancialsWithoutUpdated ?? []), ...updatedFinancials];
      });

      return { previousFinancials };
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['financials', entityId],
        }),
      ]);
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });
};
