import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Input, Modal, ModalContent, ModalFooter } from '@lama/design-system';

import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { useAsyncFn } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { useUpdateRequirement } from '../../../../../shared/hooks/react-query/requirement/useUpdateRequirement';

interface RequestAnotherDocumentModalProps {
  open: boolean;
  onClose: () => void;
  requirement: EvaluatedOpportunityRequirement;
}

export const RequestAnotherDocumentModal: FC<RequestAnotherDocumentModalProps> = ({ open, onClose, requirement }) => {
  const [documentName, setDocumentName] = useState('');

  const { mutateAsync: updateRequirement } = useUpdateRequirement(requirement.opportunityId, requirement.id);
  const [{ loading: updatingRequirement }, onSubmit] = useAsyncFn(async () => {
    await updateRequirement({
      updateRequirementPayload: {
        sources: {
          ...requirement.sources,
          uploadFilesSource: [
            ...(requirement.sources.uploadFilesSource ?? []),
            {
              extractable: !!requirement.sources.uploadFilesSource?.some((source) => source.extractable),
              name: documentName,
              topic: `lender-requested_${uuidv4()}`,
            },
          ],
        },
      },
    });

    onClose();
  }, [documentName, onClose, requirement.sources, updateRequirement]);

  const saveDisabled = useMemo(() => !documentName.length, [documentName]);

  const onDocumentNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentName(e.target.value);
  }, []);

  return (
    <Modal open={open} onClose={onClose} title={'Request additional document'} size={'s'} alignTitle={'center'} fullWidth>
      <ModalContent>
        <Input value={documentName} onChange={onDocumentNameChange} placeholder={'Document Name'} autoFocus />
      </ModalContent>
      <ModalFooter>
        <Button onClick={onSubmit} color={'primary'} disabled={saveDisabled} loading={updatingRequirement}>
          {'Submit'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
