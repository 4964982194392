/* eslint-disable react/jsx-no-bind */
import React, { useMemo } from 'react';
import type { FC } from 'react';
import { sortBy } from 'lodash-es';
import { DocumentList } from './DocumentList';
import type { DocumentGroup, GroupDocumentListProps } from './types';

export const RecentDocumentList: FC<GroupDocumentListProps> = React.memo(({ documents }) => {
  const sortedDocuments = useMemo(
    () => sortBy(documents, (doc) => doc.document?.createdAt || doc.requirement?.createdAt).reverse(),
    [documents],
  );

  const allDocumentGroup: DocumentGroup = useMemo(
    () => ({
      title: 'All Documents',
      documents: sortedDocuments,
    }),
    [sortedDocuments],
  );

  if (!documents.length) {
    return null;
  }

  return <DocumentList groups={[allDocumentGroup]} />;
});
