import React, { useCallback, useContext, useMemo } from 'react';
import { Check as CheckCircleIcon, Close } from '@mui/icons-material';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import type { FC } from 'react';
import { Flex, Spinner, Text } from '@lama/design-system';
import type { OpportunityApiModel } from '@lama/clients';
import type { Document } from '@lama/document-service-client';
import { SpreadingDocumentContext } from '../../SpreadingDocumentContext';
import { SpreadingToolbarActions } from './SpreadingToolbarActions';

interface SpreadingDialogToolbarProps {
  onClose?: () => void;
  opportunity?: OpportunityApiModel;
  applicationId?: string;
  documents: Document[];
}

const renderInput = (placeholder: string, customParams?: any) => (params: any) =>
  <TextField variant={'standard'} {...params} {...customParams} placeholder={placeholder} />;

const renderDocumentInput = renderInput('Document');

export const SpreadingToolbar: FC<SpreadingDialogToolbarProps> = ({ onClose, applicationId, opportunity, documents }) => {
  const { currentDocument, setCurrentDocument, saving } = useContext(SpreadingDocumentContext);

  const documentOptions = useMemo(
    () =>
      documents.map((document) => ({
        label: document.filename,
        value: document.id,
      })),
    [documents],
  );

  const onDocumentChange = useCallback(
    (event: React.SyntheticEvent, value: { label: string; value: string } | null) => {
      if (value) {
        setCurrentDocument(value.value);
      }
    },
    [setCurrentDocument],
  );

  const currentDocumentOption = useMemo(
    () => documentOptions.find((option) => option.value === currentDocument.id),
    [documentOptions, currentDocument],
  );

  return (
    <Flex flexDirection={'row'} px={4} py={1}>
      <Flex gap={1} flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
        <Flex gap={4} flexDirection={'row'} alignItems={'center'} flex={0.5}>
          {onClose ? (
            <IconButton edge={'start'} onClick={onClose}>
              <Close />
            </IconButton>
          ) : null}
          <Flex flex={1} gap={2}>
            <Autocomplete
              fullWidth
              value={currentDocumentOption}
              options={documentOptions}
              onChange={onDocumentChange}
              renderInput={renderDocumentInput}
              disableClearable
            />
          </Flex>
          <Flex flex={0.3} flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'center'}>
            {saving ? (
              <>
                <Spinner size={'16px'} />
                <Text variant={'body3'} color={'secondary'}>
                  {'Saving Changes....'}
                </Text>
              </>
            ) : (
              <>
                <CheckCircleIcon color={'success'} sx={{ fontSize: 16 }} />
                <Text variant={'body3'} color={'secondary'}>
                  {'Changes Saved'}
                </Text>
              </>
            )}
          </Flex>
        </Flex>
        <SpreadingToolbarActions opportunity={opportunity} applicationId={applicationId} />
      </Flex>
    </Flex>
  );
};
