import type { FC } from 'react';
import React, { useMemo, useCallback, useContext } from 'react';
import { Divider } from '@mui/material';
import { AccountDetails } from '@lama/app-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { compact } from 'lodash-es';
import { Flex, greyPalette } from '@lama/design-system';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useGetPartnerQuery } from '../../hooks/react-query/partner/useGetPartnerQuery';
import { SupportIcon } from './assets/SupportIcon';
import { IntegrationsIcon } from './assets/IntegrationsIcon';
import { PortfolioIcon } from './assets/PortfolioIcon';
import { ExchangeIcon } from './assets/ExchangeIcon';
import { ReportsIcon } from './assets/ReportsIcon';
import { DashboardIcon } from './assets/DashboardIcon';
import { ProductsIcon } from './assets/ProductsIcon';
import { DealBoardIcon } from './assets/DealBoardIcon';
import { PipelineIcon } from './assets/PipelineIcon';
import { SideNavItem } from './SideNavItem';
import type { NavItemProps } from './SideNavItem';
import Logo from './assets/logo.svg';

const getNavItems = ({
  enableProductSettingsTab,
  accountOpening,
  showDeals,
  enableReportsTab,
  enableDemoIntegrations,
  fibtDemo,
}: {
  enableProductSettingsTab: boolean;
  accountOpening: boolean;
  showDeals: boolean;
  enableReportsTab: boolean;
  enableDemoIntegrations: boolean;
  fibtDemo: boolean;
}): NavItemProps[] => {
  const basicSideNav = [
    {
      text: 'Deals',
      href: '/deals',
      Icon: DealBoardIcon,
      disabled: !showDeals || fibtDemo,
    },
    {
      text: 'Pipeline',
      href: '/pipeline',
      Icon: PipelineIcon,
    },
    {
      text: 'Products',
      href: '/products',
      disabled: !enableProductSettingsTab || fibtDemo,
      Icon: ProductsIcon,
    },
    accountOpening ? { text: 'Account Opening', href: '/account-opening', Icon: DashboardIcon } : undefined,
    {
      text: 'Reports',
      href: '/reports',
      disabled: !enableReportsTab || fibtDemo,
      Icon: ReportsIcon,
    },
    {
      text: 'Exchange',
      disabled: true,
      Icon: ExchangeIcon,
    },
    {
      text: 'Portfolio',
      disabled: true,
      Icon: PortfolioIcon,
    },
    {
      text: 'Integrations',
      href: '/integrations',
      disabled: !enableDemoIntegrations,
      Icon: IntegrationsIcon,
    },
  ];

  return compact(basicSideNav);
};

const StyledSideNavItemsContainer = styled(Flex)`
  gap: 2vh;
`;

const StyledSideNavContainer = styled(Flex)`
  flex-shrink: 1;
  box-sizing: border-box;
  border-right: 1px solid ${({ theme }) => theme.colors.grey[200]};
  max-height: 100%;
  overflow-y: auto;
`;

export const SideNav: FC = () => {
  const { firstName, lastName, email, partner, userId } = useContext(UserDetailsContext);
  const { data: partnerEntity } = useGetPartnerQuery(partner);
  const { logout } = useAuth0();
  const { accountOpening, fibtDemo, showDeals, showCurrentUserPartner } = useFlags();
  const { enableProductSettingsTab, enableReportsTab, enableDemoIntegrations } = useMemo(
    () => partnerEntity?.featureConfigurations ?? {},
    [partnerEntity],
  );

  const logoutWithRedirect = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    mixpanel.reset();
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const sideNavItems = useMemo(
    () =>
      getNavItems({
        accountOpening,
        enableProductSettingsTab: !!enableProductSettingsTab,
        showDeals: !!showDeals,
        enableReportsTab: !!enableReportsTab,
        enableDemoIntegrations: !!enableDemoIntegrations,
        fibtDemo: !!fibtDemo,
      }),
    [accountOpening, enableProductSettingsTab, showDeals, enableReportsTab, enableDemoIntegrations, fibtDemo],
  );

  return (
    <StyledSideNavContainer flexDirection={'column'} px={3}>
      <Flex flexDirection={'column'} py={8} gap={8} alignItems={'center'} borderColor={greyPalette[300]} height={'100%'} overflowY={'auto'}>
        <Flex justifyContent={'center'}>
          <Logo />
        </Flex>
        <Flex flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
          <StyledSideNavItemsContainer flexDirection={'column'}>
            {sideNavItems.map((props) => (
              <SideNavItem key={props.text} {...props} text={props.text} />
            ))}
          </StyledSideNavItemsContainer>
          <Flex flexDirection={'column'} gap={4}>
            <SideNavItem text={'Support'} href={'mailto:support@lama.ai'} Icon={SupportIcon} />
            <Divider />
            {userId ? (
              <Flex justifyContent={'center'}>
                <AccountDetails
                  logout={logoutWithRedirect}
                  firstName={firstName ?? email ?? ''}
                  lastName={lastName ?? ''}
                  email={email ?? ''}
                  partner={showCurrentUserPartner ? partner : undefined}
                  minimized
                />
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </StyledSideNavContainer>
  );
};
