import type { PipelineItem } from '@lama/pipeline-items-service-client';
import type { OpportunityApiModel } from '@lama/clients';
import { applicationNumberSelector, loanDisplayNameSelector } from '@lama/data-formatters';
import { primaryContactNameSelector } from '@lama/selectors';
import type { SearchResult } from './types';

export const opportunityToSearchResult = (opportunity: OpportunityApiModel): SearchResult => ({
  opportunityId: opportunity.id,
  applicationNumber: applicationNumberSelector(opportunity) ?? undefined,
  primaryContactFullName: primaryContactNameSelector(opportunity.application) ?? undefined,
  applicationStatus: opportunity.application.status,
  loanDisplayName: loanDisplayNameSelector(opportunity.application),
});

export const pipelineItemToSearchResult = (pipelineItem: PipelineItem): SearchResult => ({
  opportunityId: pipelineItem.opportunityId,
  applicationNumber: pipelineItem.applicationNumber,
  applicationStatus: pipelineItem.applicationStatus,
  loanDisplayName: pipelineItem.loanDisplayName,
  primaryContactFullName: pipelineItem.primaryContactFullName,
});
