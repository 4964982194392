import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Grid } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair, LoadingPage } from '@lama/app-components';
import { proposedLoanAmount } from '@lama/data-formatters';
import { SegmentContainer } from '../SegmentContainer';
import { useUsersByPartnerQuery } from '../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { useLenderProfilesByPartnerQuery } from '../../../../shared/hooks/react-query/lenderProfile/useLenderProfilesByPartnerQuery';
import { ApplicationContext } from '../../ApplicationContext';
import { FIXED_RATE_TYPE, getRateType } from '../LoanTerms/termsUtils';

export const TermsViewSegment: FC = () => {
  const { application, opportunity } = useContext(ApplicationContext);
  const { terms, branchCode, referenceYear, assigneeIds, partnerId } = opportunity;

  const { data: users, isPending: loadingUsers } = useUsersByPartnerQuery(partnerId);
  const { data: lenderProfiles, isPending: loadingLenderProfiles } = useLenderProfilesByPartnerQuery(partnerId);

  const lenderProfile = useMemo(() => {
    const assignedRM = users?.find((u) => (assigneeIds ?? []).includes(u.id) && u.roles.includes('relationshipManager'));
    return lenderProfiles?.find((p) => p.userId === assignedRM?.id);
  }, [assigneeIds, lenderProfiles, users]);

  const termsData = useMemo<InfoLineProps[]>(() => {
    const rateType = getRateType(terms?.rate);
    return [
      {
        label: 'Term Length (Months)',
        values: terms?.termLength ?? application.requestedTerm,
      },
      {
        label: 'Rate Type',
        values: rateType ?? application.requestedRateType,
      },
      {
        label: rateType === FIXED_RATE_TYPE ? 'Rate' : 'Spread',
        values: terms?.rate?.spread,
      },
      {
        label: 'Proposed Loan Amount',
        values: proposedLoanAmount(application),
        type: 'currency',
      },
    ];
  }, [terms?.rate, terms?.termLength, application]);

  const detailsData = useMemo<InfoLineProps[]>(
    () => [
      {
        label: 'Loan Officer',
        values: lenderProfile?.officerCode,
      },
      {
        label: 'Branch ID',
        values: branchCode,
      },
      {
        label: 'Disbursement Date',
        values: terms?.disbursementDate,
        type: 'date',
      },
      {
        label: 'Payment Period',
        values: terms?.paymentPeriod ?? application.requestedPaymentPeriod,
      },
      {
        label: 'First Payment Date',
        values: terms?.firstPaymentDate,
        type: 'date',
      },
      {
        label: 'Submission Date',
        values: application.submittedAt,
        type: 'date',
      },
      {
        label: 'Review Year',
        values: referenceYear,
      },
    ],
    [
      lenderProfile?.officerCode,
      branchCode,
      terms?.disbursementDate,
      terms?.paymentPeriod,
      terms?.firstPaymentDate,
      application.requestedPaymentPeriod,
      application.submittedAt,
      referenceYear,
    ],
  );

  if (loadingLenderProfiles || loadingUsers) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={4}>
      <SegmentContainer title={'Terms'}>
        <Grid columns={3}>
          {termsData.map((data) => (
            <KeyValuePair key={data.label} name={data.label} value={data.values} type={data.type} />
          ))}
        </Grid>
      </SegmentContainer>
      <SegmentContainer title={'Details'}>
        <Grid columns={3}>
          {detailsData.map((data) => (
            <KeyValuePair key={data.label} name={data.label} value={data.values} type={data.type} />
          ))}
        </Grid>
      </SegmentContainer>
    </Flex>
  );
};
