import { Flex, Text } from '@lama/design-system';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useTheme } from '@mui/material';
import { SideNav } from '../SideNav/SideNav';
import ErrorImage from './assets/ErrorImage.svg';

const supportEmail = 'support@lama.ai';

export const ErrorPage: FC<{ title?: string }> = ({ title }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Flex height={'100vh'} width={'100vw'}>
      <SideNav />
      <Flex width={'100%'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={2}>
        <ErrorImage />
        <Flex flexDirection={'column'} alignItems={'center'} gap={2}>
          <Text variant={'h4'}>{title ?? t('global.error.title')}</Text>
          <Text variant={'body1'}>
            {t('global.error.description')}{' '}
            <Link variant={'body1'} color={theme.palette.primary.main} href={`mailto:${supportEmail}`} underline={'none'} target={'_blank'}>
              <Text variant={'body1'}>{supportEmail}</Text>
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
