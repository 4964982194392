import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { FinancialData } from '@lama/contracts';
import { useAuth0 } from '@auth0/auth0-react';
import { displayToast } from '@lama/app-components';
import { financialsServiceClient } from '../../../../../shared/clients/financialsServiceClient';

interface CreateFinancialsMutation {
  financialsToCreate: FinancialData[];
}

export const useCreateFinancialsMutation = (applicationId: string, entityId: string) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationKey: ['createFinancials'],
    mutationFn: async ({ financialsToCreate }: CreateFinancialsMutation) => {
      const token = await getAccessTokenSilently();

      await financialsServiceClient.createFinancials(financialsToCreate[0]?.entityType ?? 'business', entityId, financialsToCreate, token);
      await queryClient.invalidateQueries({ queryKey: ['financials', entityId] });
    },
    onMutate: async ({ financialsToCreate }) => {
      queryClient.setQueryData(['financials', entityId], (oldFinancials: FinancialData[]) => [
        ...(oldFinancials ?? []),
        ...financialsToCreate,
      ]);
    },

    onError: (error) => {
      console.log(error);
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });
};
