import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AuditEntry } from '@lama/audit-service-client';
import { applicationServiceClient } from '../../../clients/applicationServiceClient';

export const useOpportunityAuditQuery = (opportunityId: string, options?: Partial<UseQueryOptions<AuditEntry[]>>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getAuditByEntityIdsQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    const audit = await applicationServiceClient.getOpportunityAudit(opportunityId, token);
    return audit;
  }, [opportunityId, getAccessTokenSilently]);

  const query = useQuery<AuditEntry[]>({ queryKey: ['audit', opportunityId], queryFn: getAuditByEntityIdsQuery, ...options });

  return query;
};
