import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Grid } from '@lama/design-system';
import { applicationHmdaUniversalLoanIdentifier, proposedLoanAmount } from '@lama/data-formatters';
import { ltv, annualRateSelector } from '@lama/selectors';
import type { InfoLineProps } from '@lama/app-components';
import { KeyValuePair, LoadingPage } from '@lama/app-components';
import { compact } from 'lodash-es';
import { ApplicationContext } from '../../../ApplicationContext';
import { useGetPartnerQuery } from '../../../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { useLenderProfilesByPartnerQuery } from '../../../../../shared/hooks/react-query/lenderProfile/useLenderProfilesByPartnerQuery';
import { useUsersByPartnerQuery } from '../../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';

const denialReasonOtherCode = '9';

export const HmdaLoanApplicationDetailsSegment: FC = () => {
  const { application, opportunity } = useContext(ApplicationContext);

  const { partnerId, underwriting, assigneeIds } = opportunity;
  const { data: partner, isLoading: loadingPartner } = useGetPartnerQuery(partnerId);

  const { data: lenderProfiles, isPending: loadingLenderProfiles } = useLenderProfilesByPartnerQuery(partnerId);
  const { data: users, isPending: loadingUsers } = useUsersByPartnerQuery(partnerId);

  const lenderProfile = useMemo(() => {
    const assignedRM = users?.find((u) => (assigneeIds ?? []).includes(u.id) && u.roles.includes('relationshipManager'));
    return lenderProfiles?.find((p) => p.userId === assignedRM?.id);
  }, [assigneeIds, lenderProfiles, users]);

  const loanApplicationDetails = useMemo<InfoLineProps[]>(() => {
    const denialReasons = compact(underwriting?.decisions?.filter((d) => d.decision === 'Rejected').flatMap((d) => d.reasons)) ?? [];
    const denialReasonOtherText = denialReasons.find((r) => r.code === denialReasonOtherCode)?.text;
    const denialReasonsTexts = denialReasons.map((r) => r.text);
    const { terms } = opportunity;
    return [
      {
        label: 'Universal Loan Identifier',
        values: applicationHmdaUniversalLoanIdentifier(opportunity, partner?.hmdaLegalEntityIdentifier ?? ''),
      },
      {
        label: 'Application Creation Date',
        values: application.createdAt,
        type: 'date',
      },
      {
        label: 'Loan Amount',
        values: proposedLoanAmount(application),
        type: 'currency',
      },
      {
        label: 'Reason for Denial',
        values: denialReasonsTexts,
      },
      {
        label: 'Other free text specification',
        values: denialReasonOtherText,
      },
      {
        label: 'Interest Rate',
        values: annualRateSelector(application),
        type: 'threeDecimalPercent',
      },
      {
        label: 'Debt-to-Income Ratio',
        values: 'NA',
      },
      {
        label: 'CLTV',
        values: ltv(application),
        type: 'percent',
      },
      {
        label: 'Assigned RM NMLS',
        values: lenderProfile?.NMLSNumber,
      },
      {
        label: 'Rate Spread',
        values: terms?.rate?.isFixed !== false ? 'NA' : terms?.rate?.spread ?? 'NA',
      },
      {
        label: 'Total Loan Costs',
        values: application.hmda?.totalLoanCosts ?? 'NA',
      },
      {
        label: 'Total Points and Fees',
        values: application.hmda?.totalPointsAndFees ?? 'NA',
      },
      {
        label: 'Origination Charges',
        values: application.hmda?.originationCharges ?? 'NA',
      },
      {
        label: 'Discount Points',
        values: application.hmda?.discountPoints ?? 'NA',
      },
      {
        label: 'Lender Credits',
        values: application.hmda?.lenderCredits ?? 'NA',
      },
      {
        label: 'Prepayment Penalty Term',
        values: application.hmda?.prepaymentPenaltyTerm,
      },
    ];
  }, [application, lenderProfile?.NMLSNumber, opportunity, partner?.hmdaLegalEntityIdentifier, underwriting?.decisions]);

  if (loadingPartner || loadingLenderProfiles || loadingUsers) {
    return <LoadingPage />;
  }

  return (
    <Grid columns={3}>
      {loanApplicationDetails.map((line) => (
        <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
      ))}
    </Grid>
  );
};
