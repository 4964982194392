import type { FC } from 'react';
import React from 'react';
import { Flex } from '@lama/design-system';
import { Divider } from '@mui/material';
import type { FieldFilter } from './FieldFilterButton';
import { FieldFilterButton } from './FieldFilterButton';

interface FieldFiltersProps {
  setFieldFilter: (filter: FieldFilter) => void;
  fieldFilter: FieldFilter;
}

export const FieldFilters: FC<FieldFiltersProps> = ({ setFieldFilter, fieldFilter }) => (
  <Flex px={4} pt={3} pb={3} gap={4} flexDirection={'column'}>
    <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={6}>
      <FieldFilterButton active={fieldFilter === 'all'} filter={'all'} setFilter={setFieldFilter} text={'All Fields'} />
      <FieldFilterButton active={fieldFilter === 'missing'} filter={'missing'} setFilter={setFieldFilter} text={'Missing Fields'} />
    </Flex>
    <Divider />
  </Flex>
);
