import React from 'react';
import type { CreateAudit, UpdateAudit } from '@lama/audit-service-client';
import type { FC } from 'react';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import { UpdateFinancialsAuditLog } from './UpdateFinancialsAuditLog';
import { CreateFinancialsAuditLog } from './CreateFinancialsAuditLog';

export const FinancialsAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'update') {
    return <UpdateFinancialsAuditLog audit={audit as UpdateAudit[]} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'create') {
    return <CreateFinancialsAuditLog audit={audit as CreateAudit[]} groupInfo={groupInfo} />;
  }
  return null;
};
