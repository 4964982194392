import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Flex } from '@lama/design-system';
import { LoadingButton } from '@mui/lab';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import type { FC } from 'react';
import { Grid } from '@mui/material';
import { FormikMoneyInputField, ConfirmLeave } from '@lama/app-components';
import type { FinancialData } from '@lama/contracts';
import { getSourcedProperty } from '@lama/properties';
import { isEmpty } from 'lodash-es';
import { allApplicationPeopleSelector } from '@lama/data-formatters';
import { ApplicationContext } from '../../../../ApplicationContext';
import type { RequirementScreenProps } from '../types';
import { useSubmitFinancialsMutation } from '../financials/hooks/useSubmitFinancialsMutation';
import { getFinancialPayload } from '../financials/financialFieldUtils';
import { UserDetailsContext } from '../../../../../../shared/context/UserDetailsContext';

interface FinancialProperty {
  propertyKey: string;
  formFieldName: string;
  attributeName: string;
}

const financialPropertiesData: FinancialProperty[] = [
  {
    propertyKey: 'personalAnnualIncome',
    formFieldName: 'personalAnnualIncome',
    attributeName: 'Annual Income',
  },
];

const getFinancialsPayload = ({
  entityId,
  referenceYear,
  formFields,
  sourcedFinancialProperties,
  initialValues,
  entityFinancials,
  userId,
}: {
  entityId: string;
  referenceYear: number;
  formFields: Record<string, string>;
  sourcedFinancialProperties: (FinancialProperty & { sourcedProperty: any })[];
  initialValues: Record<string, any>;
  entityFinancials: FinancialData[];
  userId: string;
}) => {
  const financialsPayload = Object.entries(formFields).flatMap(([fieldName, fieldValue]) => {
    const financialProperty = sourcedFinancialProperties.find(({ formFieldName }) => formFieldName === fieldName);

    if (!financialProperty || fieldValue === initialValues[fieldName]) {
      return [];
    }

    return getFinancialPayload({
      entityId,
      financials: entityFinancials,
      year: referenceYear,
      fieldName: financialProperty.attributeName,
      value: fieldValue as number | string | undefined,
      userId,
    });
  });

  return financialsPayload;
};

export const GC14FamilyPersonalFinancials: FC<RequirementScreenProps> = ({ requirement: { entityId, entityType } }) => {
  const [submitted, setSubmitted] = useState(false);
  const { application, opportunity, properties } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);

  const { mutateAsync: updateFinancialData, isPending: isUpdatingFinancials } = useSubmitFinancialsMutation(
    entityId,
    entityType,
    opportunity.id,
  );

  const person = useMemo(() => allApplicationPeopleSelector(application).find((p) => p.id === entityId), [application, entityId]);

  const sourcedFinancialProperties = useMemo(() => {
    const yearsBack = new Date().getUTCFullYear() - opportunity.referenceYear;

    return financialPropertiesData.flatMap((propertyData) => {
      const property = properties[propertyData.propertyKey];
      return property
        ? [
            {
              ...propertyData,
              sourcedProperty: getSourcedProperty(property, person, yearsBack),
            },
          ]
        : [];
    });
  }, [opportunity.referenceYear, person, properties]);

  const initialValues = useMemo(() => {
    const financialInitialValues = Object.fromEntries(
      sourcedFinancialProperties.map(({ formFieldName, sourcedProperty: { selectedPropertyValue } }) => [
        formFieldName,
        selectedPropertyValue?.value ?? '',
      ]),
    );

    return {
      ...financialInitialValues,
    } as Record<string, any>;
  }, [sourcedFinancialProperties]);

  const onSubmit = useCallback(
    async (values: typeof initialValues, { resetForm }: FormikHelpers<typeof initialValues>) => {
      resetForm({ values });

      if (!isEmpty(values) && userId) {
        const financialsPayload = getFinancialsPayload({
          entityId,
          referenceYear: opportunity.referenceYear,
          formFields: values,
          sourcedFinancialProperties,
          initialValues,
          entityFinancials: person?.financials ?? [],
          userId,
        });

        if (financialsPayload.length) {
          await updateFinancialData(financialsPayload);
        }
      }

      setSubmitted(true);
    },
    [entityId, initialValues, opportunity.referenceYear, person?.financials, sourcedFinancialProperties, updateFinancialData, userId],
  );

  if (!person) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty, isValid, handleSubmit }) => (
        <ConfirmLeave shouldBlock={dirty}>
          <Grid container spacing={2}>
            {sourcedFinancialProperties.length ? (
              <>
                {sourcedFinancialProperties.map(
                  ({ formFieldName, sourcedProperty: { selectedPropertyValue, propertyValues, displayName } }) => (
                    <Grid item xs={6} key={formFieldName}>
                      <FormikMoneyInputField
                        name={formFieldName}
                        label={displayName}
                        required
                        highlight={submitted}
                        fullWidth
                        value={selectedPropertyValue?.value ?? ''}
                        sourcesValues={propertyValues}
                      />
                    </Grid>
                  ),
                )}
              </>
            ) : null}
            <Grid item xs={12}>
              <Flex justifyContent={'flex-end'} width={'100%'}>
                <LoadingButton
                  disabled={!dirty || !isValid}
                  color={'primary'}
                  sx={{ width: '130px', height: '40px', cursoer: 'pointer' }}
                  variant={'contained'}
                  disableElevation
                  loading={isUpdatingFinancials}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {'Save'}
                </LoadingButton>
              </Flex>
            </Grid>
          </Grid>
        </ConfirmLeave>
      )}
    </Formik>
  );
};
