import type { ApplicationApiModel, OpportunityApiModel, PersonApiModel } from '@lama/clients';
import type { BusinessApiModel } from '@lama/business-service-client';
import {
  applicationPrimaryContactSelector,
  monthsInBusinessSelector,
  applicationUseOfFundsSelector,
  isApplicationHMDAReportableSelector,
} from '@lama/selectors';
import { personFullName, referrerDisplayName, applicationNumberSelector, businessFullAddress, formatValue } from '@lama/data-formatters';
import type { RelatedApplication } from '@lama/contracts';
import { industryTypes } from '@lama/contracts';
import type { InfoLineProps } from '@lama/app-components';
import { getShortAddressInfoLine } from '../../../shared/utils/informationLineUtils';

const addProtocol = (url: string) => {
  if (!url) {
    return '';
  }
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `http://${url}`;
};

const getGoogleMapsUrl = (address: string) => {
  const baseUrl = 'https://www.google.com/maps/search/?api=1&query=';
  const encodedAddress = encodeURIComponent(address);

  return baseUrl + encodedAddress;
};

export const getBusinessDetails = (business: BusinessApiModel, fibtDemo?: boolean): InfoLineProps[] => [
  {
    label: 'Type',
    values: business.industryTypes?.map((value: string) => industryTypes.find((type) => type.value === value)?.label ?? value) ?? [],
    type: 'array',
    wrap: false,
  },
  {
    label: 'Phone Number',
    values: business.phoneNumber,
    type: 'phoneNumber',
  },
  {
    label: 'Website',
    values: business.website ? ['Visit site', addProtocol(business.website)] : [],
    type: 'link',
  },
  ...(fibtDemo
    ? [
        {
          label: 'External Links',
          values: [
            {
              iconUrl: 'https://lama-ai-assets.s3.us-east-1.amazonaws.com/bbbrating.png',
              linkUrl: 'https://www.bbb.org/us/nd/jamestown/profile/cpa/schauer-associates-pc-0704-1000012052',
            },
            {
              iconUrl: 'facebook',
              linkUrl: 'https://www.facebook.com/SchauerCPA/',
            },
            {
              iconUrl: 'yelp',
              linkUrl: 'https://www.yelp.com/biz/schauer-and-associates-pc-cpas-jamestown-2',
            },
          ],
          type: 'linkIcons' as const,
        },
      ]
    : [
        {
          label: 'Social Media',
          values: business.socialMediaHandles?.map(({ platform, url }) => `${platform}_${url}`),
          type: 'social' as const,
        },
      ]),
  {
    label: 'Time in Business',
    values: business.startDate ? formatValue(monthsInBusinessSelector(business), 'monthsAsYearsAndMonths') : '',
  },
  {
    label: 'Address',
    values: getShortAddressInfoLine(business.addresses?.[0]),
  },
  {
    label: 'Location',
    values: business.googlePlaceId
      ? `https://www.google.com/maps/place/?q=place_id:${business.googlePlaceId}`
      : getGoogleMapsUrl(businessFullAddress(business)),
    type: 'location',
  },
];

export const getPersonDetails = (person: PersonApiModel): InfoLineProps[] => [
  {
    label: 'Email Address',
    values: person.email,
    type: 'email',
  },
  {
    label: 'Phone Number',
    values: person.phoneNumber,
    type: 'phoneNumber',
  },
];

export const getLoanDetails = (
  application: ApplicationApiModel,
  opportunity: OpportunityApiModel,
  showApplicationNumber: boolean,
  showHmdaApplicable: boolean,
  relatedApplications?: RelatedApplication[],
): InfoLineProps[] => [
  {
    label: 'Requested Amount',
    values: application.requestedAmount,
    type: 'currencyCompact',
  },
  {
    label: 'Use of Funds',
    values: applicationUseOfFundsSelector(application),
    type: 'array',
    wrap: false,
  },
  ...(application.referredBy
    ? [
        {
          label: 'Referral',
          values: referrerDisplayName({ referrer: application.referrer }) ?? application.referredBy,
        },
      ]
    : []),
  ...(opportunity.partnerSequenceNumber && showApplicationNumber
    ? [
        {
          label: 'Application Number',
          values: applicationNumberSelector(opportunity) ?? '-',
        },
      ]
    : []),
  ...(showHmdaApplicable
    ? [
        {
          label: 'HMDA Applicable',
          values: isApplicationHMDAReportableSelector(application),
          type: 'boolean' as const,
        },
      ]
    : []),
  {
    label: 'Related Applications',
    values:
      (relatedApplications?.length ?? 0) > 0
        ? [`${relatedApplications?.length} Matches Found`, 'relatedApplicationsSegment']
        : 'No Matches Found',
    type: (relatedApplications?.length ?? 0) > 0 ? ('hashLink' as const) : ('string' as const),
  },
];

export const getPrimaryContactDetails = (application: ApplicationApiModel): InfoLineProps[] => {
  const primaryContact = applicationPrimaryContactSelector(application);

  return [
    {
      label: 'Name',
      values: primaryContact ? personFullName(primaryContact) : undefined,
    },
    {
      label: 'Phone Number',
      values: primaryContact?.phoneNumber,
      type: 'phoneNumber',
    },
    {
      label: 'Email Address',
      values: primaryContact?.email,
      type: 'email',
    },
  ];
};
