import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { useContext, useMemo } from 'react';
import { getEnabledFileUploadSources } from '@lama/evaluations';
import type { DocumentDetails } from '../types';
import { isDocumentRelatedToRequirement } from '../../../shared/utils/isDocumentRelatedToRequirement';
import { useOpportunityClosingTasksQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityClosingTasksQuery';
import { ApplicationContext } from '../../Application/ApplicationContext';

export const useDocumentDetails = (allDocuments: DocumentWithIssues[], allRequirements: EvaluatedOpportunityRequirement[]) => {
  const {
    opportunity: { id: opportunityId },
    application,
  } = useContext(ApplicationContext);
  const { data: closingTasks } = useOpportunityClosingTasksQuery(opportunityId);

  return useMemo<DocumentDetails[]>(() => {
    if (!allRequirements.length && !allDocuments.length) {
      return [];
    }

    const allRequirementTopics = new Set(allRequirements.flatMap((r) => r.sources.uploadFilesSource?.map((file) => file.topic) ?? []));
    const allClosingTaskDocumentIds = closingTasks?.flatMap((task) => task.documentIds) ?? [];

    const miscDocumentDetails: DocumentDetails[] =
      allDocuments
        ?.filter(
          (document) =>
            (!document.topic || !allRequirementTopics.has(document.topic)) &&
            !allClosingTaskDocumentIds.includes(document.id) &&
            !document.requirementId,
        )
        .map((document) => ({
          id: document.id,
          fileName: document.filename,
          document,
          topic: document.topic,
          name: document.description,
          viewGroup: 'miscellaneous',
        })) ?? [];

    const documentsByRequirementId: [string, DocumentWithIssues[]][] =
      allRequirements?.map((r) => [r.id, allDocuments?.filter((d) => isDocumentRelatedToRequirement(d, r)) ?? []]) ?? [];

    const allDocumentRequirements = documentsByRequirementId.flatMap(([requirementId, reqDocuments]) => {
      const requirement = allRequirements?.find((r) => r.id === requirementId);

      if (!requirement) {
        return [];
      }

      const enabledPlaceholders = getEnabledFileUploadSources({
        sources: requirement.sources.uploadFilesSource ?? [],
        entityId: requirement.entityId,
        application,
      });

      const additionalRquirementDocumentsDetails: DocumentDetails[] = reqDocuments
        ?.filter(
          (d) =>
            !enabledPlaceholders.some((f) => f.topic === d.topic && d.relatedEntityId === requirement?.entityId && d?.status !== 'Deleted'),
        )
        .map((document) => ({
          id: document.id,
          fileName: document.filename,
          document,
          topic: document.topic,
          name: document.description,
          viewGroup: 'requirement',
          entityId: document.relatedEntityId,
          entityType: document.relatedEntityType,
          requirement,
        }));

      const requirementDocumentDetails: DocumentDetails[] = enabledPlaceholders.map(
        ({ name: documentName, topic, dismissDataByEntity }) => {
          const document = reqDocuments?.find(
            (d) => d.topic === topic && d.relatedEntityId === requirement.entityId && d?.status !== 'Deleted',
          );

          return {
            id: document?.id ?? requirement.id + topic,
            document,
            fileName: document?.filename ?? '',
            topic,
            name: document?.description ?? documentName,
            dismissed: dismissDataByEntity?.[requirement.entityId],
            viewGroup: 'requirement',
            requirement,
          };
        },
      );

      return [...requirementDocumentDetails, ...additionalRquirementDocumentsDetails];
    });

    const closingTaskDocuments = allDocuments.filter((d) => closingTasks?.some((task) => task.documentIds?.includes(d.id)));

    const closingTaskDocumentDetails: DocumentDetails[] = closingTaskDocuments.map((document) => ({
      id: document.id,
      fileName: document.filename,
      document,
      topic: document.topic,
      name: document.description,
      viewGroup: 'requirement',
      entityId: document.relatedEntityId,
      entityType: document.relatedEntityType,
      closingTask: closingTasks?.find((task) => task.documentIds?.includes(document.id)),
    }));

    return [allDocumentRequirements, closingTaskDocumentDetails, miscDocumentDetails].flat();
  }, [allRequirements, allDocuments, closingTasks, application]);
};
