import { useFormikContext } from 'formik';
import { useDebounce } from 'react-use';
import { useCallback, type FC } from 'react';

export const FormikAutoSave: FC<{ debounceMs?: number }> = ({ debounceMs = 1000 }) => {
  const formik = useFormikContext();

  const submit = useCallback(async () => {
    if (formik.dirty) {
      await formik.submitForm();
    }
  }, [formik]);

  useDebounce(submit, debounceMs, [formik.values]);

  return null;
};
