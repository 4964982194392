import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { DocumentPage } from '@lama/document-service-client';
import type { OpportunityApiModel } from '@lama/clients';
import { TaxReturnsSpreadingForm } from '../../Forms/TaxReturns/components/TaxReturnsForm';
import { financialsFormTypes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/utils/financialsFormTypes';
import { FinancialStatementsSpreadingForm } from '../../Forms/FinancialStatements/components/FinancialStatementsSpreadingContent';
import type { FormAttributesWithFinanacialData } from '../../Forms/TaxReturns/formFinancialsUtils';
import type { FieldFilter } from './FieldFilterButton';

export interface SpreadingFormByDocumentTypeProps {
  opportunity?: OpportunityApiModel;
  startDocumentPage: DocumentPage;
  endDocumentPage?: DocumentPage;
  filter: FieldFilter;
  formData?: FormAttributesWithFinanacialData[];
}

export type SpreadingContentTypes = 'financials' | 'taxReturns';

const SpreadingFormByType: FC<SpreadingFormByDocumentTypeProps> = ({ startDocumentPage, endDocumentPage, filter, formData }) => {
  const SpreadingContent = useMemo(() => {
    if (financialsFormTypes.has(startDocumentPage.formType)) {
      return FinancialStatementsSpreadingForm;
    }
    return TaxReturnsSpreadingForm;
  }, [startDocumentPage]);

  return SpreadingContent ? (
    <SpreadingContent formData={formData} startDocumentPage={startDocumentPage} filter={filter} endDocumentPage={endDocumentPage} />
  ) : null;
};

export default React.memo(SpreadingFormByType);
