import React, { useContext, useMemo } from 'react';
import type { CreateAudit } from '@lama/audit-service-client';
import type { Document } from '@lama/document-service-client';
import type { FC } from 'react';
import { AuditLog } from '../../AuditLog';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import { ApplicationContext } from '../../../../../components/Application/ApplicationContext';
import { useApplicationDocumentsQuery } from '../../../../hooks/react-query/application/useApplicationDocumentsQuery';

export const CreateDocumentAuditLog = ({ audit, groupInfo }: EntityAuditLogProps) => {
  const documentAudit = audit as CreateAudit[];

  const documentsDescriptions = useMemo(
    () => documentAudit.map((a) => (a.entity as Document).description || (a.entity as Document).filename).join(', '),
    [documentAudit],
  );

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Uploaded ${documentsDescriptions}`}
      timestamp={groupInfo.timestamp}
    />
  );
};

export const DocumentUpdatedAuditLog = ({ audit, groupInfo }: EntityAuditLogProps) => {
  const [firstAudit] = audit;

  if (firstAudit?.type === 'update' && firstAudit.changes.extracted) {
    return (
      <AuditLog
        originType={'system'}
        text={`Automatically extracted financial data from "${firstAudit.context?.filename}"`}
        timestamp={groupInfo.timestamp}
      />
    );
  }

  return null;
};

export const DocumentDeletedAuditLog = ({ audit, groupInfo }: EntityAuditLogProps) => {
  const { application } = useContext(ApplicationContext);
  const { data: applicationDocuments, isPending: loadingDocuments } = useApplicationDocumentsQuery({
    applicationId: application.id,
    includeDeleted: true,
  });

  const deletedDocument = useMemo(() => applicationDocuments?.find((doc) => doc.id === audit[0]?.entityId), [applicationDocuments, audit]);

  if (loadingDocuments) {
    return null;
  }

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Deleted "${deletedDocument?.description || deletedDocument?.filename}"`}
      timestamp={groupInfo.timestamp}
    />
  );
};

export const DocumentAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'create') {
    return <CreateDocumentAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'update') {
    return <DocumentUpdatedAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'delete') {
    return <DocumentDeletedAuditLog audit={audit} groupInfo={groupInfo} />;
  }

  return null;
};
