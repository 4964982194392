/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import type { FC } from 'react';
import type { Document } from '@lama/document-service-client';
import type { OnMetadataUpdateProps } from '@lama/app-components';
import { DocumentBox } from '@lama/app-components';
import { useDeleteDocumentQuery } from '../../../../shared/components/DocumentBox/hooks/useDeleteDocumentQuery';
import { useUpdateDocumentMutation } from '../OpportunityRequirements/RequirementScreens/financials/hooks/useUpdateDocumentMutation';

interface MiscellaneousDocumentBoxProps {
  description: string;
  document: Document;
}

export const MiscellaneousDocumentBox: FC<MiscellaneousDocumentBoxProps> = ({ description, document }) => {
  const { mutateAsync: deleteDocument } = useDeleteDocumentQuery();
  const { mutateAsync: updateDocument } = useUpdateDocumentMutation({
    applicationId: document.applicationId,
  });

  const onDeleteDocument = useCallback(async () => {
    await deleteDocument({ documentId: document.id });
  }, [deleteDocument, document]);

  const onUpdateDocumentName = useCallback(
    async ({ filename, description: descriptionChange }: OnMetadataUpdateProps) => {
      const documentUpdatePayload = {
        ...(filename !== document.filename && { filename }),
        ...(descriptionChange !== document.description && { descriptionChange }),
      };

      if (Object.keys(documentUpdatePayload).length) {
        await updateDocument({
          documentId: document.id,
          payload: documentUpdatePayload,
        });
      }
    },
    [document.description, document.filename, document.id, updateDocument],
  );

  return (
    <DocumentBox
      description={description}
      document={document}
      onDocumentRemoved={onDeleteDocument}
      onMetadataUpdate={onUpdateDocumentName}
    />
  );
};
