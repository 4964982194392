import React, { useContext, useCallback, useState, useMemo } from 'react';
import { Dialog, TextField } from '@mui/material';
import { Flex, Button, Text, Input } from '@lama/design-system';
import type { FC } from 'react';
import type { OnValueChange } from 'react-number-format';
import type { FinancialData, Property } from '@lama/contracts';
import { isNil } from 'lodash-es';
import type { SpreadRowPeriodValue } from '@lama/spreads-generator-client';
import { ApplicationContext } from '../../../../../ApplicationContext';
import { useAdjustFinancialValue } from '../../../hooks/useAdjustFinancialValue';
import { useAdjustmentNote } from '../../../hooks/useAdjustmentNote';

interface AdjustValueDialogProps {
  open: boolean;
  onClose: () => void;
  property: Property;
  columnDate: string;
  existingAdjustment?: FinancialData;
  applicationId: string;
  entityId: string;
  startDate: string;
  endDate: string;
  currentValue: SpreadRowPeriodValue | null;
}

export const AdjustFinancialValueDialog: FC<AdjustValueDialogProps> = ({
  onClose,
  open,
  columnDate,
  property,
  existingAdjustment,
  entityId,
  endDate,
  startDate,
  currentValue: rowData,
}) => {
  const [adjustmentValue, setAdjustmentValue] = useState(rowData?.financialAttribute?.value ?? undefined);
  const { opportunity } = useContext(ApplicationContext);
  const currentAdjustmentNote = useAdjustmentNote(opportunity, existingAdjustment?.id);
  const [noteText, setNoteText] = useState(currentAdjustmentNote?.text ?? '');

  const onAdjustmentValueChange: OnValueChange = useCallback((values) => {
    setAdjustmentValue(values.floatValue);
  }, []);

  const onNoteTextChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteText(event.target.value);
  }, []);

  const [{ loading: savingAdjustment }, saveAdjustment] = useAdjustFinancialValue({
    applicationId: opportunity.id,
    entityId,
    value: adjustmentValue,
    currentValue: rowData,
    property,
    existingAdjustment,
    currentAdjustmentNote,
    onSuccess: onClose,
  });

  const onClickSave = useCallback(
    () =>
      saveAdjustment({
        noteText,
        startDate,
        endDate,
      }),
    [saveAdjustment, noteText, startDate, endDate],
  );

  const saveDisabled = useMemo(
    () =>
      isNil(adjustmentValue) ||
      (adjustmentValue === rowData?.financialAttribute?.value && noteText === currentAdjustmentNote?.text) ||
      !noteText,
    [adjustmentValue, currentAdjustmentNote?.text, rowData?.financialAttribute?.value, noteText],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} disablePortal disableEnforceFocus>
      <Flex flexDirection={'column'} minWidth={700} p={8}>
        <Flex flexDirection={'column'} gap={6}>
          <Flex flexDirection={'column'} gap={2}>
            <Flex flexDirection={'row'} alignItems={'center'} gap={2}>
              <Text variant={'h6'}>{property.displayName}</Text>
              <Text variant={'body3'} color={'secondary'}>
                {`- ${columnDate}`}
              </Text>
            </Flex>
            <Text variant={'body3'} color={'secondary'}>
              {'Adjusting the input will affect any calculation that uses this value.'}
            </Text>
          </Flex>
          <Flex flexDirection={'column'} gap={4}>
            <Input
              prefix={'$'}
              thousandSeparator={','}
              label={'Value'}
              maxLength={180}
              type={'number'}
              value={adjustmentValue}
              onValueChange={onAdjustmentValueChange}
              autoFocus
              fullWidth
            />
            <TextField
              label={'Note'}
              value={noteText}
              maxRows={10}
              minRows={3}
              sx={{ minHeight: 150 }}
              onChange={onNoteTextChange}
              multiline
              fullWidth
            />
          </Flex>
        </Flex>
        <Flex flexDirection={'row'} justifyContent={'flex-end'} gap={2}>
          <Button variant={'tertiary'} size={'m'} color={'neutral'} onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button variant={'primary'} color={'primary'} size={'m'} disabled={saveDisabled} onClick={onClickSave} loading={savingAdjustment}>
            {'Save'}
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};
