/* eslint-disable react/jsx-no-bind */
import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { getRequirementsByCategory } from '@lama/app-components';
import { compact, isNil } from 'lodash-es';
import { ApplicationContext } from '../Application/ApplicationContext';
import { useOpportunityClosingTasksQuery } from '../../shared/hooks/react-query/opportunity/useOpportunityClosingTasksQuery';
import { DocumentList } from './DocumentList';
import type { DocumentGroup, GroupDocumentListProps } from './types';
import { useDocumentGroupsByTopic } from './hooks/useDocumentGroupsByTopic';

export const TopicDocumentList: FC<GroupDocumentListProps> = React.memo(({ documents, allRequirements }) => {
  const {
    application,
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);
  const { data: closingTasks } = useOpportunityClosingTasksQuery(opportunityId);
  const { groups: topicGroups } = useDocumentGroupsByTopic(documents, allRequirements ?? [], closingTasks ?? []);

  const documentGroups: DocumentGroup[] = useMemo(() => {
    if (!topicGroups.length) {
      return [];
    }

    const requirementByCategory = getRequirementsByCategory(allRequirements, application);

    return topicGroups.map<DocumentGroup>((p) => ({
      ...p,
      documents: [],
      subGroups: [
        ...compact(
          requirementByCategory.map((category) => {
            if (category.entityType === 'application' && category.categoryLongName.includes('Submit')) {
              return null;
            }

            const requirementDocuments =
              p.documents?.filter((doc) => category.requirements.some((req) => req.id === doc?.requirement?.id)) ?? [];

            const closingTaskDocuments =
              p.documents?.filter(
                (doc) => category.entityType === doc.entityType && category.entityId === doc.entityId && !isNil(doc.closingTask),
              ) ?? [];

            if (!requirementDocuments?.length && !category.requirements.length && !closingTaskDocuments.length) {
              return null;
            }

            return {
              title: category.categoryLongName,
              documents: [...requirementDocuments, ...closingTaskDocuments],
              requirements: category.requirements,
            };
          }),
        ),
      ],
    }));
  }, [topicGroups, allRequirements, application]);

  if (!documents.length) {
    return null;
  }

  return <DocumentList groups={documentGroups} />;
});
