import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';
import { exportMultipleSheetsAsExcel } from '@ag-grid-enterprise/excel-export';
import type { SpreadComponentConfiguration } from '@lama/contracts';
import type { Business, RelatedPersonApiModel } from '@lama/business-service-client';
import { personFullName, loanDisplayNameSelector, businessName } from '@lama/data-formatters';
import { compact, keyBy } from 'lodash-es';
import { getOpportunityEntityByType } from '@lama/properties';
import type { Entity } from '@lama/common-types';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { GenericSpread } from '../../Application/shared/GenericSpread/GenericSpread';
import type { SpreadTableRef } from '../../Application/CreditMemo/Spreads/hooks/types';
import { useGetPartnerSectionsQuery } from '../../../shared/hooks/react-query/partnerSections/useGetPartnerSectionsQuery';

interface SpreadsExportProps {
  exportWhenReady: boolean;
  onExportComplete: () => void;
}

const getEntityName = ({ entity, entityType }: { entity?: Record<string, any> & { id: string }; entityType?: Entity }) => {
  if (entityType === 'application' || entityType === 'opportunity' || !entity) {
    return '';
  }
  const name = entityType === 'person' ? personFullName(entity as RelatedPersonApiModel) : businessName(entity as Business);
  return name ?? '';
};

const SpreadComponentExport: FC<{
  addSheet: (sheet: any, sheetName: string) => void;
  spreadConfiguration: SpreadComponentConfiguration;
  entityType?: Entity;
  entity?: Record<string, any> & { id: string };
}> = ({ addSheet, spreadConfiguration, entityType, entity }) => {
  const spreadRef = useRef<SpreadTableRef>(null);
  const spreadName = useMemo(() => {
    const entityName = getEntityName({ entity, entityType });
    return `${spreadConfiguration.name}${entityName ? ` - ${entityName}` : ''}`;
  }, [entity, entityType, spreadConfiguration.name]);

  const onGridReady = useCallback(() => {
    if (spreadRef.current) {
      const spread = spreadRef.current.getSpread(spreadName);
      if (!spread) {
        return;
      }
      addSheet(spread, spreadName);
    }
  }, [addSheet, spreadName]);

  return (
    <GenericSpread
      spreadConfiguration={{ ...spreadConfiguration, name: spreadName }}
      entity={entity}
      entityType={entityType}
      ref={spreadRef}
      onGridReady={onGridReady}
    />
  );
};

export const SpreadsExport: FC<SpreadsExportProps> = ({ exportWhenReady, onExportComplete }) => {
  const {
    product: { creditMemoConfiguration },
    application,
    opportunity,
  } = useContext(ApplicationContext);
  const { data: partnerSections, isPending: loadingPartnerSections } = useGetPartnerSectionsQuery(opportunity.partnerId);

  const [sheets, setSheets] = useState<Record<string, any>>({});

  const addSheet = useCallback(
    (sheet: any, sheetName: string) => {
      setSheets((prev) => ({ ...prev, [sheetName]: sheet }));
    },
    [setSheets],
  );

  const sectionConfigurationByKey = useMemo(() => keyBy(partnerSections, (c) => c.key), [partnerSections]);
  const spreadComponents = useMemo(() => {
    if (!creditMemoConfiguration) {
      return [];
    }

    const sectionConfigurations = compact(creditMemoConfiguration.sections.map((section) => sectionConfigurationByKey[section.key]));

    const spreadGenericComponents = sectionConfigurations.flatMap((section) =>
      section.segments.flatMap((segment) =>
        segment.components
          .filter((component) => component.type === 'spread')
          .flatMap((component) => {
            const componentConfig = component as SpreadComponentConfiguration;

            if (segment.entityType && segment.entityGroups) {
              const entities = getOpportunityEntityByType(opportunity, segment.entityType, segment.entityGroups);
              return entities.map((entity) => (
                <SpreadComponentExport
                  key={`${componentConfig.name}-${entity.id}`}
                  addSheet={addSheet}
                  spreadConfiguration={componentConfig}
                  entityType={segment.entityType}
                  entity={entity}
                />
              ));
            }

            return <SpreadComponentExport addSheet={addSheet} spreadConfiguration={componentConfig} key={componentConfig.name} />;
          }),
      ),
    );

    return spreadGenericComponents;
  }, [addSheet, creditMemoConfiguration, opportunity, sectionConfigurationByKey]);

  const loanDisplayName = useMemo(() => loanDisplayNameSelector(application), [application]);

  useEffect(() => {
    if (!exportWhenReady || Object.keys(sheets).length < spreadComponents.length || loadingPartnerSections) {
      return;
    }

    if (!spreadComponents?.length) {
      onExportComplete();
      return;
    }

    exportMultipleSheetsAsExcel({
      fileName: `${loanDisplayName} - Spreads.xlsx`,
      data: Object.values(sheets),
    });
    onExportComplete();
  }, [sheets, exportWhenReady, application, onExportComplete, spreadComponents.length, loadingPartnerSections, loanDisplayName]);

  if (loadingPartnerSections) {
    return null;
  }

  return <div className={'hiddenContainer'}>{spreadComponents}</div>;
};
