import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Text } from '@lama/design-system';
import { getSelectedPropertyValue } from '@lama/properties';
import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../../shared/SegmentContainer';

export const PropertyTextSegment: FC<{ entity: Record<string, any> & { id: string }; title?: string; propertyKey: string }> = ({
  entity,
  title,
  propertyKey,
}) => {
  const { properties } = useContext(ApplicationContext);

  const property = useMemo(() => properties[propertyKey], [properties, propertyKey]);

  const propertyValue = useMemo(
    () => (property ? getSelectedPropertyValue(property.fieldName, entity, property.sources, property.type)?.value : undefined),
    [entity, property],
  );

  return (
    <SegmentContainer title={title ?? property?.displayName} bordered={false}>
      <Text variant={'body2'}>{propertyValue || '-'}</Text>
    </SegmentContainer>
  );
};
