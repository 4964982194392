import React from 'react';
import type { CreateAudit } from '@lama/audit-service-client';
import type { FC } from 'react';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import { CreateOpportunityRequirementAuditLog } from './CreateOpportunityRequirementAuditLog';

export const OpportunityRequirementAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'create') {
    return <CreateOpportunityRequirementAuditLog audit={audit as CreateAudit[]} groupInfo={groupInfo} />;
  }

  return null;
};
