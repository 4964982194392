import React from 'react';
import type { ConnectionAudit, CreateAudit, DataProcessedAudit, UpdateAudit } from '@lama/audit-service-client';
import type { FC } from 'react';
import { ConnectionEstablishedAuditLog } from '../System/ConnectionEstablishedAuditLog';
import { DataProcessedAuditLog } from '../System/DataProcessedAuditLog';
import { UpdateEntityAuditLog } from '../UpdateEntityAuditLog';
import { CreateEntityAuditLog } from '../CreateEntityAuditLog';
import type { EntityAuditLogProps } from '../../GroupedAudit';

export const BusinessAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'update') {
    return <UpdateEntityAuditLog audit={audit as UpdateAudit[]} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'create') {
    return <CreateEntityAuditLog audit={audit as CreateAudit[]} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'connection') {
    return <ConnectionEstablishedAuditLog audit={audit as ConnectionAudit[]} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'dataProcessed') {
    return <DataProcessedAuditLog audit={audit as DataProcessedAudit[]} groupInfo={groupInfo} />;
  }

  return null;
};
