/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import type { FC } from 'react';
import { isNil } from 'lodash-es';
import { UpdateEntityAuditLog } from '../UpdateEntityAuditLog';
import { consecutiveGroupBy } from '../../../consecutiveGroupBy';
import type { AuditComponentProps } from '../types';
import { ApplicationStatusAuditLog } from './ApplicationStatusAuditLog';
import { ApplicationReferrerAuditLog } from './ApplicationReferrerAuditLog';

export const ApplicationUpdateAuditLog: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const ApplicationUpdateAuditLogs = useMemo(() => {
    const groupedAudits = consecutiveGroupBy(audit, (entry) => entry.changes?.status);

    return groupedAudits.map((audits) => {
      const statusChangeAudits = audits.filter((auditEntry) => !isNil(auditEntry.changes?.status));

      if (statusChangeAudits.length) {
        return <ApplicationStatusAuditLog audit={statusChangeAudits} groupInfo={groupInfo} key={statusChangeAudits[0]!.id} />;
      }

      const referrerChangeAudits = audits.filter((auditEntry) => !isNil(auditEntry.changes?.referredBy));

      if (referrerChangeAudits.length) {
        return <ApplicationReferrerAuditLog audit={referrerChangeAudits} groupInfo={groupInfo} key={referrerChangeAudits[0]!.id} />;
      }

      return <UpdateEntityAuditLog audit={audits} groupInfo={groupInfo} key={audit[0]?.id} />;
    });
  }, [groupInfo, audit]);

  return <>{ApplicationUpdateAuditLogs}</>;
};
