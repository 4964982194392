import { useAuth0 } from '@auth0/auth0-react';
import { useAsync } from 'react-use';

export const useLogin = () => {
  const { loginWithRedirect, isAuthenticated, user, isLoading } = useAuth0();

  useAsync(async () => {
    if (!isAuthenticated && !user && !isLoading) {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, user]);

  return isAuthenticated && user;
};
