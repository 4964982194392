import React from 'react';
import type { CreateAudit, UpdateAudit } from '@lama/audit-service-client';
import type { FC } from 'react';
import type { EntityAuditLogProps } from '../../GroupedAudit';
import type { EmailTrackingInternalStatus } from '../../../../../../../../packages/email-sender-client/dist/src';
import { EmailTrackingUpdateAuditLogs } from './EmailTrackingUpdateAuditLog';
import { EmailTrackingCreatedAuditLogs } from './EmailTrackingCreatedAuditLog';

export const emailTrackingStatusToDisplay = (status: EmailTrackingInternalStatus) => {
  switch (status) {
    case 'opened': {
      return 'opened by';
    }
    case 'undelivered': {
      return 'not delivered to';
    }
    default: {
      return null;
    }
  }
};

export const emailTypeToDisplay = (type: string) => {
  switch (type) {
    case 'approvalEmail': {
      return 'Approval';
    }
    case 'adverseActionNotice': {
      return 'Adverse Action Notice';
    }
    case 'letterOfIncompleteness': {
      return 'Letter of Incompleteness';
    }
    case 'reminder': {
      return 'Reminder';
    }
    default: {
      return null;
    }
  }
};

export const EmailTrackingAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type === 'update') {
    return <EmailTrackingUpdateAuditLogs audit={audit as UpdateAudit[]} groupInfo={groupInfo} />;
  }

  if (groupInfo.type === 'create') {
    return <EmailTrackingCreatedAuditLogs audit={audit as CreateAudit[]} groupInfo={groupInfo} />;
  }

  return null;
};
