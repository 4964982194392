import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { GetDocumentsRequestQuery, DocumentWithIssues } from '@lama/document-service-client';
import { createDocumentServiceClient } from '@lama/document-service-client';
import { documentServiceUrl } from '../../../../framework/environment';

export const useApplicationDocumentsQuery = (params: GetDocumentsRequestQuery, options?: UseQueryOptions<DocumentWithIssues[]>) => {
  const { getAccessTokenSilently } = useAuth0();

  const getApplicationDocumentsQuery = useCallback(async () => {
    const token = await getAccessTokenSilently();

    return createDocumentServiceClient(documentServiceUrl).getDocuments({ includeIssues: true, ...params }, token);
  }, [getAccessTokenSilently, params]);

  const query = useQuery<DocumentWithIssues[]>({
    queryKey: ['applicationDocuments', ...Object.values(params)],
    queryFn: getApplicationDocumentsQuery,
    staleTime: Number.POSITIVE_INFINITY,
    ...options,
  });

  return query;
};
