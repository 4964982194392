import React, { useCallback, useContext, useState } from 'react';
import type { FC } from 'react';
import { Button, Flex, greenPalette, redPalette } from '@lama/design-system';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { displayToast, useConfirmModal } from '@lama/app-components';
import { v4 as uuid4 } from 'uuid';
import { UserDetailsContext } from '../../../../../shared/context/UserDetailsContext';
import { DismissApplicationDialog } from '../../../ApplicationHeader/ApplicationWorkflow/DismissApplicationDialog';
import { ApplicationContext } from '../../../ApplicationContext';
import { useCreateDecisionMutation } from '../../../../../shared/hooks/react-query/opportunity/useCreateDecisionMutation';
import { useUserQuery } from '../../../../../shared/hooks/react-query/user/useUserQuery';
import { DecisionMakerTitle } from './DecisionMakerTitle';
import { StyledDecisionCardContainer } from './StyledDecisionCardContainer';

interface DecisionMakingCardProps {
  role: string;
  canCurrentUserApprove?: boolean;
}

export const DecisionMakingCard: FC<DecisionMakingCardProps> = ({ role, canCurrentUserApprove }) => {
  const { opportunity } = useContext(ApplicationContext);
  const [dismissApplicationModalOpen, setDismissApplicationModalOpen] = useState(false);
  const { firstName, lastName, userId } = useContext(UserDetailsContext);
  const { data: user } = useUserQuery(userId);
  const { confirm } = useConfirmModal();
  const { mutateAsync: createDecision } = useCreateDecisionMutation(opportunity.id, {
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });

  const onClickApprove = useCallback(async () => {
    const confirmed = await confirm({ title: 'Are you sure you want to approve this application?', confirmText: 'Approve' });

    if (confirmed) {
      await createDecision({
        decision: 'Closing',
        roles: user?.roles ?? [],
        userId: userId ?? '',
        id: uuid4(),
      });
    }
  }, [confirm, createDecision, user?.roles, userId]);

  const onClickReject = useCallback(() => {
    setDismissApplicationModalOpen(true);
  }, []);

  const onDismissApplicationModalDone = useCallback(() => {
    setDismissApplicationModalOpen(false);
  }, []);

  return (
    <>
      <StyledDecisionCardContainer>
        <DecisionMakerTitle role={role} firstName={firstName ?? 'Unknown'} lastName={lastName ?? 'User'} />
        <Flex justifyContent={'flex-end'} alignItems={'center'}>
          <Flex gap={4} alignItems={'center'}>
            <Button
              startIcon={<CheckCircle />}
              size={'m'}
              variant={'secondary'}
              color={'neutral'}
              iconColor={greenPalette[500]}
              onClick={onClickApprove}
              disabled={!canCurrentUserApprove}
            >
              {'Approve'}
            </Button>
            <Button
              startIcon={<Cancel />}
              size={'m'}
              variant={'secondary'}
              color={'neutral'}
              iconColor={redPalette[500]}
              onClick={onClickReject}
              disabled={!canCurrentUserApprove}
            >
              {'Dismiss'}
            </Button>
          </Flex>
        </Flex>
      </StyledDecisionCardContainer>
      {dismissApplicationModalOpen ? (
        <DismissApplicationDialog open={dismissApplicationModalOpen} onClose={onDismissApplicationModalDone} mode={'decision'} />
      ) : null}
    </>
  );
};
