import React, { useMemo, useCallback } from 'react';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { useUpdateRequirement } from '../../../../shared/hooks/react-query/requirement/useUpdateRequirement';
import { RequirementAlert } from './RequirementAlert';

interface RequirementDismissedAlertProps {
  requirement: EvaluatedOpportunityRequirement;
}

export const RequirementDismissedAlert: React.FC<RequirementDismissedAlertProps> = ({
  requirement: { id: requirementId, excludedEntities, statusOverrideByEntity, entityId, dismissDataByEntity, opportunityId },
}) => {
  const { mutateAsync: updateRequirement } = useUpdateRequirement(opportunityId, requirementId);

  const onClick = useCallback(async () => {
    await updateRequirement({
      updateRequirementPayload: { statusOverrideByEntity: { [entityId]: null } },
    });
  }, [entityId, updateRequirement]);

  const dismissal = useMemo(() => {
    if (statusOverrideByEntity?.[entityId] === 'Dismissed' && dismissDataByEntity?.[entityId]) {
      return dismissDataByEntity?.[entityId];
    }

    return excludedEntities?.find((e) => e.entityId === entityId);
  }, [dismissDataByEntity, entityId, excludedEntities, statusOverrideByEntity]);

  const reason = `This requirement was dismissed by the applicant: ${dismissal?.description ?? dismissal?.reason ?? ''}`;

  return <RequirementAlert level={'info'} message={reason} primaryActionLabel={'Request Again'} onPrimaryActionClick={onClick} />;
};
