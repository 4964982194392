import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import type { SourcedProperty } from '@lama/properties';
import { BorderedContainer } from '../../Application/shared/BorderedContainer';

const StyledText = styled(Text)`
  overflow-wrap: break-word;
  overflow: hidden;
`;

const customComponents: Record<string, FC<{ property: SourcedPropertyWithFormattedValue }>> = {
  description: ({ property: { displayName, formattedValue } }) => (
    <Flex flexDirection={'column'} gap={2}>
      <Text variant={'body2'}>{displayName}</Text>
      <Text variant={'body2'}>{formattedValue}</Text>
    </Flex>
  ),
  yesNoQuestion: ({ property: { displayName, selectedPropertyValue } }) => (
    <Flex flexDirection={'column'} gap={2}>
      <Text variant={'body2'}>{displayName}</Text>
      <RadioGroup value={!!selectedPropertyValue?.value}>
        <Flex gap={2}>
          <FormControlLabel value control={<Radio />} label={'Yes'} />
          <FormControlLabel value={false} control={<Radio />} label={'No'} />
        </Flex>
      </RadioGroup>
    </Flex>
  ),
};

export interface SourcedPropertyWithFormattedValue extends SourcedProperty {
  formattedValue: string;
}

interface RequirmentPropertiesProps {
  properties: SourcedPropertyWithFormattedValue[];
}

export const RequirementProperties: FC<RequirmentPropertiesProps> = ({ properties }) => {
  const propertyComponents = useMemo(
    () =>
      properties.map((p) => {
        const { displayName, type, formattedValue, customComponent, propertyId } = p;

        const CustomPropeortyComponent = customComponent ? customComponents[customComponent] : null;

        if (CustomPropeortyComponent) {
          return <CustomPropeortyComponent key={propertyId ?? displayName} property={p} />;
        }

        if (type === 'table') {
          return null;
        }

        return (
          <Flex key={propertyId} gap={4} width={'100%'} justifyContent={'space-between'}>
            <Flex flex={0.4}>
              <Text color={'text.secondary'} variant={'body2'}>
                {displayName}
              </Text>
            </Flex>
            <Flex flex={0.6} maxWidth={'50%'}>
              <StyledText variant={'body2'}>{formattedValue || '-'}</StyledText>
            </Flex>
          </Flex>
        );
      }),
    [properties],
  );

  if (!properties.length) {
    return (
      <BorderedContainer>
        <Text variant={'body2'}>{'No information found'}</Text>
      </BorderedContainer>
    );
  }

  return <BorderedContainer>{propertyComponents}</BorderedContainer>;
};
