/* eslint-disable react/jsx-no-bind */
import React from 'react';
import type { FC } from 'react';
import { LoadingPage } from '@lama/app-components';
import { DocumentList } from './DocumentList';
import type { GroupDocumentListProps } from './types';
import { useDocumentDetailsByEntity } from './hooks/useDocumentDetailsByEntity';

export const EntityDocumentList: FC<GroupDocumentListProps> = React.memo(({ documents }) => {
  const { groups: documentGroups, loading } = useDocumentDetailsByEntity(documents);

  if (loading) {
    return <LoadingPage />;
  }

  if (!documents.length) {
    return null;
  }

  return <DocumentList groups={documentGroups} />;
});
