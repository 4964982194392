import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Avatar, Card, Stack, Typography, useTheme } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import useToggle from 'react-use/lib/useToggle';
import { Line } from 'react-chartjs-2';
import { purple, teal } from '@mui/material/colors';
import { Collapse } from '@lama/design-system';
import type { ConditionField, ConditionStatus } from '../../../../../shared/types/conditionTypes';
import { DataSourceAvatars } from '../../../../../shared/components/DataSourceAvatars';
import ExpandButton from '../../../../../shared/components/ExpandButton';
import { StatusFlagOutlined } from './StatusFlag';
import { FlagReasonBanner } from './FlagReasonBanner';

interface ConditionFieldCardProps {
  conditionField: ConditionField;
  conditionStatus: ConditionStatus;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
};

const onGraphClick = (event: any) => {
  event.stopPropagation();
};

export const ConditionFieldCard: FC<ConditionFieldCardProps> = ({
  conditionField: { values, flagReason, name, value, dataSources, hasInsight },
  conditionStatus,
}) => {
  const theme = useTheme();
  const [open, toggleOpen] = useToggle(false);

  const data = useMemo(
    () => ({
      labels: values?.map(({ date }) => date),
      datasets: [
        {
          label: 'Current',
          data: values?.map(({ current }) => current) ?? [],
          borderColor: teal[100],
          backgroundColor: teal[400],
        },
        {
          label: 'Peer Benchmark',
          data: values?.map(({ peerBenchmark }) => peerBenchmark) ?? [],
          borderColor: purple[100],
          backgroundColor: theme.palette.primary.main,
        },
      ],
    }),
    [values, theme.palette.primary.main],
  );

  const hasContent = useMemo(() => !!values?.length || !!flagReason, [flagReason, values?.length]);

  return (
    <Card
      sx={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          cursor: values?.length ? 'pointer' : 'inherit',
        },
        padding: '16px 12px',
      }}
      variant={'outlined'}
      onClick={toggleOpen}
    >
      <Stack alignItems={'center'} direction={'row'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <StatusFlagOutlined status={conditionStatus} sx={{ opacity: flagReason ? 1 : 0, height: '24px', width: '24px' }} />
          <Typography color={theme.palette.text.secondary} variant={'body1'}>
            {name}
          </Typography>
          <Typography>{'-'}</Typography>
          <Typography color={theme.palette.text.primary} variant={'body1'}>
            {value}
          </Typography>
        </Stack>
        <Stack alignItems={'center'} spacing={2} sx={{ marginLeft: 'auto' }} direction={'row'}>
          {hasInsight ? <Avatar sx={{ width: '24px', height: '24px' }} src={'/images/AIBadge.png'} /> : null}
          {dataSources?.length ? <DataSourceAvatars dataSources={dataSources} /> : null}
          <ExpandButton sx={{ opacity: hasContent ? 1 : 0 }} theme={theme} expand={open} />
        </Stack>
      </Stack>
      {hasContent ? (
        <Collapse expanded={open}>
          <Stack spacing={2} pt={2}>
            {flagReason ? <FlagReasonBanner flagReason={flagReason} status={conditionStatus} /> : null}
            {values?.length ? <Line onClick={onGraphClick} options={options} data={data} /> : null}
          </Stack>
        </Collapse>
      ) : null}
    </Card>
  );
};
