import React, { useContext, useMemo } from 'react';
import * as selectors from '@lama/selectors';
import { sumBy } from 'lodash-es';
import type { CellContent } from '../../creditMemoTable/CreditMemoTable';
import { CreditMemoTable } from '../../creditMemoTable/CreditMemoTable';
import { ApplicationContext } from '../../../../ApplicationContext';
import { totalRow } from '../../creditMemoTable/creditMemoTableRowCreator';

const headers = [
  { text: 'Type', width: 30 },
  { text: 'Market Value', width: 14 },
  { text: 'Advance Rate', width: 14 },
  { text: 'Net Amount', width: 14 },
  { text: 'Prior Lien', width: 14 },
  { text: 'Net Collateral Value', width: 14 },
];

export const CollateralSummaryTable = () => {
  const { application } = useContext(ApplicationContext);

  const rows = useMemo(() => {
    const collateralValueByType = selectors.collateralValueByType(application);

    const collateralTypeRows = collateralValueByType.map<CellContent[]>((item) => [
      { value: item.type },
      { value: item.marketValue, type: 'currency', align: 'right' },
      { value: item.advanceRate, type: 'percent', align: 'right' },
      { value: item.netAmount, type: 'currency', align: 'right' },
      { value: item.priorLien, type: 'currency', align: 'right' },
      { value: item.netCollateralValue, type: 'currency', align: 'right' },
    ]);

    const totalsRowCellValues: CellContent[] = [
      { value: sumBy(collateralValueByType, 'marketValue'), type: 'currency', align: 'right' },
      { value: '', align: 'right' }, // empty cell for advance rate total
      { value: sumBy(collateralValueByType, 'netAmount'), type: 'currency', align: 'right' },
      { value: sumBy(collateralValueByType, 'priorLien'), type: 'currency', align: 'right' },
      { value: sumBy(collateralValueByType, 'netCollateralValue'), type: 'currency', align: 'right' },
    ];

    const totalRowDefinition = totalRow({ cells: totalsRowCellValues });

    return [...collateralTypeRows, totalRowDefinition];
  }, [application]);

  return <CreditMemoTable headers={headers} rows={rows} />;
};
