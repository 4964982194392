import React from 'react';
import type { FC } from 'react';
import { AuditLog } from '../../AuditLog';
import type { UpdateAuditLogProps } from '../types';

export const UpdateFinancialsAuditLog: FC<UpdateAuditLogProps> = ({ groupInfo }) => (
  <AuditLog
    {...(groupInfo.originType === 'user'
      ? { originType: groupInfo.originType, user: groupInfo.user }
      : { originType: groupInfo.originType })}
    timestamp={groupInfo.timestamp}
    text={'Updated Financials'}
  />
);
