import { useContext, useMemo } from 'react';
import type { FinancialData, GetAttributesResponse } from '@lama/contracts';
import { useGetFormTypesQuery } from '../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useGetFormTypesQuery';
import { getFormAttributesWithFinancialData, isFinancialRelatedToFormPage } from '../Forms/TaxReturns/formFinancialsUtils';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';
import type { DocumentPageRange } from './types';

import { useGetAllFinancialsForCurrentDocument } from './useGetAllFinancialsForCurrentDocument';

export const getRelevantFinancialsForFormType = (
  documentId: string,
  financials: FinancialData[],
  formType: string,
  startPage: number,
  endPage?: number,
) => financials?.filter((financial) => formType && isFinancialRelatedToFormPage(financial.source, documentId, startPage, endPage));

export const useGetAllDocumentAttributesWithFinancialData = (
  formsByPages: DocumentPageRange[],
  documentAttributes: GetAttributesResponse['attributes'],
) => {
  const { currentDocument } = useContext(SpreadingDocumentContext);
  const { data: allFormTypes, isPending: formTypesLoading } = useGetFormTypesQuery();
  const { entityFinancials, fetchingData } = useGetAllFinancialsForCurrentDocument();
  const formAttributesWithFinancialData = useMemo(() => {
    if (formTypesLoading || !documentAttributes || fetchingData) {
      return [];
    }

    const result = formsByPages.map(({ startDocumentPage, endDocumentPage }) => {
      const currentFormType = allFormTypes?.formTypes.find(({ id }) => id === startDocumentPage.formType);
      const relevantFinancials = getRelevantFinancialsForFormType(
        currentDocument.id,
        entityFinancials ?? [],
        startDocumentPage.formType,
        startDocumentPage.page,
        endDocumentPage.page,
      );

      const formData = getFormAttributesWithFinancialData(
        documentAttributes.filter(({ formId }) => formId === startDocumentPage.formType),
        relevantFinancials ?? [],
        startDocumentPage.page,
        currentFormType,
        endDocumentPage.page,
      );
      return { formData, startDocumentPage, endDocumentPage };
    });
    return result.flat();
  }, [formTypesLoading, documentAttributes, fetchingData, formsByPages, allFormTypes?.formTypes, entityFinancials, currentDocument.id]);
  return { formAttributesWithFinancialData, loading: fetchingData };
};
