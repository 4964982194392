import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { Entity } from '@lama/common-types';
import { v4 as uuidv4 } from 'uuid';
import { createDocumentServiceClient } from '@lama/document-service-client';
import type { Document } from '@lama/document-service-client';
import { uploadToS3 } from '../../../clients/s3';
import { documentServiceUrl } from '../../../../framework/environment';

interface UploadFileParams {
  documentId?: string;
  file: File;
  description: string;
  requirementId?: string;
  topic: Document['topic'];
  applicationId: string;
  entityId: string;
  entityType: Entity;
  sharedRequirementId?: string;
  requirementKey?: string;
  exportConfiguration?: any;
  extractable?: boolean;
  relatedItemId?: string;
  relatedItemType?: string;
}

export const useUploadDocumentsMutation = (options?: UseMutationOptions<void, unknown, UploadFileParams[]>) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const uploadDocumentsQuery = useCallback(
    async (uploadFileParams: UploadFileParams[]) => {
      const token = await getAccessTokenSilently();

      const documentServiceClient = createDocumentServiceClient(documentServiceUrl);

      await Promise.all(
        uploadFileParams.map(
          async ({
            documentId: documentIdParam,
            file,
            description,
            requirementId,
            applicationId,
            entityId,
            entityType,
            topic,
            sharedRequirementId,
            requirementKey,
            exportConfiguration,
            extractable,
            relatedItemId,
            relatedItemType,
          }) => {
            const documentId = documentIdParam ?? uuidv4();
            const signedUploadUrl = await documentServiceClient.getSignedUploadUrl(
              {
                id: documentId,
                applicationId,
                relatedEntityId: entityId,
                relatedEntityType: entityType,
                topic,
                description,
                filename: file.name,
                requirementId,
                sharedRequirementId,
                requirementKey,
                exportConfiguration,
                extractable,
                relatedItemId,
                relatedItemType,
              },
              token,
            );

            await uploadToS3(signedUploadUrl, file);

            await documentServiceClient.updateDocument({ documentId, payload: { status: 'Uploaded' } }, token);
          },
        ),
      );
    },
    [getAccessTokenSilently],
  );

  return useMutation({
    mutationFn: uploadDocumentsQuery,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['applicationDocuments'] });
      await queryClient.invalidateQueries({ queryKey: ['opportunityRequirements'] });
    },
    ...options,
  });
};
