import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { getEnabledFileUploadSources } from '@lama/evaluations';
import type { ItemDocumentsProps } from '@lama/app-components';
import { LoadingPage } from '@lama/app-components';
import { ApplicationContext } from '../../../../ApplicationContext';
import { DocumentBox } from '../../../../../../shared/components/DocumentBox/DocumentBox';
import { useApplicationDocumentsQuery } from '../../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { UploadArbitraryDocumentButton } from '../../../../../../shared/components/DocumentBox/UploadArbitraryDocumentButton';

export const ItemDocuments: FC<ItemDocumentsProps> = ({ requirement, itemId, formContext }) => {
  const {
    id: requirementId,
    sources,
    entityId,
    entityType,
    referenceRequirementId,
    key: requirementKey,
    exportConfiguration,
    properties,
  } = requirement;
  const { application, opportunity } = useContext(ApplicationContext);
  const { data: documents, isPending: loadingDocuments } = useApplicationDocumentsQuery({ applicationId: application.id });

  const itemType = useMemo(() => properties.find((p) => p.type === 'table')?.fieldName ?? '', [properties]);

  const itemDocuments = useMemo(
    () => documents?.filter((document) => document.relatedItemId && document.relatedItemId === itemId) ?? [],
    [documents, itemId],
  );

  const enabledSources = useMemo(
    () =>
      getEnabledFileUploadSources({
        sources: sources.uploadFilesSource ?? [],
        entityId,
        application,
        opportunity: opportunity ? { ...opportunity, applicationId: opportunity.application.id } : undefined,
        entityContext: formContext,
      }),
    [sources.uploadFilesSource, entityId, application, opportunity, formContext],
  );

  const requirementHasExtractableSources = enabledSources.some(({ extractable }) => extractable);

  const documentBoxes = useMemo(() => {
    const predefinedDocumentsComponents =
      enabledSources.map(({ name: documentName, topic, dismissDataByEntity, extractable }) => (
        <DocumentBox
          key={documentName}
          description={documentName}
          topic={topic}
          document={itemDocuments.find((document) => document.topic === topic)}
          requirement={requirement}
          sharedRequirementId={referenceRequirementId}
          requirementKey={requirementKey}
          entityId={entityId}
          entityType={entityType}
          applicationId={application.id}
          dismissed={dismissDataByEntity?.[entityId]}
          extractable={extractable}
          relatedItemId={itemId}
          relatedItemType={itemType}
        />
      )) ?? [];

    const topics = new Set(enabledSources.map((file) => file.topic));
    const arbitraryDocumentsComponents = itemDocuments
      .filter((document) => !document.topic || !topics.has(document.topic))
      .map((document) => (
        <DocumentBox
          key={document.id}
          description={document.filename}
          topic={document.topic}
          document={document}
          requirement={requirement}
          sharedRequirementId={referenceRequirementId}
          requirementKey={requirementKey}
          entityId={entityId}
          entityType={entityType}
          applicationId={application.id}
          relatedItemId={itemId}
          relatedItemType={itemType}
        />
      ));

    return [...predefinedDocumentsComponents, ...arbitraryDocumentsComponents];
  }, [
    enabledSources,
    itemDocuments,
    requirement,
    referenceRequirementId,
    requirementKey,
    entityId,
    entityType,
    application.id,
    itemId,
    itemType,
  ]);

  if (loadingDocuments) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={4}>
      {documentBoxes}
      <Flex justifyContent={'center'}>
        <UploadArbitraryDocumentButton
          applicationId={application.id}
          entityId={entityId}
          entityType={entityType}
          requirementId={requirementId}
          sharedRequirementId={referenceRequirementId}
          requirementKey={requirementKey}
          exportConfiguration={exportConfiguration}
          extractable={requirementHasExtractableSources}
          relatedItemId={itemId}
          relatedItemType={itemType}
        />
      </Flex>
    </Flex>
  );
};
