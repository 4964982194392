import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import { sortBy } from 'lodash-es';
import styled from 'styled-components';
import { Flex, Text } from '@lama/design-system';
import { RenderRequirementView } from './RenderRequirementView';

const StyledFlex = styled(Flex)`
  page-break-before: always;
`;

interface CategoryPrintViewProps {
  requirements: EvaluatedOpportunityRequirement[];
  categoryDisplayName: string;
}
export const CategoryPrintView: FC<CategoryPrintViewProps> = ({ requirements, categoryDisplayName }) => {
  const sortedRequirements = useMemo(
    () =>
      sortBy(
        requirements.filter(
          (r) =>
            !r.visibleOnlyToLender && !r.sources.uploadFilesSource?.length && r.status !== 'DismissedByLender' && r.status !== 'Dismissed',
        ),
        (requirement) => requirement.viewIndex ?? 1000,
      ),
    [requirements],
  );

  return (
    <StyledFlex flexDirection={'column'}>
      <Text variant={'h4'}>{categoryDisplayName}</Text>
      {sortedRequirements.map((r) => (
        <RenderRequirementView key={r.id} requirement={r} />
      ))}
    </StyledFlex>
  );
};
