/* eslint-disable @typescript-eslint/naming-convention */
import type { MutableRefObject, FC } from 'react';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Avatar, useConfirmModal } from '@lama/app-components';
import { useAsyncFn } from 'react-use';
import { Flex, Spinner, Text } from '@lama/design-system';
import { grey } from '@mui/material/colors';
import { personFullName } from '@lama/data-formatters';
import { ApplicationContext } from '../ApplicationContext';
import { useUsersByPartnerQuery } from '../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { useUpdateOpportunityMutation } from '../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { SubMenuHeader } from '../ActionsMenu/SubMenuHeader';
import { ActionMenuSearchInput } from '../ActionsMenu/ActionMenuSearchInput';

interface CreateRequirementMenuProps {
  menuProps: any;
  open: boolean;
  menuRef: MutableRefObject<null>;
  onClose: () => void;
}

export const AssignOpportunityOwnerMenu: FC<CreateRequirementMenuProps> = ({ menuProps, open, menuRef, onClose }) => {
  const { confirm } = useConfirmModal();
  const {
    opportunity: { id: opportunityId, assigneeIds, partnerId },
  } = useContext(ApplicationContext);

  const [filterOptions, setFilterOptions] = useState('');
  const { email } = useContext(UserDetailsContext);
  const { data: users } = useUsersByPartnerQuery(partnerId);
  const [modfiedAssigneeId, setModfiedAssigneeId] = useState<string | null>(null);

  const { mutateAsync: updateOpportunity } = useUpdateOpportunityMutation(opportunityId);

  const [{ loading }, updateOpportunityOwner] = useAsyncFn(
    async (event: React.MouseEvent<HTMLElement>) => {
      const userId = event.currentTarget.getAttribute('value');

      if (!userId) {
        return;
      }

      setModfiedAssigneeId(userId);

      if (assigneeIds?.includes(userId)) {
        const confirmed = await confirm({
          title: `Are you sure you want to unassign ${users?.find((user) => user.id === userId)?.firstName}?`,
        });
        if (confirmed) {
          await updateOpportunity({ assigneeIds: assigneeIds.filter((id) => id !== userId) }, { onSuccess: onClose });
        }
      } else {
        await updateOpportunity({ assigneeIds: [...(assigneeIds ?? []), userId] }, { onSuccess: onClose });
      }

      setModfiedAssigneeId(null);
    },
    [assigneeIds, confirm, onClose, updateOpportunity, users],
  );

  const handleClose = useCallback(() => {
    setFilterOptions('');
    onClose();
  }, [onClose]);

  const onFilterChange = useCallback((value: string) => {
    setFilterOptions(value);
  }, []);

  const userOptions = useMemo(() => {
    const filteredUsers = users?.filter((user) =>
      `${user.firstName.toLocaleLowerCase()} ${user.lastName.toLocaleLowerCase()}`.includes(filterOptions.toLocaleLowerCase()),
    );

    if (email?.includes('@lama.ai')) {
      return filteredUsers?.map((user) => ({ user, id: user.id })) ?? [];
    }
    return filteredUsers?.filter((user) => !user.email.includes('@lama.ai')).map((user) => ({ user, id: user.id })) ?? [];
  }, [email, filterOptions, users]);

  return (
    <Menu {...menuProps} anchorEl={menuRef.current} open={open} onClose={handleClose}>
      <Flex flexDirection={'column'} gap={2}>
        <SubMenuHeader text={'Assign Owner'} />
        <ActionMenuSearchInput onInputChange={onFilterChange} />
        <Flex flexDirection={'column'} gap={2} pt={2} px={2} maxHeight={'300px'} overflow={'auto'}>
          {userOptions.map(({ id, user }) => (
            <MenuItem
              key={id}
              value={id}
              onClick={updateOpportunityOwner}
              sx={{
                backgroundColor: assigneeIds?.includes(id) ? 'primary.light' : '',
                '&:hover': { bgcolor: grey[100] },
                borderRadius: '4px',
                px: 2,
              }}
            >
              <Flex flex={1} flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                <Flex maxWidth={'90%'} flexDirection={'row'} gap={2} alignItems={'center'}>
                  <Avatar firstName={user.firstName} lastName={user.lastName} />
                  <Text variant={'body1'} ellipsis>
                    {personFullName(user)}
                  </Text>
                </Flex>
                <Flex flex={0.2} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                  {modfiedAssigneeId === id && loading ? <Spinner size={'s'} /> : null}
                </Flex>
              </Flex>
            </MenuItem>
          ))}
        </Flex>
      </Flex>
    </Menu>
  );
};
