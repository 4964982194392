/* eslint-disable react/jsx-no-bind */
import React, { useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import type { EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { Document } from '@lama/document-service-client';
import { Flex, Text } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isDocumentRelatedToRequirement } from '../../shared/utils/isDocumentRelatedToRequirement';
import { RequirementDocuments } from '../Application/shared/RequirementDocuments';

interface DocumentsCategoryListProps {
  title: string;
  icon?: ReactNode;
  relation?: string;
  requirements: EvaluatedOpportunityRequirement[];
  applicationDocuments: Document[];
}

export const RequirementsDocumentsList: FC<DocumentsCategoryListProps> = ({
  title,
  icon,
  relation,
  applicationDocuments,
  requirements,
}) => {
  const { fibtDemo } = useFlags();

  const documentsByRequirementId = useMemo(
    () => Object.fromEntries(requirements.map((r) => [r.id, applicationDocuments.filter((d) => isDocumentRelatedToRequirement(d, r))])),
    [applicationDocuments, requirements],
  );

  const hasDocuments = useMemo(
    () =>
      Object.values(documentsByRequirementId).some((docs) => docs.length > 0) ||
      requirements.some((r) => r.sources.uploadFilesSource?.length),
    [documentsByRequirementId, requirements],
  );

  if (!hasDocuments) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={6}>
      <Flex flexDirection={'column'} gap={1}>
        <Text variant={'body1'}>{title}</Text>
        {fibtDemo ? null : icon ? (
          <Flex gap={2} alignItems={'center'}>
            {icon}
            <Text variant={'body3'} color={'secondary'} ellipsis data-sentry-unmask>
              {relation}
            </Text>
          </Flex>
        ) : null}
      </Flex>
      <Flex flexDirection={'column'} gap={4}>
        {requirements.map((requirement) =>
          documentsByRequirementId[requirement.id] ? (
            <RequirementDocuments
              requirement={requirement}
              requirementDocuments={documentsByRequirementId[requirement.id]!}
              key={requirement.id}
            />
          ) : null,
        )}
      </Flex>
    </Flex>
  );
};
