import type { ApplicationApiModel } from '@lama/clients';
import type { BusinessApiModel, RelatedPersonApiModel } from '@lama/business-service-client';
import type { DocusignTemplateDefinition, EnvelopeTracking } from '@lama/docusign-client';
import { principals as principalsSelector, relatedBusinessesByRelationSelector } from '@lama/data-formatters';
import { applicationPrimaryContactSelector } from '@lama/selectors';
import { compact } from 'lodash-es';

const isPrimaryContact = (primaryContactId: string, personId: string) => primaryContactId === personId;

export const matchEnvelopesAndRecipients = ({
  templates,
  application,
  envelopesTrackings,
}: {
  application: ApplicationApiModel;
  envelopesTrackings?: EnvelopeTracking[];
  templates: DocusignTemplateDefinition[];
}) => {
  const primaryContact = applicationPrimaryContactSelector(application);
  const borrowingBusinesses = relatedBusinessesByRelationSelector(application, 'borrower').map(({ business }) => business);
  const principals = borrowingBusinesses?.flatMap(principalsSelector);

  return templates?.reduce<
    { id: string; people: RelatedPersonApiModel[]; templateId: string; envelopeTracking?: EnvelopeTracking; business?: BusinessApiModel }[]
  >((acc, { templateId, duplicatePerBusiness, recipientType }) => {
    switch (recipientType) {
      case 'primaryContact': {
        if (!primaryContact) {
          return acc;
        }

        const envelopeBusinesses = duplicatePerBusiness ? borrowingBusinesses : borrowingBusinesses.length ? [borrowingBusinesses[0]!] : [];

        envelopeBusinesses.forEach((b) => {
          const envelopeTracking = envelopesTrackings?.find(
            (e) => e.templateId === templateId && e.peopleIds?.includes(primaryContact.id) && e.businessId === b.id,
          );
          acc.push({
            id: `${templateId}_${primaryContact.id}_${b.id}`,
            people: [primaryContact],
            templateId,
            envelopeTracking,
            business: b,
          });
        });
        break;
      }
      case 'borrower': {
        principals?.forEach((p) => {
          const envelopeTracking = envelopesTrackings?.find((e) => e.templateId === templateId && e.peopleIds?.includes(p.id));
          acc.push({
            id: `${templateId}_${p.id}`,
            people: [p],
            templateId,
            envelopeTracking,
          });
        });

        break;
      }
      case 'principalsWithoutPrimaryContact': {
        const people = principals?.filter((p) => !isPrimaryContact(primaryContact?.id ?? '', p.id)) ?? [];

        people?.forEach((p) => {
          const envelopeTracking = envelopesTrackings?.find((e) => e.templateId === templateId && e.peopleIds?.includes(p.id));
          acc.push({
            id: `${templateId}_${p.id}`,
            people: [p],
            templateId,
            envelopeTracking,
          });
        });

        break;
      }
      default: {
        const envelopeTracking = envelopesTrackings?.find((e) => e.templateId === templateId);
        const people = [...(principals?.filter((p) => !isPrimaryContact(primaryContact?.id ?? '', p.id)) ?? []), primaryContact];
        acc.push({
          id: templateId,
          people: compact(people),
          templateId,
          envelopeTracking,
        });
      }
    }

    return acc;
  }, []);
};
