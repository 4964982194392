/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';
import { Tooltip } from '@lama/app-components';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useToggle } from 'react-use';
import type { EvaluatedOpportunityRequirement, OpportunityRequirementStatus } from '@lama/contracts';
import { Text } from '@lama/design-system';
import { isEmpty, omit } from 'lodash-es';
import { useUpdateRequirement } from '../../../../../shared/hooks/react-query/requirement/useUpdateRequirement';
import { menuProps } from '../../../ActionsMenu/ApplicationActionsMenu';
import { RequestAnotherDocumentModal } from './RequestAnotherDocumentModal';

interface RequirementHeaderActionsProps {
  requirement: EvaluatedOpportunityRequirement;
  opportunityId: string;
  statusOverride?: OpportunityRequirementStatus | null;
  status: OpportunityRequirementStatus;
}

export const RequirementHeaderActions: FC<RequirementHeaderActionsProps> = ({ opportunityId, requirement, statusOverride, status }) => {
  const [open, toggleOpen] = useToggle(false);
  const [addDocumentModalOpen, toggleAddDocumentModal] = useToggle(false);
  const menuRef = useRef(null);

  const { mutateAsync: updateRequirement } = useUpdateRequirement(opportunityId, requirement.id);

  const onDismissClick = useCallback(async () => {
    await updateRequirement({
      updateRequirementPayload: {
        statusOverrideByEntity: {
          [requirement.entityId]: status === 'DismissedByLender' ? null : 'DismissedByLender',
        },
      },
    });
    toggleOpen();
  }, [requirement.entityId, status, toggleOpen, updateRequirement]);

  const isDocumentRequirementScreen = useMemo(() => requirement.screen === 'documentRequirement', [requirement.screen]);

  const onMarkCompletedClick = useCallback(async () => {
    await updateRequirement({
      updateRequirementPayload: {
        statusOverrideByEntity: {
          [requirement.entityId]: status === 'Complete' ? null : 'Complete',
        },
      },
    });

    toggleOpen();
  }, [requirement.entityId, status, toggleOpen, updateRequirement]);

  const clearSubmissions = useCallback(async () => {
    if (requirement.submissions) {
      await updateRequirement({
        updateRequirementPayload: {
          submissions: {},
        },
      });

      const isMultipliedEntityType = ['business', 'person'].includes(requirement.entityType);
      const submissionsExcludingCurrentEntity = omit(requirement.submissions, requirement.entityId);
      if (isMultipliedEntityType && !isEmpty(submissionsExcludingCurrentEntity)) {
        await updateRequirement({
          updateRequirementPayload: {
            submissions: submissionsExcludingCurrentEntity,
          },
        });
      }
    }
  }, [requirement.entityId, requirement.entityType, requirement.submissions, updateRequirement]);

  const onReopenClick = useCallback(async () => {
    await clearSubmissions();
    toggleOpen();
  }, [toggleOpen, clearSubmissions]);

  const onOpenAddDocumentModal = useCallback(() => {
    toggleOpen();
    toggleAddDocumentModal();
  }, [toggleAddDocumentModal, toggleOpen]);

  return (
    <>
      <Tooltip title={'Actions'}>
        <IconButton
          onClick={toggleOpen}
          sx={{
            borderRadius: '8px',
            '&:hover': { bgcolor: 'primary.light' },
          }}
          ref={menuRef}
          role={'button'}
          disableRipple
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        key={'application-actions-menu'}
        anchorEl={menuRef.current}
        open={open}
        onClose={toggleOpen}
        {...menuProps}
        disableEnforceFocus
        MenuListProps={{ sx: { py: 0.5, px: 0.5 } }}
      >
        {statusOverride === 'Complete' ? (
          <MenuItem onClick={onMarkCompletedClick} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}>
            <Text variant={'body1'}>{'Mark pending'}</Text>
          </MenuItem>
        ) : !statusOverride && status !== 'Complete' ? (
          <MenuItem onClick={onMarkCompletedClick} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}>
            <Text variant={'body1'}>{'Mark complete'}</Text>
          </MenuItem>
        ) : null}
        {isDocumentRequirementScreen ? (
          <MenuItem onClick={onOpenAddDocumentModal} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}>
            <Text variant={'body1'}>{'Request additional document'}</Text>
          </MenuItem>
        ) : null}
        <MenuItem onClick={onDismissClick} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}>
          <Text variant={'body1'}>{status === 'DismissedByLender' ? 'Mark as required' : 'Dismiss requirement'}</Text>
        </MenuItem>
        <MenuItem
          onClick={onReopenClick}
          sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }}
          disabled={!requirement.submitted}
        >
          <Text variant={'body1'}>{'Reopen'}</Text>
        </MenuItem>
      </Menu>
      <RequestAnotherDocumentModal open={addDocumentModalOpen} onClose={toggleAddDocumentModal} requirement={requirement} />
    </>
  );
};
