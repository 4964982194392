import type { DataNode } from '@lama/design-system';
import { Flex, Text } from '@lama/design-system';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { ApplicationContext } from '../Application/ApplicationContext';
import { DocumentBox } from '../../shared/components/DocumentBox/DocumentBox';
import type { DocumentSubGroup } from './types';

interface DocumentListSubGroupProps {
  subGroup: DocumentSubGroup;
  moveToTreeData: DataNode[];
}
export const DocumentListSubGroup: FC<DocumentListSubGroupProps> = React.memo(({ subGroup, moveToTreeData }) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);

  const subGroupKey = useMemo(() => subGroup.title + subGroup.subtitle || subGroup.documents?.at(0)?.id, [subGroup]);

  if (!subGroup.documents?.length) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={3} key={subGroupKey} data-testid={`subgroup-${subGroup.title}`}>
      <Text variant={'body2'} color={'secondary'} px={2}>
        {subGroup.title}
      </Text>
      <Flex flexDirection={'column'} gap={3}>
        {subGroup.documents.map(({ document, requirement, ...details }) => (
          <DocumentBox
            applicationId={applicationId}
            key={document?.id || details.id || details.name + details.fileName + subGroup.title}
            document={document}
            description={details.name}
            dismissed={details.dismissed}
            entityId={requirement?.entityId}
            entityType={requirement?.entityType}
            requirement={requirement}
            topic={details.topic}
            sharedRequirementId={requirement?.referenceRequirementId}
            moveToTreeData={moveToTreeData}
          />
        ))}
      </Flex>
    </Flex>
  );
});
