import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { allApplicationPeopleSelector } from '@lama/data-formatters';
import { personFullName } from '@lama/selectors';
import type { EmailTracking } from '@lama/email-sender-client';
import type { AuditComponentProps, AuditGroupInfo } from '../types';
import { AuditLog } from '../../../AuditLog';
import { useUserQuery } from '../../../../../hooks/react-query/user/useUserQuery';
import { ApplicationContext } from '../../../../../../components/Application/ApplicationContext';
import { emailTypeToDisplay } from './EmailTrackingAuditLog';

interface EmailTrackingCreatedAuditLogProps {
  emailTracking: EmailTracking;
  groupInfo: AuditGroupInfo;
}

export const EmailTrackingCreatedAuditLog: FC<EmailTrackingCreatedAuditLogProps> = ({ groupInfo, emailTracking }) => {
  const { application } = useContext(ApplicationContext);

  const { data: recipientUser, isPending: loadingRecipient } = useUserQuery(emailTracking.recipientId, {
    enabled: emailTracking.recipientIdType !== 'person',
  });

  const text = useMemo(() => {
    const emailTypeDisplay = emailTypeToDisplay(emailTracking.emailType);

    const recipientPersonOrUser =
      emailTracking.recipientIdType === 'person'
        ? allApplicationPeopleSelector(application).find((p) => p.id === emailTracking.recipientId)
        : recipientUser;

    if (!emailTypeDisplay || !recipientPersonOrUser) {
      return null;
    }

    return `Sent ${emailTypeDisplay} email to ${personFullName(recipientPersonOrUser)}`;
  }, [application, emailTracking.emailType, emailTracking.recipientId, emailTracking.recipientIdType, recipientUser]);

  if (loadingRecipient) {
    return null;
  }

  if (!recipientUser || !text) {
    return null;
  }

  return (
    <AuditLog
      text={text}
      timestamp={groupInfo.timestamp}
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
    />
  );
};

export const EmailTrackingCreatedAuditLogs: FC<AuditComponentProps> = ({ audit, groupInfo }) => {
  const auditLogs = useMemo(
    () =>
      audit.map((a) => {
        const emailTracking = a.context.entity as EmailTracking;

        return <EmailTrackingCreatedAuditLog key={a.id} groupInfo={groupInfo} emailTracking={emailTracking} />;
      }),
    [groupInfo, audit],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{auditLogs}</>;
};
