/* eslint-disable @typescript-eslint/naming-convention */
import { v4 as uuidv4 } from 'uuid';
import type { UnderwritingNoteType } from '@lama/contracts';
import { Bookmark, BookmarkAdded } from '@mui/icons-material';
import type { FC } from 'react';
import React, { useState, useMemo, useCallback, useContext } from 'react';

import { useAsyncFn } from 'react-use';
import { AddNotePopover, HideOnPrintWrapper, displayToast } from '@lama/app-components';
import type { InputValue } from '@lama/design-system';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../ApplicationContext';
import { SecondaryActionButton } from '../../../shared/components/SecondaryActionButton';
import { useCreateNoteMutation } from '../../../shared/hooks/react-query/opportunity/useCreateNoteMutation';
import { useUpdateNoteMutation } from '../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { useDeleteNote } from '../../../shared/hooks/react-query/opportunity/useDeleteNote';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { BorderedEditableText } from './InlineNote/BorderedEditableText';
import { getUnderwritingNoteRelatedItemProperty, type UnderwritingNoteRelatedSectionType } from './noteUtils';

interface SectionDecisioningProps {
  sectionId: string;
  sectionType: UnderwritingNoteRelatedSectionType;
}

const noteToCopy: Record<UnderwritingNoteType, string> = {
  flag: 'Issue',
  general: 'Note',
  cleared: 'Cleared Issue',
  exception: 'Exception',
  reviewed: 'Reviewed',
  adjustment: 'Adjustment',
  inline: 'Note',
};

const displayedNoteTypes = new Set<UnderwritingNoteType>(['flag', 'exception']);

export const SectionDecisioning: FC<SectionDecisioningProps> = ({ sectionId, sectionType }) => {
  const { opportunity } = useContext(ApplicationContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { userId } = useContext(UserDetailsContext);

  const { mutateAsync: createNote } = useCreateNoteMutation(opportunity.id, userId);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunity.id, userId);
  const { mutateAsync: deleteNote } = useDeleteNote(opportunity.id);

  const currentNote = useMemo(() => {
    const sectionIdProperty = getUnderwritingNoteRelatedItemProperty(sectionType);
    return opportunity.underwriting?.notes?.find((note) => displayedNoteTypes.has(note.type) && note[sectionIdProperty] === sectionId);
  }, [opportunity.underwriting?.notes, sectionId, sectionType]);

  const [activeButton, setActiveButton] = useState<UnderwritingNoteType | null>(currentNote?.type ?? null);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    setActiveButton(null);
  }, []);

  const onDecisionClick = useCallback(
    (type: UnderwritingNoteType, button: HTMLButtonElement) => {
      if (activeButton === type) {
        setActiveButton(null);
        return;
      }
      setAnchorEl(button);
      setActiveButton(type);
    },
    [activeButton],
  );

  const onClickException = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onDecisionClick('exception', event.currentTarget);
    },
    [onDecisionClick],
  );

  const onClickFlag = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onDecisionClick('flag', event.currentTarget);
    },
    [onDecisionClick],
  );

  const [, deleteNoteInternal] = useAsyncFn(async () => {
    if (!currentNote) {
      return;
    }

    await deleteNote(currentNote.id);

    if (activeButton) {
      displayToast(`${noteToCopy[activeButton]} deleted`, 'info');
      setActiveButton(null);
    }
  }, [activeButton, currentNote, deleteNote]);

  const [, onSubmitNoteChange] = useAsyncFn(
    async ({ value: text }: InputValue) => {
      if (!currentNote) {
        return;
      }

      await updateNote({
        noteId: currentNote.id,
        notePayload: {
          text,
        },
      });
    },
    [currentNote, updateNote],
  );

  const [, onSubmitClick] = useAsyncFn(
    async (text: string) => {
      if (!activeButton) {
        return;
      }

      const sectionIdProperty = getUnderwritingNoteRelatedItemProperty(sectionType);
      await (currentNote
        ? updateNote({
            noteId: currentNote.id,
            notePayload: {
              type: activeButton,
              text,
            },
          })
        : createNote({
            id: uuidv4(),
            [sectionIdProperty]: sectionId,
            text,
            type: activeButton,
          }));
    },
    [activeButton, currentNote, updateNote, createNote, sectionType, sectionId],
  );

  return (
    <>
      <Flex flexDirection={'column'} gap={4}>
        <HideOnPrintWrapper>
          <Flex gap={4}>
            <SecondaryActionButton
              variant={'warning'}
              label={'Exception'}
              icon={BookmarkAdded}
              onClick={onClickException}
              selected={currentNote?.type === 'exception'}
            />
            <SecondaryActionButton
              variant={'danger'}
              label={'Flag Issue'}
              icon={Bookmark}
              onClick={onClickFlag}
              selected={currentNote?.type === 'flag'}
            />
          </Flex>
        </HideOnPrintWrapper>
        {currentNote ? (
          <BorderedEditableText value={currentNote?.text ?? ''} onSubmit={onSubmitNoteChange} onDelete={deleteNoteInternal} />
        ) : null}
      </Flex>
      <AddNotePopover
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        onSubmit={onSubmitClick}
        initialText={currentNote?.text}
        placeholder={'Leave a Comment...'}
      />
    </>
  );
};
