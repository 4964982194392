import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { InputValue } from '@lama/design-system';
import { RichInput } from '@lama/design-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../../ApplicationContext';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useCreateNoteMutation } from '../../../../shared/hooks/react-query/opportunity/useCreateNoteMutation';
import { useUpdateNoteMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateNoteMutation';
import { SegmentContainer } from '../SegmentContainer';
import { useUploadImage } from './hooks/useGetImageUploadUrl';

export const InlineNote: FC<{
  title: string;
  noteName: string;
  valueTemplate?: string;
  placeholder?: string;
  backwardCompatibleNoteName?: string;
  entityId?: string;
}> = ({ title, noteName, valueTemplate, placeholder, backwardCompatibleNoteName, entityId }) => {
  const { allowImageUploadInInlineNotes } = useFlags();
  const { opportunity } = useContext(ApplicationContext);
  const { userId } = useContext(UserDetailsContext);
  const { mutateAsync: addNote } = useCreateNoteMutation(opportunity.id, userId);
  const { mutateAsync: updateNote } = useUpdateNoteMutation(opportunity.id, userId);
  const { mutateAsync: uploadImage } = useUploadImage();

  const sectionNote = useMemo(
    () =>
      opportunity.underwriting?.notes?.find(
        ({ title: noteTitle, entityId: noteEntityId }) =>
          noteName &&
          noteTitle === noteName &&
          (entityId ? noteEntityId === entityId || noteEntityId === backwardCompatibleNoteName : true),
      ),
    [backwardCompatibleNoteName, entityId, noteName, opportunity.underwriting?.notes],
  );

  const onImageSelected = useCallback(
    async (img: File) => {
      const src = await uploadImage({ opportunityId: opportunity.id, filename: img.name, fileContents: img });
      return { src };
    },
    [opportunity.id, uploadImage],
  );

  const onNoteSubmit = useCallback(
    async ({ value }: InputValue) => {
      await (sectionNote
        ? updateNote({
            noteId: sectionNote.id,
            notePayload: { text: value, entityId, title: noteName },
          })
        : addNote({
            id: uuidv4(),
            text: value,
            title: noteName,
            entityId,
            type: 'inline',
          }));
    },
    [sectionNote, updateNote, addNote, noteName, entityId],
  );

  return (
    <SegmentContainer padding={'12px'} title={title}>
      <RichInput
        onImageSelected={allowImageUploadInInlineNotes ? onImageSelected : undefined}
        placeholder={placeholder ?? 'Type here'}
        value={sectionNote?.text ?? valueTemplate}
        onSubmit={onNoteSubmit}
      />
    </SegmentContainer>
  );
};
