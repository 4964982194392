/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { Flex, Text, greyPalette } from '@lama/design-system';
import type { RelatedApplication } from '@lama/contracts';
import { format } from 'date-fns';
import { Tooltip } from '@lama/app-components';
import styled from 'styled-components';
import { ApplicationNumber } from '../../../../shared/components/ApplicationNumber';
import { ApplicationStatusChip } from '../../../../shared/components/ApplicationStatusChip';
import { MatchDisplay } from './MatchDisplay';

const RowContainer = styled(Flex)`
  padding: 10px 8px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: ${greyPalette[50]};
  }
`;

interface RelatedApplicationRowProps {
  relatedApplication: RelatedApplication;
  onClick: (opportunityId: string) => void;
}

export const RelatedApplicationRow: FC<RelatedApplicationRowProps> = ({ relatedApplication, onClick }) => {
  const { application, matches } = relatedApplication;

  const lastStatusUpdatedAt = useMemo(
    () => ({
      display: format(new Date(application.lastStatusUpdatedAt), 'MM/dd/yyyy'),
      tooltip: `Last status update ${format(new Date(application.lastStatusUpdatedAt), 'MM/dd/yyyy HH:mm')}`,
    }),
    [application.lastStatusUpdatedAt],
  );

  const handleApplicationClick = useCallback(() => {
    onClick(application.leadingOpportunityId);
  }, [onClick, application]);

  if (!relatedApplication) {
    return null;
  }

  return (
    <RowContainer onClick={handleApplicationClick} justifyContent={'space-between'} gap={6}>
      <Flex gap={4} alignItems={'center'}>
        <ApplicationNumber numberLabel={application.applicationNumber} />
        <Text ellipsis>{application.displayName}</Text>
        <MatchDisplay matches={matches} />
      </Flex>
      <Flex gap={4} alignItems={'center'}>
        <Tooltip title={lastStatusUpdatedAt.tooltip} placement={'top'}>
          <Text variant={'body3'} color={'secondary'}>
            {lastStatusUpdatedAt.display}
          </Text>
        </Tooltip>
        <ApplicationStatusChip status={application.status} size={'small'} />
      </Flex>
    </RowContainer>
  );
};
