import type { FC, ReactNode } from 'react';
import React, { Suspense, useContext, useEffect } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Outlet, useParams } from 'react-router-dom';
import { Flex, Text } from '@lama/design-system';
import mixpanel from 'mixpanel-browser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useProductQuery } from '../../shared/hooks/react-query/product/useProductQuery';
import { useGetOpportunityQuery } from '../../shared/hooks/react-query/opportunity/useGetOpportunityQuery';
import { BackLink } from '../../shared/components/BackLink';
import { useOpportunityPropertiesQuery } from '../../shared/hooks/react-query/opportunity/useOpportunityPropertiesQuery';
import { ProductionApplicationIndication } from '../../shared/components/ProductionApplicationIndication';
import { NotificationCenter } from '../../shared/components/NotificationCenter/NotificationCenter';
import { ApplicationContext, ApplicationProvider } from './ApplicationContext';
import { ApplicationTabs } from './ApplicationTabs';
import { ApplicationHeader } from './ApplicationHeader/ApplicationHeader';
import { SmartAssistant } from './shared/SmartAssistant/SmartAssistant';
import { SearchButton } from './ApplicationSearch/SearchButton';

declare global {
  interface Window {
    opportunity?: {
      opportunityId: string;
      applicationId: string;
      productId: string;
      partnerId: string;
    };
  }
}

const OpportunityDataHelper: FC = () => {
  const { opportunity } = useContext(ApplicationContext);

  useEffect(() => {
    if (window.opportunity?.opportunityId !== opportunity.id) {
      window.opportunity = {
        opportunityId: opportunity.id,
        applicationId: opportunity.application.id,
        productId: opportunity.productId,
        partnerId: opportunity.partnerId,
      };
    }
  }, [opportunity]);

  return null;
};

const ApplicationLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { searchApplication } = useFlags();

  return (
    <Flex flexDirection={'column'} px={10} pt={10} pb={24} gap={4}>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <BackLink title={'Back to Pipeline'} to={'/pipeline'} state={{ preserved: true }} />
        <Flex gap={4}>
          {searchApplication ? <SearchButton /> : null}
          <NotificationCenter />
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} gap={5}>
        {children}
      </Flex>
    </Flex>
  );
};

export const OpportunityPage: FC = () => {
  const { opportunityId } = useParams();

  const { data: opportunity, isPending: loadingOpportunity } = useGetOpportunityQuery(opportunityId);
  const { data: properties, isPending: loadingProperties } = useOpportunityPropertiesQuery(opportunityId);
  const { data: product, isPending: loadingProduct } = useProductQuery(opportunity?.productId);

  if (loadingOpportunity || loadingProduct || loadingProperties) {
    return <LoadingPage />;
  }

  if (!opportunity || !product || !properties) {
    return (
      <ApplicationLayout>
        <Flex justifyContent={'center'} alignItems={'center'} height={'100%'}>
          <Text variant={'h4'}>{'Application not found'}</Text>
        </Flex>
      </ApplicationLayout>
    );
  }

  mixpanel.track_pageview({
    applicationId: opportunity.application.id,
    opportunityId,
    productId: opportunity.productId,
  });

  return (
    <ApplicationProvider product={product} opportunity={opportunity} properties={properties}>
      <OpportunityDataHelper />
      <ApplicationLayout>
        <ProductionApplicationIndication />
        <ApplicationHeader />
        <Flex flexDirection={'column'} gap={8}>
          <ApplicationTabs />
          <Suspense fallback={<LoadingPage />}>
            <Outlet />
          </Suspense>
        </Flex>
        <SmartAssistant />
      </ApplicationLayout>
    </ApplicationProvider>
  );
};
