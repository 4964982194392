import { type ApplicationStatus } from '@lama/contracts';
import React, { useMemo, type FC } from 'react';
import { personFullName } from '@lama/selectors';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import PendingIcon from '@mui/icons-material/PendingOutlined';
import { blue } from '@mui/material/colors';

import { AuditLog } from '../../../AuditLog';
import { useUserPartner } from '../../../../../../hooks/useCurrentPartner';
import type { ApplicationStatusAuditProps } from './applicationStatusAuditComponentMap';

const applicationStatusToIcon: Record<ApplicationStatus, React.ReactNode> = {
  Cancelled: <CancelIcon fontSize={'inherit'} color={'error'} sx={{ backgroundColor: 'white', borderRadius: '50%', border: 0 }} />,
  WithdrawalOfApprovedApplication: (
    <CancelIcon fontSize={'inherit'} color={'error'} sx={{ backgroundColor: 'white', borderRadius: '50%', border: 0 }} />
  ),
  ExpresslyWithdrawn: <CancelIcon fontSize={'inherit'} color={'error'} sx={{ backgroundColor: 'white', borderRadius: '50%', border: 0 }} />,
  ApprovedNotAccepted: (
    <CancelIcon fontSize={'inherit'} color={'error'} sx={{ backgroundColor: 'white', borderRadius: '50%', border: 0 }} />
  ),
  Closing: <CheckCircleIcon fontSize={'inherit'} color={'success'} sx={{ backgroundColor: 'white', borderRadius: '50%', border: 0 }} />,
  Closed: <CheckCircleIcon fontSize={'inherit'} color={'success'} sx={{ backgroundColor: 'white', borderRadius: '50%', border: 0 }} />,
  Draft: <PendingIcon fontSize={'inherit'} sx={{ backgroundColor: 'white', color: blue[200], borderRadius: '50%', border: 0 }} />,
  Disqualified: <PendingIcon fontSize={'inherit'} sx={{ backgroundColor: 'white', color: blue[200], borderRadius: '50%', border: 0 }} />,
  InReview: <PendingIcon fontSize={'inherit'} sx={{ backgroundColor: 'white', color: blue[200], borderRadius: '50%', border: 0 }} />,
  PendingLost: <PendingIcon fontSize={'inherit'} sx={{ backgroundColor: 'white', color: blue[200], borderRadius: '50%', border: 0 }} />,
  Deleted: null,
  OnboardingCompleted: null,
  PendingApproval: null,
  Rejected: null,
};

export const getApplicationStatusAuditLog: (status: ApplicationStatus) => FC<ApplicationStatusAuditProps> =
  (status) =>
  ({ audit, groupInfo }) => {
    const partner = useUserPartner();

    const auditText = useMemo(() => {
      if (audit.originType === 'system' || !audit.user?.firstName || !audit.user?.lastName) {
        return `Application moved to status ${partner.applicationStatusDisplayNameMapping[status]}`;
      }

      // TODO: how to get rid of this cast?
      return `Application status changed to ${partner.applicationStatusDisplayNameMapping[status]} by ${personFullName(
        audit.user as { firstName: string; middleName?: string; lastName: string },
      )}`;
    }, [audit.originType, audit.user, partner.applicationStatusDisplayNameMapping]);

    return (
      <AuditLog
        {...(groupInfo.originType === 'user'
          ? { originType: groupInfo.originType, user: groupInfo.user }
          : { originType: groupInfo.originType })}
        text={auditText}
        timestamp={audit.timestamp}
        key={audit.id}
        badge={applicationStatusToIcon[status]}
      />
    );
  };
