/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import type { OpportunityRequirementStatus } from '@lama/contracts';
import { LoadingPage, getRequirementsByCategory } from '@lama/app-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Collapse, Flex } from '@lama/design-system';
import { ApplicationContext } from '../Application/ApplicationContext';
import { useApplicationDocumentsQuery } from '../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { useOpportunityRequirementsQuery } from '../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { DocumentGatheringCard } from '../Application/OpportunityRequirements/DocumentGathering';
import { MiscellaneousDocumentsList } from '../Application/OpportunityRequirements/MiscellaneousDocumentList/MiscellaneousDocumentsList';
import { isDocumentRelatedToRequirement } from '../../shared/utils/isDocumentRelatedToRequirement';
import { DeletedDocumentsList } from '../../shared/components/DeletedDocumentsList/DeletedDocumentsList';
import { useGetPartnerQuery } from '../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { RequirementsDocumentsList } from './RequirementsDocumentsList';
import { DocumentHubEmptyState } from './DocumentHubEmptyState';
import { DemoDocumentSummary } from './DocumentSummary/DemoDocumentSummary';

const dismissedRequirementStatuses = new Set<OpportunityRequirementStatus>(['Dismissed', 'DismissedByLender']);

export const DocumentHubPage: FC = () => {
  const { application, opportunity } = useContext(ApplicationContext);
  const { data: partner } = useGetPartnerQuery(opportunity.partnerId);

  const { showDocumentGatheringCard, spreadingBackOfficeMode } = useFlags();
  const { showDocumentHubSpreadingSummary } = useMemo(() => partner?.featureConfigurations ?? {}, [partner]);
  const { data: allRequirements, isPending: loadingRequirements } = useOpportunityRequirementsQuery(opportunity.id);
  const { data: applicationDocuments, isPending: loadingDocuments } = useApplicationDocumentsQuery({
    applicationId: application.id,
    includeDeleted: true,
  });

  const relatedDocuments = useMemo(() => applicationDocuments?.filter((doc) => doc.status !== 'Deleted'), [applicationDocuments]);
  const deletedDocuments = useMemo(() => applicationDocuments?.filter((doc) => doc.status === 'Deleted'), [applicationDocuments]);

  const requirementsByCategory = useMemo(
    () =>
      allRequirements
        ? getRequirementsByCategory(allRequirements, application).map((p) => ({
            ...p,
            requirements: p.requirements.filter((r) => !dismissedRequirementStatuses.has(r.status)),
          }))
        : [],
    [allRequirements, application],
  );

  const requirementRelatedDocumentsIds = useMemo(() => {
    const requirementRelatedDocuments =
      relatedDocuments?.filter((document) =>
        allRequirements?.some((requirement) => isDocumentRelatedToRequirement(document, requirement)),
      ) ?? [];

    return new Set(requirementRelatedDocuments.map(({ id }) => id));
  }, [allRequirements, relatedDocuments]);

  const hasDocuments = useMemo(
    () => relatedDocuments?.length || allRequirements?.some((r) => r.sources.uploadFilesSource?.length),
    [allRequirements, relatedDocuments?.length],
  );

  const miscellaneousDocuments = useMemo(
    () => relatedDocuments?.filter((document) => !requirementRelatedDocumentsIds.has(document.id)),
    [relatedDocuments, requirementRelatedDocumentsIds],
  );

  const manualSpreadingDocuments = useMemo(
    () => relatedDocuments?.filter((doc) => spreadingBackOfficeMode || doc.status === 'Processed' || doc.status === 'Reviewed') ?? [],
    [relatedDocuments, spreadingBackOfficeMode],
  );

  if (loadingRequirements || loadingDocuments) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={10} px={20}>
      {showDocumentHubSpreadingSummary && relatedDocuments?.length ? (
        <Collapse expanded={Boolean(hasDocuments)}>
          <DemoDocumentSummary
            documents={relatedDocuments ?? []}
            applicationId={application.id}
            manualSpreadingDocuments={manualSpreadingDocuments}
          />
        </Collapse>
      ) : null}
      {showDocumentGatheringCard ? <DocumentGatheringCard /> : null}
      {hasDocuments ? (
        <Flex flexDirection={'column'} gap={10}>
          {requirementsByCategory.map((p) =>
            p.requirements.length ? (
              <RequirementsDocumentsList
                key={p.categoryLongName + p.entityType}
                title={p.categoryLongName}
                icon={p.categoryIcon}
                relation={p.entityRelation}
                requirements={p.requirements}
                applicationDocuments={relatedDocuments ?? []}
              />
            ) : null,
          )}
          {miscellaneousDocuments?.length ? <MiscellaneousDocumentsList miscellaneousDocuments={miscellaneousDocuments} /> : null}
          {deletedDocuments?.length ? <DeletedDocumentsList deletedDocuments={deletedDocuments} /> : null}
        </Flex>
      ) : loadingDocuments || loadingRequirements ? (
        <LoadingPage />
      ) : (
        <DocumentHubEmptyState />
      )}
    </Flex>
  );
};
