import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Switch, Flex, Text, Button } from '@lama/design-system';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfirmModal } from '@lama/app-components';
import { formatConditionDefinition, getConditionConfig } from '@lama/conditions';
import type { Condition, DecisionReason, LabeledValue, Matcher } from '@lama/contracts';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useToggle } from 'react-use';
import { useQueryClient } from '@tanstack/react-query';
import { ProductSettingsContext } from '../../../ProductSettingsContext';
import {
  defaultDecisionReasons,
  filterReasonsForStatus,
} from '../../../../../../../shared/components/DecisionDialog/defaultDecisionReasons';
import { useUpdateConditionMutation } from '../../../hooks/useUpdateConditionMutation';
import { useDeleteConditionMutation } from '../../../hooks/useDeleteConditionMutation';
import { MatcherCard } from './Matchers/MatcherCard';
import { AddMatcherMenu } from './Matchers/AddMatcherMenu';
import type { FailActionType } from './FailActionChip';
import { FailActionChip, failActionOptions } from './FailActionChip';
import { KnockoutReasonsAutocomplete } from './KnockoutReasonsAutocomplete';
import { FailActionAutocomplete } from './FailActionAutocomplete';
import type { MatcherValue } from './Matchers/types';
import { conditionV2ByTypeComponent } from './conditionComponentByTypeV2';

export const ConditionPage: FC = () => {
  const { conditionId } = useParams<{ conditionId?: string }>();
  const { product } = useContext(ProductSettingsContext);
  const condition: Condition | null = useMemo(() => product.conditions?.find((c) => c.id === conditionId) ?? null, [conditionId, product]);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutateAsync: updateCondition, isPending: updatingCondition } = useUpdateConditionMutation(product.id);

  const onDeleteCondition = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['product', product.id] });

    queryClient.setQueryData(['product', product.id], (oldData: any) => ({
      ...oldData,
      conditions: oldData.conditions.filter((existingCondition: any) => existingCondition.id !== conditionId),
    }));
  }, [conditionId, product.id, queryClient]);

  const { mutateAsync: deleteCondtion, isPending: deletingCondition } = useDeleteConditionMutation(product.id, {
    onSuccess: onDeleteCondition,
  });

  const { confirm } = useConfirmModal();

  const menuRef = useRef(null);
  const [menuOpen, toggleMenuOpen] = useToggle(false);

  const knockoutReasons = useMemo(() => {
    if (product?.decisionReasons) {
      return filterReasonsForStatus(product.decisionReasons, ['Rejected']);
    }

    return filterReasonsForStatus(defaultDecisionReasons, ['Rejected']);
  }, [product.decisionReasons]);

  const [failAction, setFailAction] = useState<LabeledValue | null>(
    failActionOptions.find((option) => option.value === condition?.failAction) ?? null,
  );

  const [reason, setReason] = useState<DecisionReason | null>(
    knockoutReasons.find((knockout) => knockout.id === condition?.reasonId) ?? null,
  );

  const [active, toggleActive] = useToggle(condition?.active ?? false);

  const [prequal, setPrequal] = useState(condition?.prequal ?? false);

  const [note, setNote] = useState(condition?.note ?? '');

  const [enabledMatcher, setEnabledMatcher] = useState<Matcher | null>(condition?.enabledMatcher ?? null);

  const [conditionValues, setConditionValues] = useState<MatcherValue | null>(null);

  const config = useMemo(() => (condition ? getConditionConfig(condition) : null), [condition]);

  const onAddMatcher = useCallback(
    async (matcher: Matcher) => {
      if (!condition) {
        return;
      }

      setEnabledMatcher(matcher);
    },
    [condition],
  );

  const onPrequalChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPrequal(event.target.checked);
  }, []);

  const onSubmit = useCallback(async () => {
    if (!condition) {
      return;
    }

    await updateCondition({
      conditionId: condition.id,
      conditionPayload: {
        ...condition,
        active,
        failAction: failAction?.value,
        reasonId: reason?.id,
        prequal,
        note,
        enabledMatcher,
        ...conditionValues,
      },
    });

    navigate('../');
  }, [active, condition, conditionValues, enabledMatcher, failAction?.value, navigate, note, prequal, reason?.id, updateCondition]);

  const removeMatcher = useCallback(() => {
    if (!condition) {
      return;
    }

    setEnabledMatcher(null);
  }, [condition]);

  const onNoteChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setNote(event.target.value);
  }, []);

  const onDelete = useCallback(async () => {
    if (!condition) {
      return;
    }

    const confirmed = await confirm({
      title: 'Delete Rule',
      message: 'Are you sure you want to delete this rule?',
      cancelText: 'Cancel',
      confirmText: 'Delete',
    });

    if (confirmed) {
      await deleteCondtion({ conditionId: condition.id });
      navigate('../');
    }
  }, [condition, confirm, deleteCondtion, navigate]);

  const onCancel = useCallback(() => {
    navigate('../');
  }, [navigate]);

  const ConditionComponent = useMemo(() => {
    if (!condition || !config) {
      return null;
    }

    return conditionV2ByTypeComponent(config, setConditionValues, condition);
  }, [condition, config, setConditionValues]);

  if (!condition) {
    return <Flex>{'Rule not found'}</Flex>;
  }

  return (
    <>
      <Flex flexDirection={'column'} gap={enabledMatcher ? 8 : 2}>
        {enabledMatcher ? (
          <MatcherCard matcher={enabledMatcher} onRemove={removeMatcher} setMatcher={setEnabledMatcher} />
        ) : (
          <Flex>
            <Button variant={'tertiary'} color={'neutral'} size={'m'} startIcon={<Add />} onClick={toggleMenuOpen} ref={menuRef}>
              {'Add Condition'}
            </Button>
          </Flex>
        )}
        <Flex flexDirection={'column'} border={'1px solid #E0E0E0'} borderRadius={'8px'}>
          <Flex
            flexDirection={'row'}
            px={6}
            py={5}
            justifyContent={'space-between'}
            backgroundColor={'grey.50'}
            borderRadius={'8px 8px 0px 0px'}
          >
            <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
              <Flex gap={2} alignItems={'center'}>
                <Text variant={'body2'}>{condition.name}</Text>
                <Text variant={'body2'} color={'grey.300'}>
                  {'•'}
                </Text>
                <Text variant={'body2'} color={'secondary'}>
                  {formatConditionDefinition(condition, config?.format)}
                </Text>
              </Flex>
              {failAction ? <FailActionChip status={failAction.value as FailActionType} size={'medium'} /> : null}
            </Flex>
            <Switch checked={active} onChange={toggleActive} size={'m'} />
          </Flex>
          <Flex flexDirection={'column'} gap={4} p={6}>
            {ConditionComponent ?? null}
            <Flex flexDirection={'row'} gap={6}>
              <FailActionAutocomplete failAction={failAction} setFailAction={setFailAction} />
              <KnockoutReasonsAutocomplete
                reason={reason}
                setReason={setReason}
                reasons={knockoutReasons}
                disabled={!(failAction?.value === 'knockout')}
              />
            </Flex>
            <Flex flexDirection={'row'} gap={6}>
              <FormControlLabel
                name={'prequal'}
                label={<Text>{'Required for Pre-Qualification'}</Text>}
                control={<Checkbox checked={!!prequal} onChange={onPrequalChange} />}
              />
            </Flex>
            <TextField value={note} onChange={onNoteChange} placeholder={'Add a note...'} />
            <Flex flexDirection={'row'} justifyContent={'space-between'}>
              <Button variant={'tertiary'} color={'danger'} size={'l'} type={'button'} onClick={onDelete} loading={deletingCondition}>
                {'Delete Rule'}
              </Button>
              <Flex flexDirection={'row'} gap={4}>
                <Button variant={'tertiary'} color={'neutral'} size={'l'} type={'button'} onClick={onCancel}>
                  {'Cancel'}
                </Button>
                <Button variant={'primary'} color={'primary'} size={'l'} type={'submit'} onClick={onSubmit} loading={updatingCondition}>
                  {'Save'}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <AddMatcherMenu open={menuOpen} menuRef={menuRef} onClose={toggleMenuOpen} addMatcher={onAddMatcher} />
    </>
  );
};
