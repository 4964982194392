import React, { useMemo, useCallback, useContext } from 'react';
import type { FC } from 'react';
import { Alert, Divider } from '@mui/material';
import type { EvaluatedOpportunityRequirement, RequirementAlert } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import Markdown from 'react-markdown';
import { Indication } from '@lama/app-components';
import { CommentsSegment } from '../../../../shared/components/Comments/CommentsSegment';

import { ApplicationContext } from '../../ApplicationContext';
import { requirementScreenToComponent } from './requirementScreenToComponent';
import { RequirementDismissedAlert } from './RequirementDismissedReason';
import { RequirementHeaderRow } from './RequirementHeaderRow';

const getAlertMessage = (alert: RequirementAlert) =>
  alert.type === 'document' ? `There was an issue processing document "${alert?.documentName}": ${alert.message}` : alert.message;

interface RequirementContentProps {
  requirement: EvaluatedOpportunityRequirement;
  failingDisqualifyingConditionMessages?: string[];
}

export const RequirementContent: FC<RequirementContentProps> = ({ requirement, failingDisqualifyingConditionMessages }) => {
  const { application } = useContext(ApplicationContext);
  const { id: requirementId, screen, alerts } = requirement;

  const RequirementComponent = useMemo(() => (screen ? requirementScreenToComponent[screen] : null), [screen]);

  const RenderedComponent = useCallback(
    () => (RequirementComponent ? <RequirementComponent requirement={requirement} /> : null),
    [RequirementComponent, requirement],
  );

  const isDismissedByBorrower = useMemo(() => requirement.status === 'Dismissed', [requirement.status]);

  return (
    <Flex flexDirection={'column'} p={10} flex={1} overflow={'auto'} alignItems={'center'}>
      <Flex flexDirection={'column'} gap={4} maxWidth={'960px'} width={'100%'}>
        <RequirementHeaderRow requirement={requirement} />
        <Flex flexDirection={'column'} gap={8} width={'100%'}>
          {failingDisqualifyingConditionMessages?.length ? (
            <Flex flexDirection={'column'} gap={4}>
              {failingDisqualifyingConditionMessages.map((message) => (
                <Alert key={message} severity={'warning'}>
                  <Markdown>{message}</Markdown>
                </Alert>
              ))}
            </Flex>
          ) : null}
          {isDismissedByBorrower ? <RequirementDismissedAlert requirement={requirement} /> : null}
          {alerts?.length ? (
            <Flex flexDirection={'column'} gap={4}>
              {alerts?.map((alert) => (
                <Alert key={alert.message} severity={'warning'}>
                  {getAlertMessage(alert)}
                </Alert>
              ))}
              <Divider />
            </Flex>
          ) : null}
        </Flex>
        <Indication screenName={screen} application={application} />
        <RenderedComponent />
        <Flex flexDirection={'column'} pt={6}>
          <CommentsSegment relatedItemId={requirementId} relatedItemType={'requirement'} />
        </Flex>
      </Flex>
    </Flex>
  );
};
