import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Text } from '@lama/design-system';
import { businessDebtSelector } from '@lama/selectors';
import type { BusinessApiModel } from '@lama/business-service-client';
import { DebtScheduleTableSegment } from '../../../shared/Debt/DebtScheduleTableSegment';

export const DebtScheduleSegment: FC<{ business: BusinessApiModel }> = ({ business }) => {
  const businessDebt = useMemo(() => businessDebtSelector(business), [business]);

  if (!businessDebt) {
    return <Text variant={'body1'}>{'No debt found'}</Text>;
  }

  if (!businessDebt.length) {
    return <Text variant={'body1'}>{'Applicant marked no debt'}</Text>;
  }

  return <DebtScheduleTableSegment debt={businessDebt} />;
};
