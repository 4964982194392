import type { DocumentPage } from '@lama/document-service-client';
import { sortBy } from 'lodash-es';
import { useContext } from 'react';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';

export const useGroupedDocumentPagesByFormInstance = () => {
  const { currentDocument } = useContext(SpreadingDocumentContext);

  return sortBy(currentDocument.documentPages ?? [], ({ page }) => page)
    .reduce<DocumentPage[][]>((documentPagesByForm, currentDocumentPage) => {
      const lastGroup = documentPagesByForm.at(-1);
      const lastDocumentPage = lastGroup?.at(-1);

      if (
        lastGroup &&
        lastDocumentPage &&
        lastDocumentPage.formType === currentDocumentPage.formType &&
        lastDocumentPage.formPage + 1 === currentDocumentPage.formPage
      ) {
        lastGroup.push(currentDocumentPage);
      } else {
        documentPagesByForm.push([currentDocumentPage]);
      }
      return documentPagesByForm;
    }, [])
    .map((pages) => ({
      startDocumentPage: pages.at(0)!,
      endDocumentPage: pages.at(-1)!,
    }));
};
