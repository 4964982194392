import React, { useMemo } from 'react';
import type { OpportunityRequirement } from '@lama/contracts';
import type { FC } from 'react';
import type { CreateAuditLogProps } from '../types';
import { AuditLog } from '../../AuditLog';

export const CreateOpportunityRequirementAuditLog: FC<CreateAuditLogProps> = ({ audit, groupInfo }) => {
  const createdRequirements = useMemo(
    () =>
      audit
        .map((entry) => {
          const opportunityRequirement = entry.entity as OpportunityRequirement;

          return opportunityRequirement.name;
        })
        .join(', '),
    [audit],
  );

  return (
    <AuditLog
      {...(groupInfo.originType === 'user'
        ? { originType: groupInfo.originType, user: groupInfo.user }
        : { originType: groupInfo.originType })}
      text={`Added requirement${createdRequirements.length ? 's' : ''} ${createdRequirements}`}
      timestamp={groupInfo.timestamp}
    />
  );
};
