import { Tooltip } from '@lama/app-components';
import type { User } from '@lama/user-service-client';
import { formatValue , personFullName } from '@lama/data-formatters';
import { greyPalette, Flex, Text, Avatar } from '@lama/design-system';
import type { FC, ReactNode } from 'react';
import React, { useMemo } from 'react';

interface CommentTitleProps {
  user?: User | null;
  createdAt: string;
  titleSuffix?: ReactNode;
}

export const CommentTitle: FC<CommentTitleProps> = ({ user, createdAt, titleSuffix: TitleSuffix }) => {
  const userName = useMemo(() => {
    if (!user?.firstName) {
      return 'Unknown User';
    }

    let name = user.firstName;
    if (user.lastName) {
      name = `${name} ${user.lastName[0]?.toLocaleUpperCase() ?? ''}.`;
    }

    return name;
  }, [user]);

  const commentDate = useMemo(() => formatValue(createdAt, 'date'), [createdAt]);

  const absoluteDateTime = useMemo(() => formatValue(createdAt, 'datetime'), [createdAt]);

  return (
    <Flex gap={2} flexDirection={'row'} alignItems={'center'}>
      {user ? <Avatar size={'24px'} name={personFullName(user)} /> : null}
      <Text variant={'body2'} color={'primary'}>
        {userName}
      </Text>
      <Text variant={'body2'} color={greyPalette[500]}>
        {'•'}
      </Text>
      <Tooltip title={absoluteDateTime}>
        <Text variant={'body2'} color={greyPalette[500]}>
          {commentDate}
        </Text>
      </Tooltip>
      {TitleSuffix ? (
        <>
          <Text variant={'body2'} color={greyPalette[500]}>
            {'•'}
          </Text>
          {TitleSuffix}
        </>
      ) : null}
    </Flex>
  );
};
