import { Divider } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useState, useContext, useMemo } from 'react';
import { Flex, greyPalette } from '@lama/design-system';
import type { UnderwritingGroup } from '@lama/contracts';
import { ApplicationContext } from '../../ApplicationContext';
import { SideNavContainer } from '../../../../shared/components/PageSideNav/SideNavContainer';
import { SideNavFilterButton } from '../../../../shared/components/PageSideNav/SideNavFilterButton';
import { UnderwritingSideNavCategory } from './UnderwritingSideNavCategory';

interface UnderwritingSideNavProps {
  underwritingCategories: UnderwritingGroup[];
}

export const UnderwritingSideNav: FC<UnderwritingSideNavProps> = ({ underwritingCategories }) => {
  const {
    opportunity: { underwriting },
  } = useContext(ApplicationContext);
  const [filter, setFilter] = useState('all');

  const underwritingSections = useMemo(() => underwritingCategories.flatMap(({ sections }) => sections) ?? [], [underwritingCategories]);

  const reviewedSections = useMemo(
    () =>
      underwritingSections.filter(
        (s) => !!underwriting?.notes?.find((n) => n.relatedUnderwritingSectionId === s.id && n.type === 'reviewed'),
      ),
    [underwriting?.notes, underwritingSections],
  );

  const pendingReviewSection = useMemo(
    () => underwritingSections.filter((s) => !reviewedSections.includes(s)),
    [underwritingSections, reviewedSections],
  );

  const filteredUnderwritingConfiguration = useMemo(
    () =>
      underwritingCategories.map((group) => ({
        ...group,
        sections: group.sections.filter((s) =>
          filter === 'pendingReview' ? pendingReviewSection.includes(s) : filter === 'reviewed' ? reviewedSections.includes(s) : true,
        ),
      })),
    [filter, pendingReviewSection, reviewedSections, underwritingCategories],
  );

  const onFilterClick = useCallback(
    (filterValue: string) => {
      setFilter(filterValue);
    },
    [setFilter],
  );

  return (
    <SideNavContainer>
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        gap={2}
        px={2}
        py={3}
        borderBottom={`1px solid ${greyPalette[200]}`}
      >
        <SideNavFilterButton
          text={'All'}
          count={underwritingSections.length}
          isSelected={filter === 'all'}
          filterValue={'all'}
          onClick={onFilterClick}
        />
        <SideNavFilterButton
          text={'Pending'}
          count={pendingReviewSection.length}
          isSelected={filter === 'pendingReview'}
          filterValue={'pendingReview'}
          onClick={onFilterClick}
        />
        <SideNavFilterButton
          text={'Reviewed'}
          count={reviewedSections.length}
          isSelected={filter === 'reviewed'}
          filterValue={'reviewed'}
          onClick={onFilterClick}
        />
      </Flex>
      {filteredUnderwritingConfiguration
        ?.filter((group) => group.sections.length)
        .map((group) => (
          <Flex flexDirection={'column'} key={group.groupName}>
            <UnderwritingSideNavCategory group={group} />
            <Divider />
          </Flex>
        ))}
    </SideNavContainer>
  );
};
