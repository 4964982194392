import React, { useContext } from 'react';
import type { Document } from '@lama/document-service-client';
import { Divider } from '@mui/material';
import type { FC } from 'react';
import { Modal } from '@lama/design-system';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { SpreadingDocumentProvider } from '../SpreadingDocumentContext';
import { SpreadingDialogContent } from './SpreadingContent';
import { SpreadingToolbar } from './SpreadingToolbar/SpreadingToolbar';

interface DocumentFinancialDataDialogProps {
  toggleDialog: () => void;
  open: boolean;
  title: string;
  requirementDocuments: Document[];
  applicationId: string;
}

export const SpreadingDialog: FC<DocumentFinancialDataDialogProps> = ({ open, toggleDialog, requirementDocuments }) => {
  const {
    application: { id: applicationId },
    opportunity,
  } = useContext(ApplicationContext);

  return (
    <Modal open={open} onClose={toggleDialog} p={0} fullscreen>
      {open && requirementDocuments.length ? (
        <SpreadingDocumentProvider documents={requirementDocuments} applicationId={applicationId}>
          <SpreadingToolbar
            documents={requirementDocuments}
            onClose={toggleDialog}
            applicationId={applicationId}
            opportunity={opportunity}
          />
          <Divider />
          <SpreadingDialogContent />
        </SpreadingDocumentProvider>
      ) : null}
    </Modal>
  );
};
