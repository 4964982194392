import { compact, groupBy } from 'lodash-es';
import type { OpportunityClosingTask, OpportunityRequirement } from '@lama/contracts';
import { useMemo } from 'react';
import type { DocumentDetails, DocumentGroup } from '../types';

export const useDocumentGroupsByTopic = (
  documents: DocumentDetails[],
  allRequirements: OpportunityRequirement[],
  closingTasks: OpportunityClosingTask[],
) =>
  useMemo(() => {
    const documentsWithRequirement = documents.filter((d) => d.requirement?.id);
    const documentsByRequirementId = groupBy(documentsWithRequirement, (d) => d.requirement?.id);

    const requirementGroups: DocumentGroup[] = Object.entries(documentsByRequirementId)
      .filter(([requirementId]) => requirementId)
      .map(([referenceRequirement, docs]) => {
        const relevantRequirements = allRequirements.filter((r) => r.id === referenceRequirement);

        if (!relevantRequirements.length) {
          return {
            title: 'Unknown Requirement',
            documents: docs,
          };
        }

        return {
          title: relevantRequirements.at(0)?.name,
          documents: docs,
          requirements: relevantRequirements,
        } as DocumentGroup;
      });

    const closingTaskGroups: DocumentGroup[] = closingTasks
      .map((closingTask) => {
        const closingTaskDocuments = documents.filter((doc) => closingTask?.documentIds?.includes(doc.id));

        return {
          title: closingTask.name,
          documents: closingTaskDocuments,
          closingTasks: [closingTask],
        } as DocumentGroup;
      })
      .filter((group) => group?.documents?.length);

    return {
      groups: compact([...requirementGroups, ...closingTaskGroups]),
    };
  }, [documents, closingTasks, allRequirements]);
