import type { DocumentLineItem } from '@lama/document-service-client';
import type { FilterOptionsState } from '@mui/material';
import { TextField, Autocomplete } from '@mui/material';
import type { MRT_TableInstance } from 'material-react-table';
import React, { useCallback } from 'react';
import { FitToContentPopper } from '../../../../../shared/components/FitToContentPopper';

interface FinancialSpreadingCellPickerProps {
  onValueChanged: (value: string | null) => void;
  options: { label: string; value: string }[];
  table: MRT_TableInstance<DocumentLineItem>;
  label: string;
  value?: { label: string; value: string };
}
const getOptionLabel = (option: { label: string; value: string }) => option?.label;

const filterDefaultValueOption = (
  options: { label: string; value: string }[],
  state: FilterOptionsState<{ label: string; value: string }>,
) => options.filter((option) => option.label !== '' && option.label.toLowerCase().startsWith(state.inputValue.toLowerCase()));

export const FinancialSpreadingCellPicker: React.FC<FinancialSpreadingCellPickerProps> = ({
  onValueChanged,
  options,
  table,
  label,
  value,
}) => {
  const onBlur = useCallback(() => {
    table.setEditingCell(null);
  }, [table]);

  const onChange = useCallback(
    (_: any, selectedOption: { label: string; value: string } | null) => {
      onValueChanged(selectedOption?.value ?? null);
      table.setEditingCell(null);
    },
    [onValueChanged, table],
  );

  const renderInput = useCallback((params: any) => <TextField variant={'standard'} {...params} label={label} />, [label]);

  return (
    <Autocomplete
      PopperComponent={FitToContentPopper}
      filterOptions={filterDefaultValueOption}
      options={options}
      value={value}
      onChange={onChange}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      onBlur={onBlur}
    />
  );
};
