import React, { useMemo } from 'react';
import { summarizedAccountTypes, type Tradeline } from '@lama/credit-report-retriever-client';
import { formatValue } from '@lama/data-formatters';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { Chip } from '@mui/material';
import type { FC } from 'react';
import { isNil, sum } from 'lodash-es';
import styled from 'styled-components';
import { ErrorOutline } from '@mui/icons-material';
import ExpandButton from '../../../../../../shared/components/ExpandButton';

interface TradelineCardHeaderProps {
  tradeline: Tradeline;
  onExpandClick: () => void;
  isCardOpen: boolean;
  highUtilization?: boolean;
  redFlag: boolean;
}

const TradelineHeaderContainer = styled(Flex)<{ dismissed: boolean }>(
  ({ dismissed }) => `
      background-color: ${dismissed ? greyPalette[200] : 'transparent'};
      color: ${dismissed ? greyPalette[500] : 'inherit'};
    `,
);

const DismissedTradelineContent: FC<{ dismissReason: string }> = ({ dismissReason }) => (
  <Text variant={'body2'} weight={500} textAlign={'center'}>
    {dismissReason}
  </Text>
);

const ActiveTradelineContent: FC<{ numberOfDelinquencies?: number; isRevolving?: boolean; ecoa?: string }> = ({ isRevolving, ecoa }) => (
  <Text variant={'body2'} weight={500} textAlign={'center'} color={greyPalette[500]}>
    {`${isRevolving ? 'Revolving' : 'Installments'} ${String.fromCodePoint(8226)} ${ecoa}`}
  </Text>
);

export const TradelineCardHeader: FC<TradelineCardHeaderProps> = ({
  tradeline: {
    dismissed,
    dismissedReason,
    accountType,
    balanceAmount,
    delinquencies30Days,
    delinquencies60Days,
    delinquencies90to180Days,
    revolving,
    ecoa,
    open,
  },
  isCardOpen,
  onExpandClick,
  highUtilization,
  redFlag,
}) => {
  const numberOfDelinquencies = useMemo(
    () => sum([delinquencies30Days ?? 0, delinquencies60Days ?? 0, delinquencies90to180Days ?? 0]),
    [delinquencies30Days, delinquencies60Days, delinquencies90to180Days],
  );

  const accountTypeCategory = useMemo(
    () => accountType && Object.entries(summarizedAccountTypes).find(([, types]) => types.includes(accountType))?.[0],
    [accountType],
  );

  return (
    <TradelineHeaderContainer justifyContent={'space-between'} dismissed={!!dismissed} px={6} py={2} onClick={onExpandClick}>
      <Flex gap={1} alignItems={'center'}>
        <Text variant={'body1'}>{accountTypeCategory}</Text>
        {!isNil(balanceAmount) ? (
          <>
            <Text variant={'body1'} color={greyPalette[500]}>
              {'|'}
            </Text>
            <Text variant={'body1'}>{formatValue(balanceAmount, 'currency')}</Text>
          </>
        ) : null}
      </Flex>
      <Flex alignItems={'center'} gap={2}>
        {dismissed ? (
          <DismissedTradelineContent dismissReason={dismissedReason ?? ''} />
        ) : (
          <ActiveTradelineContent numberOfDelinquencies={numberOfDelinquencies} isRevolving={revolving} ecoa={ecoa} />
        )}
        {redFlag ? <ErrorOutline fontSize={'small'} color={'error'} /> : null}
        {highUtilization ? (
          <Chip
            label={'High Utilization'}
            size={'small'}
            variant={'outlined'}
            sx={{ bgcolor: '#FFF8E1', color: '#FFA000', borderColor: '#FFF8E1' }}
          />
        ) : null}
        <Chip
          label={dismissed ? 'Dismissed' : open ? 'Open' : 'Closed'}
          size={'small'}
          variant={'outlined'}
          sx={{ bgcolor: greyPalette[100], color: greyPalette[500], borderColor: greyPalette[100] }}
        />
        <ExpandButton expand={isCardOpen} />
      </Flex>
    </TradelineHeaderContainer>
  );
};
