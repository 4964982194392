import React, { useCallback, useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Button, Flex, Text } from '@lama/design-system';
import { DocumentBox, LoadingPage } from '@lama/app-components';
import { useToggle } from 'react-use';
import type { Partner } from '@lama/partner-service-client';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useApplicationDocumentsQuery } from '../../../../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { ApplicationContext } from '../../../ApplicationContext';
import { useDeleteDocumentQuery } from '../../../../../shared/components/DocumentBox/hooks/useDeleteDocumentQuery';
import { useSubmitApplicationMutation } from '../../../../../shared/hooks/react-query/application/useSubmitApplicationMutation';
import { useGetPartnerQuery } from '../../../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import type { RequirementScreenProps } from './types';

const SIGNED_DOCUMENT_TOPIC = 'signedDocument';

const Disclaimer: FC<{ disclaimer: Required<Partner>['submitDisclaimer']; confirmed: boolean; onToggle: () => void }> = ({
  disclaimer,
  confirmed,
  onToggle,
}) => (
  <Flex flexDirection={'column'} gap={2}>
    <Text variant={'body2'}>{disclaimer.text}</Text>
    <FormControlLabel
      label={<Text variant={'body2'}>{disclaimer.confirmationText}</Text>}
      control={<Checkbox checked={!!confirmed} onChange={onToggle} />}
    />
  </Flex>
);

export const LenderSubmitScreen: FC<RequirementScreenProps> = () => {
  const {
    application: { id: applicationId },
    opportunity: { id: opportunityId, partnerId },
  } = useContext(ApplicationContext);

  const { data: documents, isPending: isLoadingDocuments } = useApplicationDocumentsQuery({ applicationId });
  const { mutateAsync: deleteDocument } = useDeleteDocumentQuery();
  const { mutate: signApplication, isPending: isSigningApplication } = useSubmitApplicationMutation(applicationId, opportunityId);
  const { data: partner, isPending: loadingPartner } = useGetPartnerQuery(partnerId);
  const signedDocument = useMemo(() => documents?.find((document) => document.topic === SIGNED_DOCUMENT_TOPIC), [documents]);
  const [confirmed, toggleConfirmed] = useToggle(false);

  const onDeleteDocument = useCallback(async () => {
    if (signedDocument) {
      await deleteDocument({ documentId: signedDocument?.id });
    }
  }, [deleteDocument, signedDocument]);

  const onSubmitClick = useCallback(async () => {
    signApplication();
  }, [signApplication]);

  const submitDisclainer = useMemo(() => partner?.submitDisclaimer, [partner]);

  const submitButtonDisabled = useMemo(() => submitDisclainer && !confirmed, [submitDisclainer, confirmed]);

  if (isLoadingDocuments || loadingPartner) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} justifyContent={'space-between'} gap={10}>
      <Flex flexDirection={'column'} gap={10}>
        <DocumentBox description={'Signed Application Form'} document={signedDocument} onDocumentRemoved={onDeleteDocument} />
        {submitDisclainer ? <Disclaimer disclaimer={submitDisclainer} confirmed={confirmed} onToggle={toggleConfirmed} /> : null}
      </Flex>
      <Flex width={'100%'} justifyContent={'flex-start'}>
        <Button onClick={onSubmitClick} loading={isSigningApplication} disabled={submitButtonDisabled}>
          {'Submit'}
        </Button>
      </Flex>
    </Flex>
  );
};
