import { Text, Flex, Grid } from '@lama/design-system';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import type { RelatedPersonApiModel } from '@lama/business-service-client';
import { minBy } from 'lodash-es';
import { yearsDiffFromReferenceYearSelector, personalCreditUtilizationRateSelector } from '@lama/selectors';
import { KeyValuePair } from '@lama/app-components';
import { BorderedContainer } from '../../../../shared/BorderedContainer';
import { ApplicationContext } from '../../../../ApplicationContext';
import { getTradelinesSummary } from './utils';
import { TradelinesSummaryTable } from './TradelinesSummaryTable';

interface TradeSummaryProps {
  guarantor: RelatedPersonApiModel;
}

const TradeSummaryContainer = styled(Flex)`
  page-break-inside: avoid;
`;

export const TradeSummary: FC<TradeSummaryProps> = ({ guarantor }) => {
  const {
    opportunity: { referenceYear },
  } = useContext(ApplicationContext);

  const totalSummary = useMemo(() => getTradelinesSummary(guarantor.tradelines?.filter((t) => !t.dismissed) ?? []), [guarantor.tradelines]);

  const revolvingCreditUtilization = useMemo(() => {
    const yearsBack = yearsDiffFromReferenceYearSelector(referenceYear);
    return personalCreditUtilizationRateSelector(guarantor, yearsBack);
  }, [guarantor, referenceYear]);

  const revolvingAvailableCredit = useMemo(
    () => (revolvingCreditUtilization ? 100 - revolvingCreditUtilization : null),
    [revolvingCreditUtilization],
  );

  const oldestTradelineDate = useMemo(() => minBy(guarantor.tradelines ?? [], (t) => t.openDate)?.openDate, [guarantor.tradelines]);

  const totalDebtToHighCreditRatio = useMemo(() => {
    if (!totalSummary.highCredit) {
      return null;
    }

    return totalSummary.balance / totalSummary.highCredit;
  }, [totalSummary.balance, totalSummary.highCredit]);

  return (
    <TradeSummaryContainer flexDirection={'column'} gap={4}>
      <Text variant={'body1'}>{'Trade Summary'}</Text>
      <TradelinesSummaryTable guarantor={guarantor} />
      <BorderedContainer>
        <Grid columns={3}>
          <KeyValuePair name={'Total Tradelines'} value={totalSummary.count} type={'number'} />
          <KeyValuePair name={'Total Balance'} value={totalSummary.balance} type={'currency'} />
          <KeyValuePair name={'Total Payments'} value={totalSummary.payments} type={'currency'} />
          <KeyValuePair name={'Total High Credit'} value={totalSummary.highCredit} type={'currency'} />
          <KeyValuePair name={'Past Due'} value={totalSummary.amountPastDue} type={'currency'} />
          <KeyValuePair name={'Oldest Tradeline'} value={oldestTradelineDate} type={'date'} />
          <KeyValuePair name={'Revolving Credit Utilization'} value={revolvingCreditUtilization} type={'percent'} />
          <KeyValuePair name={'Available Revolving Credit'} value={revolvingAvailableCredit} type={'percent'} />
          <KeyValuePair name={'Total Debt / High Credit'} value={totalDebtToHighCreditRatio} type={'decimal'} />
        </Grid>
      </BorderedContainer>
    </TradeSummaryContainer>
  );
};
