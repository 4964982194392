/* eslint-disable @typescript-eslint/naming-convention */
import { Button } from '@lama/design-system';
import { useTheme, Chip } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { FC } from 'react';
import React, { useCallback } from 'react';

const CountChip: FC<{ count: number }> = ({ count }) => {
  const theme = useTheme();

  return (
    <Chip
      label={count}
      size={'small'}
      sx={{
        '& .MuiChip-label': {
          padding: 0,
        },
        height: '18px',
        width: '18px',
        cursor: 'inherit',
        backgroundColor: grey[300],
        color: theme.palette.text.secondary,
        fontSize: '10px !important',
      }}
    />
  );
};

export const SideNavFilterButton: FC<{
  text: string;
  count: number;
  isSelected: boolean;
  filterValue: string;
  onClick: (filterValue: string) => void;
}> = ({ text, count, isSelected, filterValue, onClick }) => {
  const onButtonClick = useCallback(() => {
    onClick(filterValue);
  }, [onClick, filterValue]);

  return (
    <Button
      variant={'tertiary'}
      color={'neutral'}
      onClick={onButtonClick}
      endIcon={<CountChip count={count} />}
      disabled={count === 0}
      selected={isSelected}
    >
      {text}
    </Button>
  );
};
