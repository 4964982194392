import React, { useContext, useMemo, useState } from 'react';
import { LoadingPage, useConfirmModal } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import { useAsyncFn } from 'react-use';
import { LoadingButton } from '@mui/lab';
import { ApplicationContext } from '../ApplicationContext';
import { useOpportunityClosingTasksQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityClosingTasksQuery';
import { useUpdateStatusMutation } from '../ActionsMenu/hooks/useUpdateStatusMutation';
import { ClosingFilters } from './ClosingTasks/ClosingFilters';
import type { Filter } from './ClosingTasks/FilterButton';
import { ClosingTasks } from './ClosingTasks/ClosingTasks';

export const ClosingPage: React.FC = () => {
  const { opportunity, application } = useContext(ApplicationContext);

  const [filter, setFilter] = useState<Filter>('all');

  const { confirm } = useConfirmModal();
  const { data: closingTasks, isPending: loadingClosingTasks } = useOpportunityClosingTasksQuery(opportunity.id);

  const { mutate: updateStatus, isPending: updatingStatus } = useUpdateStatusMutation(application.id, opportunity.id);

  const [, onSetClosedClick] = useAsyncFn(async () => {
    if (closingTasks?.some((t) => t.status !== 'Complete')) {
      const confirmed = await confirm({
        title: 'Are you sure you want to mark this application as closed?',
        message: 'Some tasks are not complete.',
      });

      if (!confirmed) {
        return;
      }
    }

    updateStatus({
      updateApplicationPayload: {
        status: 'Closed',
      },
    });
  }, [closingTasks, confirm, updateStatus]);

  const filteredClosingTasks = useMemo(() => {
    if (!closingTasks) {
      return [];
    }

    if (filter === 'all') {
      return closingTasks;
    }

    if (filter === 'pending') {
      return closingTasks.filter((task) => task.status === 'Pending');
    }

    if (filter === 'complete') {
      return closingTasks.filter((task) => task.status === 'Complete');
    }

    return [];
  }, [closingTasks, filter]);

  if (loadingClosingTasks) {
    return <LoadingPage />;
  }

  if (!closingTasks) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} flex={1} gap={8} px={20}>
      <ClosingFilters closingTasks={closingTasks} filter={filter} setFilter={setFilter} />
      <ClosingTasks closingTasks={filteredClosingTasks} />
      <Flex flexDirection={'column'} width={'100%'} alignItems={'flex-start'}>
        <LoadingButton
          onClick={onSetClosedClick}
          loading={updatingStatus}
          disabled={application.status === 'Closed'}
          variant={'contained'}
          sx={{ borderRadius: '4px', fontSize: '16px' }}
        >
          {'Mark Application as Closed'}
        </LoadingButton>
      </Flex>
    </Flex>
  );
};
