/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { SourceStatus } from '@lama/contracts';
import type { CreateAudit } from '@lama/audit-service-client';
import { AuditLog } from '../../AuditLog';
import type { EntityAuditLogProps } from '../../GroupedAudit';

const allowedFetchStatuses = new Set<SourceStatus>(['Fetched', 'Not Found']);

const CreditReportFetchResultAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  const AuditLogs = useMemo(
    () =>
      (audit as CreateAudit[]).map((a) => {
        if (!a.entity.creditReportRequest) {
          return null;
        }

        const {
          fetchStatus,
          creditReportRequest: { firstName, lastName },
        } = a.entity;

        if (!firstName || !lastName || !fetchStatus || !allowedFetchStatuses.has(fetchStatus as SourceStatus)) {
          return null;
        }
        const fullName = `${firstName} ${lastName}`;
        const text = fetchStatus === 'Fetched' ? `Pulled credit report for ${fullName}` : `No credit report found for ${fullName}`;

        return (
          <AuditLog
            key={a.id}
            {...(groupInfo.originType === 'user'
              ? { originType: groupInfo.originType, user: groupInfo.user }
              : { originType: groupInfo.originType })}
            text={text}
            timestamp={a.timestamp}
          />
        );
      }),
    [audit, groupInfo.originType, groupInfo.user],
  );

  return <>{AuditLogs}</>;
};

export const CreditReportAuditLog: FC<EntityAuditLogProps> = ({ audit, groupInfo }) => {
  if (groupInfo.type !== 'create') {
    return null;
  }

  return <CreditReportFetchResultAuditLog audit={audit} groupInfo={groupInfo} />;
};
