import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { applicationBorrowingRelatedBusinessesSelector, relatedCompaniesSelector, formatValue } from '@lama/data-formatters';
import { tangibleNetWorth, twoYearAverageNetOperatingIncome } from '@lama/selectors';
import { isNil, sum } from 'lodash-es';
import { Grid } from '@lama/design-system';
import { KeyValuePair } from '@lama/app-components';
import { ApplicationContext } from '../../ApplicationContext';
import { SegmentContainer } from '../SegmentContainer';

export const RelatedCompaniesSummarySegment: FC = () => {
  const {
    opportunity: { referenceYear },
    application,
  } = useContext(ApplicationContext);

  const yearsBackFromCurrentYear = useMemo(() => new Date().getUTCFullYear() - referenceYear, [referenceYear]);
  const relatedCompanies = useMemo(() => {
    const borrowers = applicationBorrowingRelatedBusinessesSelector(application);

    return borrowers.flatMap(({ business: borrower }) => relatedCompaniesSelector(application, borrower).map(({ business }) => business));
  }, [application]);

  const formattedAverageNetOperatingIncome = useMemo(() => {
    if (relatedCompanies.length === 0) {
      return 0;
    }

    const twoYearNetOperatingIncomeAverages = relatedCompanies?.map((company) =>
      twoYearAverageNetOperatingIncome(company, yearsBackFromCurrentYear),
    );

    if (twoYearNetOperatingIncomeAverages?.some((average) => isNil(average))) {
      return '-';
    }

    return formatValue(sum(twoYearNetOperatingIncomeAverages), 'currency');
  }, [relatedCompanies, yearsBackFromCurrentYear]);

  const formattedTangibleNetWorthSum = useMemo(() => {
    if (relatedCompanies.length === 0) {
      return formatValue(0, 'currency');
    }

    const tangibleNetWorths = relatedCompanies.map((company) => tangibleNetWorth(company, yearsBackFromCurrentYear));

    if (tangibleNetWorths?.some((t) => isNil(t))) {
      return '-';
    }

    return formatValue(sum(tangibleNetWorths), 'currency');
  }, [relatedCompanies, yearsBackFromCurrentYear]);

  return (
    <SegmentContainer title={'Affiliates Summary'}>
      <Grid columns={3}>
        <KeyValuePair name={'2-Year Avg. Net Income'} value={formattedAverageNetOperatingIncome} />
        <KeyValuePair name={'Tangible Net Worth'} value={formattedTangibleNetWorthSum} />
      </Grid>
    </SegmentContainer>
  );
};
