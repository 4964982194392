/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import React, { useCallback, type FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Sync } from '@mui/icons-material';
import { Button } from '@lama/design-system';
import { useSyncSamplesWithS3Mutation } from '../../shared/hooks/react-query/pipeline/useSyncSamplesWithS3Mutation';

export const SyncSpreadingSamplesButton: FC = () => {
  const { syncSpreadingSamples } = useFlags();

  const { mutateAsync: syncS3SpreadingSamples, isPending: loadingSyncSamples } = useSyncSamplesWithS3Mutation('spreadingsamples');

  const onSyncSamplesClick = useCallback(async () => {
    await syncS3SpreadingSamples();
  }, [syncS3SpreadingSamples]);

  return syncSpreadingSamples ? (
    <Button variant={'primary'} color={'primary'} size={'l'} startIcon={<Sync />} onClick={onSyncSamplesClick} loading={loadingSyncSamples}>
      {'Sync Spreading Samples'}
    </Button>
  ) : null;
};
