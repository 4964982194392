/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { LoadingPage } from '@lama/app-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useApplicationDocumentsQuery } from '../../shared/hooks/react-query/application/useApplicationDocumentsQuery';
import { DocumentHubEmptyState } from '../DocumentHub/DocumentHubEmptyState';
import { SpreadingDocumentProvider } from '../Spreading/SpreadingDocumentContext';
import { SpreadingDialogContent } from '../Spreading/SpreadingDialog/SpreadingContent';
import { SpreadingToolbar } from '../Spreading/SpreadingDialog/SpreadingToolbar/SpreadingToolbar';

export const SpreadingManagementPage: React.FC = () => {
  const { spreadingBackOfficeMode } = useFlags();
  const { applicationId } = useParams();

  const { isLoading, data: relatedDocuments } = useApplicationDocumentsQuery({ applicationId: applicationId ?? '' });

  if (!spreadingBackOfficeMode || !applicationId) {
    return <DocumentHubEmptyState />;
  }

  return isLoading ? (
    <LoadingPage />
  ) : !relatedDocuments?.length ? (
    <DocumentHubEmptyState />
  ) : (
    <SpreadingDocumentProvider applicationId={applicationId} documents={relatedDocuments}>
      <SpreadingToolbar applicationId={applicationId} documents={relatedDocuments} />
      <Divider />
      <SpreadingDialogContent />
    </SpreadingDocumentProvider>
  );
};
