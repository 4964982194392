/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Tabs, Tab, useTheme } from '@mui/material';
import { capitalCase } from 'change-case-all';
import { sumBy } from 'lodash-es';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import styled, { css } from 'styled-components';
import type { CalculatedPipelineItem } from './types';

const HidingContainer = styled(Flex)<{ hideCategories: boolean }>`
  width: 100%;
  transition: display 0.5s ease-out;
  ${({ hideCategories }) =>
    hideCategories &&
    css`
      display: none;
    `}
`;

export const pipelineCategories = ['Leads', 'Qualified', 'Closing', 'Funded', 'alerts', 'Lost'];
export const workflowPipelineCategories = ['Leads', 'Qualified', 'Pending Decision', 'Closing', 'Closed', 'Lost'];

interface PipelineCategoryTabsProps {
  selectedPipelineCategory: string | null;
  onChange: (_: React.SyntheticEvent, category: string) => void;
  pipelineItems: CalculatedPipelineItem[];
  hideCategories: boolean;
}

const CategoryTabLabel = ({
  category,
  totalAmount,
  numberOfApplications,
}: {
  category: string;
  totalAmount: number;
  numberOfApplications: number;
}) => {
  const theme = useTheme();
  return (
    <Flex flexDirection={'column'} alignItems={'baseline'} mr={'auto'}>
      <Text variant={'body1'}>{capitalCase(category)}</Text>
      <Flex gap={1}>
        <Text variant={'h5'} color={theme.palette.text.primary}>
          {formatValue(totalAmount, 'currencyCompact')}
        </Text>
        <Text variant={'h5'} color={theme.palette.text.disabled}>{`(${numberOfApplications})`}</Text>
      </Flex>
    </Flex>
  );
};

export const PipelineCategoryTabs: FC<PipelineCategoryTabsProps> = ({
  selectedPipelineCategory,
  onChange,
  pipelineItems,
  hideCategories,
}) => {
  const pipelineItemInCategories = useMemo(
    () =>
      Object.fromEntries(workflowPipelineCategories.map((c) => [c, pipelineItems.filter((item) => item.pipelineCategories.includes(c))])),
    [pipelineItems],
  );

  const categoryAmounts: [string, number][] = useMemo(
    () =>
      Object.entries(pipelineItemInCategories).map(([category, items]) => [
        category,
        sumBy(items, (item) => item.requestedAmount ?? 0) ?? 0,
      ]),
    [pipelineItemInCategories],
  );

  const totalApplicationSum = useMemo(() => sumBy(pipelineItems, (item) => item.requestedAmount || 0), [pipelineItems]);

  return (
    <HidingContainer hideCategories={hideCategories}>
      <Tabs
        sx={{ textTransform: 'none', width: '100%' }}
        centered={false}
        value={selectedPipelineCategory}
        variant={'fullWidth'}
        onChange={onChange}
      >
        {categoryAmounts.map(([category, amount]) => (
          <Tab
            key={category}
            sx={{ textTransform: 'none' }}
            label={
              <CategoryTabLabel
                category={category}
                totalAmount={amount}
                numberOfApplications={pipelineItemInCategories[category]?.length ?? 0}
              />
            }
            value={category}
          />
        ))}
        <Tab
          sx={{ textTransform: 'none' }}
          label={<CategoryTabLabel category={'all'} totalAmount={totalApplicationSum} numberOfApplications={pipelineItems.length} />}
          value={'all'}
        />
      </Tabs>
    </HidingContainer>
  );
};
