import React, { useEffect, useCallback, useContext, useMemo } from 'react';
import type { PageChangeEvent, ViewerProps } from '@react-pdf-viewer/core';
import { Viewer, Worker, ViewMode } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { useTheme } from '@mui/material';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { highlightPlugin, Trigger, type RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import { useDebounce, useToggle } from 'react-use';
import { Flex } from '@lama/design-system';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import styled from 'styled-components';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { DocumentHighlights, ImageDocumentViewer } from '@lama/app-components';
import type { PDFHighlightedArea } from '@lama/app-components';
import { SpreadingDocumentContext } from '../SpreadingDocumentContext';
import jumpToPagePlugin from './jumpToPagePlugin';
import { PageThumbnail } from './PageThumbnail';
import { DocumentToolbar } from './DocumentToolbar';

interface RemovePartsDefaultToolbarExampleProps {
  fileUrl: string;
}

const getPDFHighlight = (boundingBox: PDFHighlightedArea | null) => {
  if (!boundingBox?.pageIndex) {
    return null;
  }

  const { top, left, width, height, pageIndex } = boundingBox;

  return {
    top: (top ?? 0) * 100,
    left: (left ?? 0) * 100,
    width: (width ?? 0) * 100,
    height: (height ?? 0) * 100,
    pageIndex: pageIndex - 1,
  };
};

const StyledThumbnailContainer = styled(Flex)`
  max-height: 100%;

  //hide the scrollbar
  .rpv-thumbnail__list {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const workerUrl = 'https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js';

const SpreadingPDFViewer: React.FC<RemovePartsDefaultToolbarExampleProps & ViewerProps> = ({ fileUrl, plugins, ...props }) => {
  const { spreadingBackOfficeMode } = useFlags();
  const thumbnailPluginInstance = thumbnailPlugin();
  const jumpToPagePluginInstance = jumpToPagePlugin();
  const [toolbarOpen, toggleToolbarOpen] = useToggle(true);
  const { jumpToPage: jumpToPageAction } = jumpToPagePluginInstance;
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const { highlightedArea, setCurrentPage, setHighlightedArea, currentPage, jumpToPage, setJumpToPage } =
    useContext(SpreadingDocumentContext);

  const { Thumbnails } = thumbnailPluginInstance;

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const theme = useTheme();

  const documentType = useMemo(() => {
    const documentName = fileUrl?.toLocaleLowerCase();
    if (documentName.includes('.pdf')) {
      return 'pdf';
    }
    if (documentName.includes('.png') || documentName.includes('.jpg') || documentName.includes('.jpeg')) {
      return 'image';
    }
    return 'other';
  }, [fileUrl]);

  const onDocumentLoad = useCallback(() => {
    jumpToPageAction(0);
    setHighlightedArea(null);
    if (!toolbarOpen) {
      toggleToolbarOpen();
    }
  }, [jumpToPageAction, setHighlightedArea, toggleToolbarOpen, toolbarOpen]);

  const onPageChange = useCallback(
    (e: PageChangeEvent) => {
      setCurrentPage(e.currentPage);
    },
    [setCurrentPage],
  );

  useEffect(() => {
    if (jumpToPage !== null) {
      jumpToPageAction(jumpToPage);
      setJumpToPage(null);
      setCurrentPage(jumpToPage);
    }
  }, [jumpToPage, jumpToPageAction, setCurrentPage, setJumpToPage]);

  const renderHighlights = useCallback(
    (highlightProps: RenderHighlightsProps) => {
      if (!highlightedArea) {
        return <div />;
      }

      const highlightArea = getPDFHighlight(highlightedArea);

      if (!highlightArea || highlightArea?.pageIndex !== highlightProps.pageIndex) {
        return <div />;
      }

      return <DocumentHighlights boundingBoxes={[highlightArea]} highlightProps={highlightProps} />;
    },
    [highlightedArea],
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  useDebounce(
    () => {
      const highlightArea = getPDFHighlight(highlightedArea);

      if (highlightArea && highlightArea.pageIndex !== currentPage) {
        const areaWithTopBuffer = { ...highlightArea, top: Math.max(0, highlightArea.top - 20) };
        highlightPluginInstance.jumpToHighlightArea(areaWithTopBuffer);
      }
    },
    100,
    [highlightedArea],
  );

  return (
    <StyledThumbnailContainer flexDirection={'row'} backgroundColor={theme.palette.grey[100]} flex={1}>
      <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} pl={8} maxWidth={'15%'} flex={'0.15 1 15%'}>
        {documentType === 'pdf' ? <Thumbnails renderThumbnailItem={spreadingBackOfficeMode ? PageThumbnail : undefined} /> : null}
      </Flex>
      <Flex flexDirection={'column'} flex={'0.85 1 85%'} backgroundColor={theme.palette.grey[100]} position={'relative'}>
        {documentType === 'pdf' ? (
          <Worker workerUrl={workerUrl}>
            {documentType === 'pdf' ? <Toolbar /> : null}
            <Viewer
              fileUrl={fileUrl}
              viewMode={ViewMode.SinglePage}
              onPageChange={onPageChange}
              onDocumentLoad={onDocumentLoad}
              pageLayout={{
                buildPageStyles: () => ({
                  backgroundColor: '#f5f5f5',
                }),
              }}
              plugins={[
                toolbarPluginInstance,
                thumbnailPluginInstance,
                highlightPluginInstance,
                pageNavigationPluginInstance,
                jumpToPagePluginInstance,
                ...(plugins ?? []),
              ]}
              {...props}
            />
          </Worker>
        ) : documentType === 'image' && fileUrl ? (
          <ImageDocumentViewer documentUrl={fileUrl} />
        ) : null}
        <DocumentToolbar
          documetHasMultiplePages={documentType === 'pdf'}
          pageNavigationPluginInstance={pageNavigationPluginInstance}
          open={toolbarOpen}
        />
      </Flex>
    </StyledThumbnailContainer>
  );
};

export default React.memo(SpreadingPDFViewer);
