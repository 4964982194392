import React, { useContext } from 'react';
import type { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingPage } from '@lama/app-components';
import { useEffectOnce } from 'react-use';
import { Flex } from '@lama/design-system';
import { BackLink } from '../../../shared/components/BackLink';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { useGetDeal } from '../../../shared/hooks/react-query/deal/useGetDeal';
import { useUpdateDeal } from '../../../shared/hooks/react-query/deal/useUpdateDeal';
import { BusinessMetrics } from '../../../shared/components/BusinessMetrics/BusinessMetrics';
import { CollateralsTable } from './Collateral/CollateralsTable';
import { DealHeaderWrapper } from './DealHeaderWrapper';
import { DealInformation } from './DealInformation';
import { LoanDescription } from './LoanDescription';
import { LockedInsights } from './LockedInsights';
import { UseOfFundsBreakdownTable } from './UseOfFundsBreakdown/UseOfFundsBreakdownTable';

export const DealPage: FC = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const { partner } = useContext(UserDetailsContext);
  const updateDeal = useUpdateDeal(dealId ?? '');
  const { data: deal, isPending: loadingDeal } = useGetDeal(dealId, partner);
  const location = useLocation();
  const category = location.state?.category;

  useEffectOnce(() => {
    if (deal && !deal.seen) {
      void updateDeal({
        seen: true,
      });
    }
  });

  if (loadingDeal) {
    return <LoadingPage />;
  }

  if (!deal) {
    return (
      <Flex flexDirection={'column'} gap={4}>
        <BackLink title={'Back to Deals'} to={`/deals${category ? `?category=${category}` : ''}`} />
        <span>{'Deal not found'}</span>
      </Flex>
    );
  }

  return (
    <Flex flexDirection={'column'} gap={6} pb={4}>
      <DealHeaderWrapper deal={deal} category={category} />
      <Flex flexDirection={'column'} gap={6} px={10}>
        <DealInformation deal={deal} />
        {deal.useOfFundsBreakdown ? <UseOfFundsBreakdownTable useOfFundsBreakdown={deal.useOfFundsBreakdown} /> : null}
        {deal.collateral ? <CollateralsTable collateral={deal.collateral} /> : null}
        {deal.loanDescription ? <LoanDescription description={deal.loanDescription} /> : null}
        <LockedInsights />
        <BusinessMetrics dealWithFinancials={{ ...deal, financials: deal.businessFinancials }} />
      </Flex>
    </Flex>
  );
};
