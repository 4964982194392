import axios from 'axios';
import saveAs from 'file-saver';
import type { GetAttributesResponse, FinancialData } from '@lama/contracts';
import type { DocumentLineItem, DocumentPage, DocumentWithIssues } from '@lama/document-service-client';
import { financialsFormTypes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/utils/financialsFormTypes';
import { getFormAttributesWithFinancialData, isFinancialRelatedToFormPage } from '../../Forms/TaxReturns/formFinancialsUtils';

const parseTaxReturnForm = (
  formFinancials: FinancialData[],
  formAttributes: GetAttributesResponse['attributes'],
  startDocumentPage: DocumentPage,
  endDocumentPage: DocumentPage,
) => ({
  formType: startDocumentPage.formType,
  startPage: startDocumentPage.page,
  endPage: endDocumentPage.page,
  attributes: getFormAttributesWithFinancialData(formAttributes, formFinancials ?? [], startDocumentPage.page).map(
    ({ attribute, financialData }) => ({
      value: financialData?.value,
      attributeName: attribute.financialData?.type,
      startDate: attribute.financialData?.startDate,
      endDate: attribute.financialData?.endDate,
    }),
  ),
});

const parseFinancialStatementsForm = (lineItems: DocumentLineItem[], startDocumentPage: DocumentPage, endDocumentPage: DocumentPage) => ({
  formType: startDocumentPage.formType,
  startPage: startDocumentPage.page,
  endPage: endDocumentPage.page,
  lineItems: lineItems
    .filter(({ page }) => page && page >= startDocumentPage.page && page <= endDocumentPage.page)
    .map(({ id, text, page, attribute, parentId, values }) => ({
      id,
      text,
      page,
      attribute,
      parentId,
      values,
    })),
});

export const exportSampleData = async (
  currentDocument: DocumentWithIssues,
  documentPagesByFormType: { startDocumentPage: DocumentPage; endDocumentPage: DocumentPage }[],
  entityFinancials: FinancialData[],
  attributes: GetAttributesResponse['attributes'],
  lineItems: DocumentLineItem[],
) => {
  const sampleData = documentPagesByFormType.map(({ startDocumentPage, endDocumentPage }) => {
    if (financialsFormTypes.has(startDocumentPage.formType)) {
      return parseFinancialStatementsForm(lineItems, startDocumentPage, endDocumentPage);
    }
    const formAttributes = attributes.filter(
      ({ formId, entityType }) => formId === startDocumentPage.formType && entityType === currentDocument.relatedEntityType,
    );
    const formFinancials = entityFinancials.filter(({ source }) =>
      isFinancialRelatedToFormPage(source, currentDocument.id, startDocumentPage.page, endDocumentPage?.page),
    );
    return parseTaxReturnForm(formFinancials, formAttributes, startDocumentPage, endDocumentPage);
  });

  const dataBlob = new Blob([JSON.stringify(sampleData)], {
    type: 'application/zip',
  });

  saveAs(dataBlob, `${currentDocument.id}_expectedResults.json`);

  if (currentDocument.previewUrl) {
    const pdfFile = await axios.get(currentDocument.previewUrl, { responseType: 'blob' });

    const pdfBlob = new Blob([pdfFile.data], {
      type: 'application/zip',
    });

    saveAs(pdfBlob, `${currentDocument.id}.pdf`);
  }
};
