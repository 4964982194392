/* eslint-disable react/no-array-index-key */
import type { FC, ReactNode } from 'react';
import React, { useContext, useMemo } from 'react';
import type { Entity } from '@lama/common-types';
import type { ComponentConfiguration, SegmentConfiguration } from '@lama/contracts';
import { Flex, Text } from '@lama/design-system';
import { getOpportunityEntityByType } from '@lama/properties';
import { businessNameWithRelationsSelector, personNameWithRelationsSelector } from '@lama/data-formatters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../../ApplicationContext';
import { componentByType } from '../componentByType';

const getComponentByConfiguration = (componentConfiguration: ComponentConfiguration) =>
  componentByType[componentConfiguration.type === 'custom' ? componentConfiguration.customComponentName : componentConfiguration.type];

const getComponent = ({
  componentConfiguration,
  segmentIndex,
  componentIndex,
  entity,
  entityType,
  sectionId,
}: {
  componentConfiguration: ComponentConfiguration;
  segmentIndex: number;
  componentIndex: number;
  entity?: Record<string, any> & { id: string };
  entityType?: Entity;
  sectionId: string;
}): ReactNode => {
  const Component = getComponentByConfiguration(componentConfiguration);

  if (!Component) {
    return null;
  }

  return (
    <div key={`${sectionId}_${entity ? `${entity.id}_` : ''}${componentConfiguration.type}_${segmentIndex}_${componentIndex}`}>
      <Component componentConfiguration={componentConfiguration} entity={entity} entityType={entityType} />
    </div>
  );
};

interface GenericEntitySegmentProps {
  components: ComponentConfiguration[];
  sectionId: string;
  segmentIndex: number;
  entity: Record<string, any> & { id: string };
  entityType: Entity;
}

const GenericEntitySegment: FC<GenericEntitySegmentProps> = ({ entity, entityType, components, sectionId, segmentIndex }) => {
  const { fibtDemo } = useFlags();
  const { application } = useContext(ApplicationContext);

  const entityName = useMemo(() => {
    if (entityType === 'application' || entityType === 'opportunity') {
      return null;
    }
    const name =
      entityType === 'person'
        ? personNameWithRelationsSelector(entity.id, application)
        : businessNameWithRelationsSelector(entity.id, application);
    return fibtDemo ? name.replace('Borrower', 'Applicant') : name;
  }, [application, entity.id, entityType, fibtDemo]);

  const componentNodes = useMemo<ReactNode[]>(
    () =>
      components.map((componentConfiguration, i) =>
        getComponent({ componentConfiguration, segmentIndex, componentIndex: i, entity, entityType, sectionId }),
      ),
    [components, entity, entityType, sectionId, segmentIndex],
  );

  return (
    <Flex flexDirection={'column'} gap={8}>
      {entityName ? <Text variant={'h6'}>{entityName}</Text> : null}
      {componentNodes}
    </Flex>
  );
};

export const GenericSection: FC<{
  segments: SegmentConfiguration[];
  sectionId: string;
  sectionEntity?: Record<string, any> & { id: string };
}> = ({ segments, sectionId, sectionEntity }) => {
  const { opportunity } = useContext(ApplicationContext);

  const segmentComponents = useMemo<ReactNode[]>(
    () =>
      segments.flatMap((segment, segmentIdx) => {
        const segmentEntityType = segment.entityType;
        if (segmentEntityType) {
          if (sectionEntity) {
            console.error('Section configuration does not support both section entity and segment entity');
            return null;
          }

          const entities = getOpportunityEntityByType(opportunity, segmentEntityType, segment.entityGroups);

          return entities.map<ReactNode>((entity) => (
            <GenericEntitySegment
              entity={entity as any}
              entityType={segmentEntityType}
              components={segment.components}
              key={`${sectionId}_${entity.id}_${segmentIdx}`}
              sectionId={sectionId}
              segmentIndex={segmentIdx}
            />
          ));
        }

        return segment.components.map<ReactNode>((componentConfiguration, i) =>
          getComponent({
            componentConfiguration,
            segmentIndex: segmentIdx,
            componentIndex: i,
            sectionId,
            entity: sectionEntity,
          }),
        );
      }),
    [opportunity, sectionEntity, sectionId, segments],
  );

  return (
    <Flex flexDirection={'column'} gap={12}>
      {segmentComponents}
    </Flex>
  );
};
