import React, { useMemo } from 'react';
import type { FC } from 'react';
import * as selectors from '@lama/selectors';
import type { EntityWithFinancials } from '@lama/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { DealApiModel } from '@lama/contracts';
import { data as demoAccountingData } from '../../demoData/businessMetrics';
import { MetricsTable } from './MetricsTable';
import { columnsDefinition } from './MetricsColumns';

interface BusinessMetricsProps {
  dealWithFinancials: DealApiModel & EntityWithFinancials;
}

const createBusinessMetricsFromAccountingData = (dealWithFinancials: DealApiModel & EntityWithFinancials) => [
  {
    id: 'Total Assets',
    twoYearsPriorFiscal:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Assets', yearsBack: 2 })?.value ?? null,
    priorFiscalYear: selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Assets', yearsBack: 1 })?.value ?? null,
    ytd: selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Assets', yearsBack: 0 })?.value ?? null,
  },
  {
    id: 'Cash & Cash Equivalents',
    twoYearsPriorFiscal:
      selectors.getFinancialAttribute({
        entity: dealWithFinancials,
        financialType: 'Cash & Cash Equivalents',
        yearsBack: 2,
      })?.value ?? null,
    priorFiscalYear:
      selectors.getFinancialAttribute({
        entity: dealWithFinancials,
        financialType: 'Cash & Cash Equivalents',
        yearsBack: 1,
      })?.value ?? null,
    ytd:
      selectors.getFinancialAttribute({
        entity: dealWithFinancials,
        financialType: 'Cash & Cash Equivalents',
        yearsBack: 0,
      })?.value ?? null,
  },
  {
    id: 'Accounts Receivable',
    twoYearsPriorFiscal:
      selectors.getFinancialAttribute({
        entity: dealWithFinancials,
        financialType: 'Trade Notes & Accounts Receivable',
        yearsBack: 2,
      })?.value ?? null,
    priorFiscalYear:
      selectors.getFinancialAttribute({
        entity: dealWithFinancials,
        financialType: 'Trade Notes & Accounts Receivable',
        yearsBack: 1,
      })?.value ?? null,
    ytd:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Trade Notes & Accounts Receivable', yearsBack: 0 })
        ?.value ?? null,
  },
  // "Current Debt" pulled for backwards compatibility
  {
    id: 'Short Term Debt',
    twoYearsPriorFiscal:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Business Debt Service', yearsBack: 2 })?.value ??
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Current Debt', yearsBack: 2 })?.value ??
      null,
    priorFiscalYear:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Business Debt Service', yearsBack: 1 })?.value ??
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Current Debt', yearsBack: 1 })?.value ??
      null,
    ytd:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Business Debt Service', yearsBack: 0 })?.value ??
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Current Debt', yearsBack: 0 })?.value ??
      null,
  },
  {
    id: 'Long Term Debt',
    twoYearsPriorFiscal:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Non Current Debt', yearsBack: 2 })?.value ?? null,
    priorFiscalYear:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Non Current Debt', yearsBack: 1 })?.value ?? null,
    ytd: selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Non Current Debt', yearsBack: 0 })?.value ?? null,
  },
  {
    id: 'Revenue',
    twoYearsPriorFiscal:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Net Revenue', yearsBack: 2 })?.value ?? null,
    priorFiscalYear:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Net Revenue', yearsBack: 1 })?.value ?? null,
    ytd: selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Net Revenue', yearsBack: 0 })?.value ?? null,
  },
  {
    id: 'Cost of Goods Sold',
    twoYearsPriorFiscal:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Cost of Goods Sold', yearsBack: 2 })?.value ?? null,
    priorFiscalYear:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Cost of Goods Sold', yearsBack: 1 })?.value ?? null,
    ytd: selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Cost of Goods Sold', yearsBack: 0 })?.value ?? null,
  },
  {
    id: 'Taxes',
    twoYearsPriorFiscal:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Taxes', yearsBack: 2 })?.value ?? null,
    priorFiscalYear: selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Taxes', yearsBack: 1 })?.value ?? null,
    ytd: selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Taxes', yearsBack: 0 })?.value ?? null,
  },
  {
    id: 'Net Income',
    twoYearsPriorFiscal:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Net Income', yearsBack: 2 })?.value ?? null,
    priorFiscalYear:
      selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Net Income', yearsBack: 1 })?.value ?? null,
    ytd: selectors.getFinancialAttribute({ entity: dealWithFinancials, financialType: 'Net Income', yearsBack: 0 })?.value ?? null,
  },
];

export const BusinessMetrics: FC<BusinessMetricsProps> = ({ dealWithFinancials }) => {
  const { useDemoBusinessDetails } = useFlags<{ useDemoBusinessDetails: boolean }>();
  const tableData = useMemo(
    () =>
      useDemoBusinessDetails ? demoAccountingData : dealWithFinancials ? createBusinessMetricsFromAccountingData(dealWithFinancials) : [],
    [dealWithFinancials, useDemoBusinessDetails],
  );

  if (!tableData?.length) {
    return null;
  }

  return <MetricsTable columns={columnsDefinition} data={tableData} />;
};
