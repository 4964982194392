import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import type { ProductTerms } from '@lama/contracts';
import { useAuth0 } from '@auth0/auth0-react';
import { useAsyncFn } from 'react-use';
import { ProductSettingsContext } from '../ProductSettingsContext';
import { productServiceClient } from '../../../../../shared/clients/productService';
import { TermLengthSettings } from './TermLengthSettings';
import { AprSettings } from './AprSettings';

export const TermsSettingsPage = () => {
  const { product, registerSaveFn } = useContext(ProductSettingsContext);
  const { getAccessTokenSilently } = useAuth0();
  const [terms, setTerms] = useState(product?.terms ?? {});

  const onFieldChanged = useCallback(
    (fieldChange: Partial<ProductTerms>) => {
      setTerms({ ...terms, ...fieldChange });
    },
    [terms],
  );

  const [, saveProductTerms] = useAsyncFn(async () => {
    const token = await getAccessTokenSilently();
    return productServiceClient.updateProduct(product.id, { terms }, token);
  }, [getAccessTokenSilently, product.id, terms]);

  useEffect(() => {
    registerSaveFn('terms', saveProductTerms);
  }, [saveProductTerms, registerSaveFn]);

  return (
    <Stack p={5} gap={4} sx={{ backgroundColor: 'white', border: '1px solid #E0E0E0', borderRadius: '8px' }}>
      <TermLengthSettings term={product.terms?.ranges?.termLength} onChange={onFieldChanged} />
      <AprSettings apr={product.terms?.ranges?.apr} onChange={onFieldChanged} />
    </Stack>
  );
};
