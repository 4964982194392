import { useContext, useMemo } from 'react';
import { getRequirementsByCategory } from '@lama/app-components';
import type { DocumentDetails, DocumentGroup } from '../types';
import { ApplicationContext } from '../../Application/ApplicationContext';
import { useOpportunityRequirementsQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityRequirementsQuery';
import { useOpportunityClosingTasksQuery } from '../../../shared/hooks/react-query/opportunity/useOpportunityClosingTasksQuery';
import { useDocumentGroupsByTopic } from './useDocumentGroupsByTopic';

export const useDocumentDetailsByEntity = (documents: DocumentDetails[]) => {
  const {
    application,
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);

  const { data: allRequirements, isPending: loadingRequirements } = useOpportunityRequirementsQuery(opportunityId, {});
  const { data: closingTasks } = useOpportunityClosingTasksQuery(opportunityId);
  const { groups: topicGroups } = useDocumentGroupsByTopic(documents, allRequirements ?? [], closingTasks ?? []);

  return useMemo(() => {
    if (!topicGroups.length) {
      return {
        loading: false,
        groups: [],
      };
    }

    const requirementGroups = allRequirements
      ? getRequirementsByCategory(allRequirements, application).map<DocumentGroup>((p) => {
          const closingTasGroupskWithSameEntity = topicGroups.filter(
            (group) =>
              group?.closingTasks?.length &&
              group.documents?.every((doc) => p.entityId === doc.entityId && doc.entityType === p.entityType),
          );

          const subGroups: DocumentGroup[] = [
            ...topicGroups.filter((group) => group.requirements?.some((req) => p.requirements.some((r) => r.id === req.id))),
            ...closingTasGroupskWithSameEntity,
          ];

          return {
            title: p.categoryLongName,
            icon: p.categoryIcon,
            subtitle: p.entityRelation,
            subGroups,
          };
        })
      : [];

    return {
      loading: loadingRequirements,
      // Remove the submit group as it is not needed in the document hub
      groups: requirementGroups.filter((group) => group.title !== 'Submit'),
    };
  }, [allRequirements, application, loadingRequirements, topicGroups]);
};
