import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { personFullName } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import { Avatar, FormikAutoSave, FormikPicker, displayToast, ConfirmLeave } from '@lama/app-components';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import type { LabeledValue } from '@lama/contracts';
import type { User } from '@lama/user-service-client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useUsersByPartnerQuery } from '../../../../shared/hooks/react-query/user/useUsersByPartnerQuery';
import { ApplicationContext } from '../../ApplicationContext';
import { UserDetailsContext } from '../../../../shared/context/UserDetailsContext';
import { useUpdateOpportunityMutation } from '../../../../shared/hooks/react-query/opportunity/useUpdateOpportunityMutation';
import { SaveFormButton } from '../SaveFormButton';

const ReviewerItem: FC<{ user: User }> = ({ user }) => (
  <Flex gap={2} alignItems={'center'} justifyContent={'flex-start'}>
    <Avatar size={24} firstName={user.firstName} lastName={user.lastName} />
    <Text>{personFullName(user)}</Text>
  </Flex>
);

export const OfacReportReviewSegment: FC = () => {
  const { opportunity } = useContext(ApplicationContext);
  const { userId: currentUser } = useContext(UserDetailsContext);
  const { autoSaveEnabled } = useFlags();
  const { data: users } = useUsersByPartnerQuery(opportunity.partnerId);
  const { mutate: updateOpportunity, isPending: updatingOpportunity } = useUpdateOpportunityMutation(opportunity.id);

  const initialValues = useMemo(
    () => ({
      designation: opportunity.underwriting?.ofacSearchReview?.designation ?? '',
      reviewedByUser: opportunity.underwriting?.ofacSearchReview?.reviewedByUser ?? '',
    }),
    [opportunity.underwriting],
  );

  const onSubmit = useCallback(
    async (values: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
      const { designation, reviewedByUser } = values;
      const afterChangeValues = {
        designation: designation === 'Positive' ? ('Positive' as const) : ('Negative' as const),
        reviewedByUser: reviewedByUser ?? currentUser ?? '',
      };
      resetForm({ values: afterChangeValues });
      updateOpportunity(
        {
          underwriting: {
            ofacSearchReview: afterChangeValues,
          },
        },
        {
          onError: () => {
            displayToast('There was a problem processing the request. Please try again.', 'error');
          },
        },
      );
    },
    [currentUser, updateOpportunity],
  );

  const reviewersOptions: LabeledValue[] = useMemo(
    () =>
      users?.map((user) => ({
        value: user.id,
        label: personFullName(user),
      })) ?? [],
    [users],
  );

  const renderReviewerOption = useCallback(
    ({ value: userId }: { value: string }) => {
      const user = users?.find((u) => u.id === userId);

      if (!user) {
        return null;
      }

      return <ReviewerItem user={user} />;
    },
    [users],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty, isValid, handleSubmit }) => (
        <ConfirmLeave shouldBlock={dirty}>
          <Flex flexDirection={'column'} gap={4}>
            <Flex gap={4}>
              <FormikPicker name={'designation'} label={'Designation'} values={['Negative', 'Positive']} />
              <FormikPicker name={'reviewedByUser'} label={'Reviewed By'} values={reviewersOptions} renderOption={renderReviewerOption} />
            </Flex>
            {autoSaveEnabled ? (
              <FormikAutoSave />
            ) : (
              <SaveFormButton loading={updatingOpportunity} submit={handleSubmit} disabled={!dirty || !isValid} />
            )}
          </Flex>
        </ConfirmLeave>
      )}
    </Formik>
  );
};
