import React from 'react';
import type { FC } from 'react';
import type { Document } from '@lama/document-service-client';
import { Text, grayPalette, Flex } from '@lama/design-system';
import { MiscellaneousDocumentBox } from './MiscellaneousDocumentBox';

interface DocumentsCategoryListProps {
  miscellaneousDocuments: Document[];
}

export const MiscellaneousDocumentsList: FC<DocumentsCategoryListProps> = ({ miscellaneousDocuments }) => {
  if (!miscellaneousDocuments.length) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={6}>
      <Text variant={'body2'} color={grayPalette[500]}>
        {'Miscellaneous Documents'}
      </Text>
      <Flex flexDirection={'column'} gap={6}>
        {miscellaneousDocuments.map((document) => (
          <MiscellaneousDocumentBox key={document.id} description={document.filename} document={document} />
        ))}
      </Flex>
    </Flex>
  );
};
