/* eslint-disable react/no-array-index-key */
import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { formatConditionDefinition, getConditionConfig } from '@lama/conditions';
import { isBoolean, isNil } from 'lodash-es';
import {
  applicationBorrowingRelatedBusinessesSelector,
  businessName,
  personFullName,
  relatedPeopleByRelationsSelector,
} from '@lama/data-formatters';
import { selectorDisplayNames } from '@lama/selectors';
import { sentenceCase, titleCase } from 'change-case-all';
import { Flex, Text } from '@lama/design-system';
import type { InfoLineProps } from '@lama/app-components';
import { BusinessIcon, InformationStack, PersonIcon } from '@lama/app-components';
import { Divider } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../ApplicationContext';
import { OpportunityAuditFeed } from '../../../shared/components/Audit';
import { useEvaluateOpportunityQuery } from '../../../shared/hooks/react-query/application/useEvaluateOpportunityQuery';
import { ConditionResultIcon } from '../../../shared/components/conditions/conditionIcons';
import { fibtDemoKeyIndicators } from '../../../shared/demoData/fibtKeyIndicators';
import { useRelatedApplicationsQuery } from '../../../shared/hooks/react-query/application/useRelatedApplicationsQuery';
import { useGetPartnerQuery } from '../../../shared/hooks/react-query/partner/useGetPartnerQuery';
import { OpportunityAuditFeedV2 } from '../../../shared/components/Audit/AuditV2/OpportunityAuditFeedV2';
import { getBusinessDetails, getLoanDetails, getPersonDetails } from './summaryData';
import { OpportunityAlerts } from './OpportunityAlerts';
import { KeyIndicatorsSkeleton } from './KeyIndicatorsSkeleton';
import { DetailsSegment } from './DetailsSegment';
import { PrimaryContactDetailsSegment } from './PrimaryContactDetails/PrimaryContactDetailsSegment';
import { RelatedApplications } from './RelatedApplications/RelatedApplications';
import { OverviewSection } from './OverviewColumn';

const KeyIndicators: FC = () => {
  const { fibtDemo } = useFlags();
  const { opportunity } = useContext(ApplicationContext);
  const { data: evaluatedConditions, isPending: loadingEvaluatedConditions } = useEvaluateOpportunityQuery(opportunity.id);

  const keyIndicatorsData = useMemo(() => {
    const keyIndicators =
      evaluatedConditions
        ?.filter(({ condition }) => !condition.hideInKeyIndicators)
        .map<InfoLineProps>(({ condition, ...evaluation }) => {
          const conditionConfiguration = getConditionConfig(condition);

          const value =
            isNil(evaluation.extractedValue) || evaluation.extractedValue === ''
              ? '-'
              : isBoolean(evaluation.extractedValue)
              ? evaluation.extractedValue
                ? 'Yes'
                : 'No'
              : evaluation.extractedValue;

          const label =
            selectorDisplayNames[condition.conditionMatcher.fieldName] ??
            conditionConfiguration?.displayName ??
            condition.name ??
            titleCase(sentenceCase(condition.conditionMatcher.fieldName));

          return {
            label,
            icon: <ConditionResultIcon reason={evaluation.reason} size={'16px'} />,
            type: conditionConfiguration?.format,
            values: value,
            tip: formatConditionDefinition(condition, conditionConfiguration?.format),
            wrap: false,
          };
        }) ?? [];

    if (fibtDemo) {
      keyIndicators.push(...fibtDemoKeyIndicators);
    }

    return keyIndicators;
  }, [evaluatedConditions, fibtDemo]);
  const keyIndicatorsSubtitle = useMemo(
    () => (
      <Text variant={'body3'} color={'secondary'}>
        {`Financial conditions are calculated according to data from ${opportunity.referenceYear}`}
      </Text>
    ),
    [opportunity.referenceYear],
  );

  return loadingEvaluatedConditions ? (
    <KeyIndicatorsSkeleton />
  ) : (
    <InformationStack title={'Key Indicators'} items={keyIndicatorsData} subtitle={keyIndicatorsSubtitle} />
  );
};

const ApplicationDetails: FC = () => {
  const { fibtDemo } = useFlags();
  const { application, opportunity } = useContext(ApplicationContext);
  const { data: partner } = useGetPartnerQuery(opportunity.partnerId);
  const { showApplicationNumber } = useFlags();
  const showHmdaApplicable = useMemo(() => partner?.featureConfigurations?.showHmdaApplicable, [partner]);
  const { data: relatedApplications } = useRelatedApplicationsQuery(application.id);

  const loanDetails = useMemo(
    () => getLoanDetails(application, opportunity, Boolean(showApplicationNumber), Boolean(showHmdaApplicable), relatedApplications),
    [application, opportunity, showApplicationNumber, showHmdaApplicable, relatedApplications],
  );
  const borrowingBusinessesDetails = useMemo(
    () =>
      applicationBorrowingRelatedBusinessesSelector(application).map(({ business }) => ({
        borrowerIcon: <BusinessIcon />,
        title: businessName(business) ?? '',
        details: getBusinessDetails(business, !!fibtDemo),
      })),
    [application, fibtDemo],
  );
  const personDetails = useMemo(
    () =>
      relatedPeopleByRelationsSelector(application, ['borrower']).map(({ person }) => ({
        borrowerIcon: <PersonIcon />,
        title: personFullName(person),
        details: getPersonDetails(person),
      })),
    [application],
  );

  const detailComponents = useMemo(() => {
    const components = [
      ...(fibtDemo ? [] : [<DetailsSegment key={'overview_loan_details'} title={'Loan Details'} items={loanDetails} />]),
      <PrimaryContactDetailsSegment key={'overview_primary_contact'} application={application} />,
      ...borrowingBusinessesDetails.map(({ title, details, borrowerIcon }, index) => (
        <DetailsSegment
          key={`overview_borrowing_business_details_${index}`}
          title={title}
          items={details}
          borrowerIcon={fibtDemo ? false : borrowerIcon}
        />
      )),
      ...personDetails.map(({ title, details, borrowerIcon }, index) => (
        <DetailsSegment key={`overview_borrowing_person_details_${index}`} title={title} items={details} borrowerIcon={borrowerIcon} />
      )),
    ];

    return components.flatMap((component, index) =>
      index < components.length - 1 ? [component, <Divider key={`divider-${index}`} />] : [component],
    );
  }, [application, borrowingBusinessesDetails, fibtDemo, loanDetails, personDetails]);

  return (
    <Flex flexDirection={'column'} gap={8}>
      {detailComponents}
    </Flex>
  );
};

export const SummaryPage: FC = () => {
  const { useAuditV2 } = useFlags();

  const {
    opportunity: { id: opportunityId },
  } = useContext(ApplicationContext);

  return (
    <Flex flexDirection={'column'} gap={6}>
      <OpportunityAlerts />
      <Flex gap={6}>
        <OverviewSection>
          <ApplicationDetails />
        </OverviewSection>
        <OverviewSection>
          <KeyIndicators />
        </OverviewSection>
      </Flex>
      <RelatedApplications />
      {useAuditV2 ? <OpportunityAuditFeedV2 opportunityId={opportunityId} /> : <OpportunityAuditFeed opportunityId={opportunityId} />}
    </Flex>
  );
};
