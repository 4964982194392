import type { FC } from 'react';
import React, { useContext, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Button, Flex } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import { SpreadingDocumentContext } from '../../SpreadingDocumentContext';
import { useGetDocumentAttributes } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useGetDocumentAttributes';

import { useGetAllDocumentAttributesWithFinancialData } from '../../hooks/useGetAllDocumentAttributesWithFinancialData';
import { useGetInitialValues } from '../../hooks/useGetInitialValues';
import { useSubmitDocumentFinancials } from '../../hooks/useSubmitDocumentFinancials';
import type { FormAttributesWithFinanacialData } from '../../Forms/TaxReturns/formFinancialsUtils';
import { useGroupedDocumentPagesByFormInstance } from '../../hooks/useGetGroupedDocumentPAgesByFormInstance';
import { useSyncSpreadingSample } from '../../hooks/useSyncSpreadingSample';
import SpreadingFormByType from './SpreadingFormByType';
import type { FieldFilter } from './FieldFilterButton';
import { FieldFilters } from './FieldFilters';

export const SpreadingForms: FC = () => {
  const { saving, loading: contextLoading, currentDocument } = useContext(SpreadingDocumentContext);
  const [fieldFilter, setFieldFilter] = useState<FieldFilter>('all');

  const { data: documentAttributes, isPending: documentAttributesPending } = useGetDocumentAttributes();
  const attributesForEntity = useMemo(
    () => documentAttributes?.filter((d) => d.entityType === currentDocument.relatedEntityType) ?? [],
    [currentDocument.relatedEntityType, documentAttributes],
  );
  const groupedDocumentPagesByFormInstance = useGroupedDocumentPagesByFormInstance();

  const { formAttributesWithFinancialData } = useGetAllDocumentAttributesWithFinancialData(
    groupedDocumentPagesByFormInstance,
    attributesForEntity ?? [],
  );
  const flatValues = useMemo(
    () => formAttributesWithFinancialData.flatMap<FormAttributesWithFinanacialData>(({ formData }) => formData),
    [formAttributesWithFinancialData],
  );

  const initalValues = useGetInitialValues(flatValues);
  const syncSpreadingSample = useSyncSpreadingSample(currentDocument.applicationId);

  const onSubmit = useSubmitDocumentFinancials(flatValues, fieldFilter, null, syncSpreadingSample);
  if (documentAttributesPending || contextLoading) {
    return <LoadingPage />;
  }
  return (
    <Formik initialValues={initalValues} onSubmit={onSubmit} enableReinitialize enableInitialize>
      {({ submitForm }) => (
        <>
          <Flex zIndex={1000} p={1} position={'fixed'} right={6}>
            <Button variant={'primary'} onClick={submitForm} loading={saving}>
              {'Save'}
            </Button>
          </Flex>
          <FieldFilters fieldFilter={fieldFilter} setFieldFilter={setFieldFilter} />
          {formAttributesWithFinancialData?.map(
            ({ startDocumentPage, endDocumentPage, formData }) =>
              startDocumentPage && (
                <SpreadingFormByType
                  key={`${startDocumentPage.formType}-${startDocumentPage.page}-${endDocumentPage?.page}`}
                  startDocumentPage={startDocumentPage}
                  endDocumentPage={endDocumentPage ?? startDocumentPage}
                  filter={fieldFilter}
                  formData={formData}
                />
              ),
          )}
        </>
      )}
    </Formik>
  );
};
