import { displayToast } from '@lama/app-components';
import type { OpportunityApiModel } from '@lama/clients';
import type { FormikHelpers } from 'formik';

import { isNil, uniqBy } from 'lodash-es';
import { useMemo, useContext, useCallback } from 'react';
import { UserDetailsContext } from '../../../shared/context/UserDetailsContext';
import { getFinancialPayloadAction } from '../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/financialFieldUtils';
import { useSubmitFinancialsMutation } from '../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/hooks/useSubmitFinancialsMutation';
import type { FormAttributesWithFinanacialData } from '../Forms/TaxReturns/formFinancialsUtils';

import { SpreadingDocumentContext } from '../SpreadingDocumentContext';
import { useGetAllFinancialsForCurrentDocument } from './useGetAllFinancialsForCurrentDocument';
import { useGetAttributesDocumentFunction } from './useGetAttributesDocumentFunction';

export const useSubmitDocumentFinancials = (
  allFinancials: FormAttributesWithFinanacialData[],
  filter: string,
  opportunity: OpportunityApiModel | null,
  onSuccess: () => Promise<void>,
) => {
  const { userId } = useContext(UserDetailsContext);
  const { setSaving, currentDocument } = useContext(SpreadingDocumentContext);
  const { mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    currentDocument.relatedEntityId,
    currentDocument.relatedEntityType,
    opportunity?.id,
  );
  const filteredFinancialAttributes = useMemo(() => {
    if (filter === 'missing') {
      return allFinancials.filter(({ financialData }) => isNil(financialData?.value));
    }

    if (filter === 'all') {
      return allFinancials;
    }

    return [];
  }, [filter, allFinancials]);

  const getAttributeDocumentPage = useGetAttributesDocumentFunction();
  const { fetchingData } = useGetAllFinancialsForCurrentDocument();
  return useCallback(
    async (newValues: Record<string, number | string | undefined>, { resetForm }: FormikHelpers<any>) => {
      try {
        if (!userId || fetchingData) {
          return;
        }

        setSaving(true);

        const financialsPayload = uniqBy(filteredFinancialAttributes, 'id')
          .filter(({ attribute: { financialData } }) => financialData?.type && financialData?.startDate)
          .flatMap((attributeWithFinancialData) => {
            const currentFinancial = allFinancials.find(({ id }) => id === attributeWithFinancialData.id);
            const currentFormType = currentFinancial?.attribute?.formTypeId;
            const startPage = currentFinancial?.attribute?.formStartPage;

            if (!currentFormType || !startPage) {
              return [];
            }

            const {
              id,
              attribute: { financialData },
              financialData: financialDataFromForm,
            } = attributeWithFinancialData;
            try {
              const payload = getFinancialPayloadAction({
                entityId: currentDocument.relatedEntityId,
                fieldToUpdate: financialDataFromForm,
                year: new Date(financialData!.startDate).getUTCFullYear(),
                fieldName: financialData!.type,
                value: newValues[id],
                userId,
                documentId: currentDocument.id,
                currentFormType,
                documentPageNumber: getAttributeDocumentPage(attributeWithFinancialData) ?? startPage,
                unverified: currentDocument.status !== 'Processed',
              });
              return payload;
            } catch (error) {
              console.error(error);
              return [];
            }
          });

        if (financialsPayload.length) {
          await updateFinancialData(financialsPayload);
          await onSuccess();
        }
      } catch {
        displayToast('Failed to save financial data', 'error');
      } finally {
        resetForm({ values: newValues });
        setSaving(false);
      }
    },
    [
      allFinancials,
      currentDocument.id,
      currentDocument.relatedEntityId,
      currentDocument.status,
      fetchingData,
      filteredFinancialAttributes,
      getAttributeDocumentPage,
      onSuccess,
      setSaving,
      updateFinancialData,
      userId,
    ],
  );
};
