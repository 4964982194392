import type { GetAttributesResponse, FinancialData, FinancialDataSource, TopicAnalysisParams } from '@lama/contracts';
import { getYear } from '@lama/data-formatters';
import { getFinancialFieldByYear } from '../../../Application/OpportunityRequirements/OpportunityRequirements/RequirementScreens/financials/financialFieldUtils';
import type { AttributeWithFinancialData } from '../FinancialStatements/utils/financialAttribute';

export const isFinancialRelatedToFormPage = (
  source: FinancialDataSource,
  curerntDocumentId: string,
  formStartPage: number,
  formEndPage?: number,
) => {
  const financialDocument = source.type === 'Document' ? source.documentId : source.type === 'Manual' ? source.relatedDocumentId : null;
  const financialPage = source.type === 'Document' ? source.page : source.type === 'Manual' ? source.relatedDocumentPage : null;

  if (financialDocument !== curerntDocumentId) {
    return false;
  }
  if (!financialPage) {
    return false;
  }
  if (!formEndPage || financialPage < formStartPage || financialPage > formEndPage) {
    return false;
  }

  return true;
};

export type FormAttributesWithFinanacialData = AttributeWithFinancialData & {
  attribute: { formTypeId?: string; formStartPage?: number; formEndPage?: number };
};

export const getFormAttributesWithFinancialData = (
  formAttributes: GetAttributesResponse['attributes'],
  formFinancials: FinancialData[],
  formStartPage: number,
  currentFormType?: TopicAnalysisParams,
  formEndPage?: number,
): FormAttributesWithFinanacialData[] =>
  formAttributes
    .filter((attribute) => attribute.financialData)
    .map(({ formId, ...attribute }) => {
      const year = getYear(attribute.financialData!.startDate);
      const { type } = attribute.financialData!;
      const attributePage = Number(attribute.page);
      return {
        id: `${type}_${year}_${formStartPage}`,
        financialPage: Number.isNaN(attributePage) ? undefined : formStartPage + attributePage - 1,
        attribute: {
          ...attribute,
          formStartPage,
          formEndPage,
          formId: currentFormType?.displayName ?? formId,
          formTypeId: currentFormType?.id ?? formId,
        },
        financialData: getFinancialFieldByYear(formFinancials ?? [], year, type),
      };
    });
