import { useAuth0 } from '@auth0/auth0-react';
import type { CreateBorrowerRequestBody } from '@lama/user-service-client';
import { useMutation } from '@tanstack/react-query';
import { userServiceClient } from '../../../shared/clients/userServiceClient';

export const useCreateBorrowerMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async ({ createBorrowerPayload }: { createBorrowerPayload: CreateBorrowerRequestBody }) => {
      const token = await getAccessTokenSilently();
      return userServiceClient.createBorrower(createBorrowerPayload, token);
    },
  });
};
