import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { PartnerRequirement } from '@lama/contracts';
import { LoadingPage } from '@lama/app-components';
import { useAsyncFn } from 'react-use';
import { useAuth0 } from '@auth0/auth0-react';
import { groupBy, isNil } from 'lodash-es';
import { Flex } from '@lama/design-system';
import { ProductSettingsContext } from '../ProductSettingsContext';
import { productServiceClient } from '../../../../../shared/clients/productService';
import { usePartnerRequirementsQuery } from '../../../../../shared/hooks/react-query/product/usePartnerRequirementsQuery';
import { PartnerRequirementSettingsCategory } from './PartnerRequirementSettingsCategory';

export const PartnerRequirementsSettings = () => {
  const { product, registerSaveFn, setIsDirty } = useContext(ProductSettingsContext);
  const { data: partnerRequirements, isPending: loadingRequirements } = usePartnerRequirementsQuery(product.partnerId);
  const { getAccessTokenSilently } = useAuth0();
  const [currentProductRequirementsKeys, setCurrentProductRequirementsKeys] = useState(
    product.requirements
      .filter((r) => !isNil(r.key))
      .map((r) => ({
        key: r.key,
      })),
  );

  const [, saveRequirements] = useAsyncFn(async () => {
    const token = await getAccessTokenSilently();
    await productServiceClient.updateProduct(product.id, { partnerRequirementsReferences: currentProductRequirementsKeys }, token);
  }, [getAccessTokenSilently, product.id, currentProductRequirementsKeys]);

  const requirementsByCategory = useMemo(() => {
    if (!partnerRequirements) {
      return {};
    }

    const partnerRequirementsWithActiveStatus = partnerRequirements.map((r) => ({
      ...r,
      active: currentProductRequirementsKeys.some((pr) => pr.key === r.key),
    }));

    return groupBy(partnerRequirementsWithActiveStatus ?? [], (x) => x.category);
  }, [currentProductRequirementsKeys, partnerRequirements]);

  const onRequirementCheck = useCallback(
    (requirement: PartnerRequirement) => {
      if (!requirement) {
        return;
      }

      if (currentProductRequirementsKeys.some((r) => r.key === requirement.key)) {
        setCurrentProductRequirementsKeys(currentProductRequirementsKeys.filter((r) => r.key !== requirement.key));
        setIsDirty(true);
        return;
      }

      setCurrentProductRequirementsKeys((prev) => [...prev, { key: requirement.key }]);
    },
    [currentProductRequirementsKeys, setIsDirty],
  );

  useEffect(() => {
    registerSaveFn('requirements', saveRequirements);
  }, [registerSaveFn, saveRequirements]);

  if (loadingRequirements) {
    return <LoadingPage />;
  }

  if (!partnerRequirements?.length && !product.requirements.length) {
    return <div>{'There are no requirements to show'}</div>;
  }

  return (
    <Flex flexDirection={'column'} p={5} gap={2} backgroundColor={'white'} border={'1px solid #E0E0E0'} borderRadius={'8px'}>
      {Object.entries(requirementsByCategory).map(([category, requirements]) => (
        <PartnerRequirementSettingsCategory key={category} category={category} requirements={requirements} onCheck={onRequirementCheck} />
      ))}
    </Flex>
  );
};
